/**
 * This file has strcture for start with settings page
 */
import {useState} from "react";
// for routing
import { useLocation, Navigate } from 'react-router-dom';
import Header from "../../components/header/header";
import MobileHeader from "../../components/header/mobile-header";
import MobileFooter from "../../components/footer/mobile-footer";
import Footer from "../../components/footer/footer";
import ProcessSteps from "../../components/process-steps";
import InnerPageBanner from "../../components/inner-page-banner";
import MobileProcessSteps from "../../components/mobile-process-steps";
import DiamondSettingsSlider from "../../components/diamond-settings-slider";
import MobileFilterPopup from "./sws-mobile-filter-popup.js";
import GridWithSlider from "./sws-grid";
import CTACard from "../../components/cta-card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import {
  swsDesktopBanner,
  swsMobileBanner,
  settingImage1, settingImage2, settingImage3, settingImage4, settingGridImage
} from "../../assets/start-with-setting-media.js";
import {
  cydStepperDiamondImage,
  cydStepperSettingImage,
  cydStepperRingImage,
  cydCTACardImage1,
  cydCTACardImage2,
} from "../../assets/cyd-media.js";
import { filterIcon, diamondCushion, diamondDecagon, diamondEmerald, diamondRound, diamondOval, diamondRadiant, diamondShield, filterGrayIcon, closeIcon } from "../../assets/icons.js";
import NativeSelect from '@mui/material/NativeSelect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
// Banner content
const bannerContentData = {
  desktopImage: swsDesktopBanner,
  mobileImage: swsMobileBanner,
  title: "Engagement Ring",
  text: "Your journey begins here, select your preferred choice of the highest cut standards in the world within our in-house inventory.",
};
// CTA cards data
const ctaCardsData = [
    {
      image: cydCTACardImage1,
      imageTitle: "Ask a JANNPAUL Diamond Specialist",
      imageSubtitle:
        "Jannpaul Diamond Specialist can assist you in choosing an engagement ring, personalizing a wedding band or selecting a special anniversary gift.",
      linkToNewPage: "",
      ctaName: "BOOK An APPOINTMENT"
    },
    {
      image: cydCTACardImage2,
      imageTitle: "SEND US YOUR REQUEST",
      imageSubtitle:
        "Not all diamond inventory are listed, for more options please contact us.",
        linkToNewPage: "",
      ctaName: "Contact Us"
    },
  ];
  // steps default data
  let steps = [
    {
      text: "Select Your Diamond",
      link: "Browse Diamonds",
      icon: cydStepperDiamondImage,
    },
    {
      text: "Select Your Setting",
      link: "Browse Setting",
      icon: cydStepperSettingImage,
    },
    { text: "Complete Your Ring", icon: cydStepperRingImage },
  ];
  //settingType data
  const settingTypes = [
    {
      name: "SOLITARE",
      image: cydStepperSettingImage,
    },
    {
      name: "HALO",
      image: cydStepperSettingImage,
    },
    {
      name: "PETITE",
      image: cydStepperSettingImage,
    },
    {
      name: "SIDE STONE",
      image: cydStepperSettingImage,
    },
    {
      name: "LOREM",
      image: cydStepperSettingImage,
    },
    {
      name: "LOREM",
      image: cydStepperSettingImage,
    },
    {
      name: "LOREM",
      image: cydStepperSettingImage,
    },
    {
      name: "LOREM",
      image: cydStepperSettingImage,
    },
    {
      name: "LOREM",
      image: cydStepperSettingImage,
    },
  ];

  let obj1 = {
    images: [settingImage1, settingImage2, settingImage3, settingImage4 ],
    price: "SGD5,200",
    productText: "Ante tincidunt orci accura rhoncus vitae."
  };
  let obj2 = {
    images: [settingImage2, settingImage3, settingImage4, settingImage1],
    price: "SGD5,200",
    productText: "Ante tincidunt orci accura rhoncus vitae."
  };
  let obj3 = {
    images: [settingImage3, settingImage4, settingImage1, settingImage2],
    price: "SGD5,200",
    productText: "Ante tincidunt orci accura rhoncus vitae."
  }
  let obj4 = {
    images: [settingImage4, settingImage1, settingImage2, settingImage3],
    price: "SGD5,200",
    productText: "Ante tincidunt orci accura rhoncus vitae."
  }
  const settingsData = [];
  for(let i = 0;i < 100; i++){
    settingsData.push(obj1);
    settingsData.push(obj2);
    settingsData.push(obj3);
    settingsData.push(obj4);
  }
  const metalFilterData = ["Gold", "White Gold", "Rose Gold", "Platinum"];
  const diamondShapeFilterData = [{shape: "Round", image: diamondRound }, {shape: "Oval", image: diamondOval}, {shape: "Decagon", image: diamondDecagon}, {shape: "Cusion", image: diamondCushion}, {shape: "Radiant", image: diamondRadiant}, {shape: "Shield", image: diamondShield}, {shape: "Emerald", image: diamondEmerald}];
const StartWithSetting = ({ isMobile, paths }) => {
  // import css files
  isMobile ? import("../../assets/css/start-with-setting/start-with-setting-mobile.css") : import("../../assets/css/start-with-setting/start-with-setting-desktop.css");
  // Toggle filter popup for mobile
  const [ toggleFilter, setToggleFilter ] = useState(false);
  // Showing result
  const [productRange, setProductRange] = useState(isMobile ? [1,8] : [1,12]);
  // Store dropdown values
  // const [dropdownValues, setDropdownValues] = useState({price: [], metal: [], diamondShape: []});
  // Selected filter values
  const [ selectedFilters, setSelectedFilters ] = useState([]);
  const [processStepData, setProcessStepData] = useState(null);
  // For storing routing data
  const location = useLocation();
  const diamondData = location.state;
  if(diamondData){
    // console.log(diamondData);
    // setProcessStepData(diamondData);
  }
  console.log("Selected filters are ",selectedFilters);
  // adding selected filters
  const handleSelectedFilter = (e) =>{
    // console.log(e.target.value);
    setSelectedFilters(prevArray => [...prevArray, e.target.value]);
  }
  // remove all selected filters
  const handleResetAll = () =>{
    setSelectedFilters([]);
  }
  // remove selected filter
  const handleChipRemoval = (filterToRemove) =>{
    setSelectedFilters((chips) => chips.filter((chip) => chip !== filterToRemove));
  }
  return (
    <>
      {/* Header */}
      {isMobile ? <MobileHeader paths={paths} /> : <Header paths={paths} />}
      {/*  Banner */}
      <InnerPageBanner
        bannerContentData={bannerContentData}
        isMobile={isMobile}
      />
      {/* Steps */}
      {isMobile ? (
        <MobileProcessSteps data={diamondData} pageName={"start-with-setting"} />
      ) : (
        <ProcessSteps data={diamondData} pageName={"start-with-setting"}  />
      )}
      {/* Image Slider */}
      <Container className="swsSettingsSliderContainer">
        <DiamondSettingsSlider
          diamondTypes={settingTypes}
          isMobile={isMobile}
          pageName={"Start With Setting"}
          setSelectedFilters={setSelectedFilters}
        />
      </Container>
      {/* Filter box and Grid */}
      <Container className="swsGridContainer">
        <Grid container className="swsFilterGridContainer">
          {isMobile ? (
            <>
              <Grid item xs={6} className="swsFilterGrid1">
                <Typography className="swsFilterText">
                  Showing {productRange[0]}-{productRange[1]} of{" "}
                  {settingsData.length}
                </Typography>
              </Grid>
              <Grid item xs={6} className="swsFilterGrid2">
                <Button
                  className="swsFilter blackOutlinedButton"
                  onClick={() => setToggleFilter(true)}
                >
                  <img
                    className="swsFilterImage"
                    src={filterIcon}
                    loading="lazy"
                  />
                  Filter By
                </Button>
                {toggleFilter && (
                  <MobileFilterPopup
                    dataLength={settingsData.length}
                    setToggleFilter={setToggleFilter}
                    diamondShapeFilterData={diamondShapeFilterData}
                    metalFilterData={metalFilterData}
                  />
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={6} className="swsFilterGrid1">
                <Stack direction="row" className="swsFilterGrid1Stack">
                  <Typography className="filterByText">
                    <img src={filterGrayIcon} className="swsFilterIcon" />
                    Filter By:
                  </Typography>

                  <NativeSelect
                    disableUnderline
                    className="jpNativeSelectDropdown"
                    IconComponent={() => (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="selectDropdownArrow"
                      />
                    )}
                  >
                    <option className="dropdownOptions" value={"price"}>Price</option>
                    <option className="dropdownOptions" value={"price2"}>Price2</option>
                  </NativeSelect>
                  <NativeSelect
                    disableUnderline
                    className="jpNativeSelectDropdown"
                    IconComponent={() => (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="selectDropdownArrow"
                      />
                    )}
                    onChange={handleSelectedFilter}
                  >
                    <option className="dropdownOptions">Metal</option>
                    {metalFilterData.map((item, index) => (
                      <option className="dropdownOptions" value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </NativeSelect>
                  <NativeSelect
                    disableUnderline
                    className="jpNativeSelectDropdown"
                    IconComponent={() => (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="selectDropdownArrow"
                      />
                    )}
                    onChange={handleSelectedFilter}
                  >
                    <option className="dropdownOptions">Diamond Shape</option>
                    {diamondShapeFilterData.map((item, index) => (
                      <option className="dropdownOptions" key={index} value={item.shape}>
                        {item.shape}
                      </option>
                    ))}
                  </NativeSelect>
                </Stack>
              </Grid>
              <Grid item md={6} className="swsFilterGrid2">
              <Stack direction="row" className="swsFilterGrid2Stack">
              <Typography className="sortByText">Sort By: </Typography>
              <NativeSelect disableUnderline className="jpNativeSelectDropdown" IconComponent={()=>( <FontAwesomeIcon icon={faAngleDown} className="selectDropdownArrow" /> )}>
                  <option className="dropdownOptions">Default Sorting</option>
                  <option className="dropdownOptions">Sorting</option>
                </NativeSelect>
              </Stack>
              </Grid>
              <Grid item md={12} className="swsFilterGrid3">
                <Divider className="swsFilterDivider" />
              </Grid>
              <Grid item md={2} className="swsFilterGrid4">
              <Typography className="swsFilterText">
                  Showing {productRange[0]}-{productRange[1]} of{" "}
                  {settingsData.length}
                </Typography>
              </Grid>
              <Grid item md={9} className="swsFilterGrid4">
              {selectedFilters && selectedFilters.map((filter, index)=>(
                <Chip key={index} icon={<img src={closeIcon} className="jpSelectedChipCloseIcon" onClick={()=>handleChipRemoval(filter)}  />} label={filter} className="jpSelectedItemChip" />
              ))}
              </Grid>
              <Grid item md={1} className="swsFilterGrid5">
              <Link className="swsFilterResetAllLink" onClick={handleResetAll}>
                 Reset All
                </Link>
              </Grid>
            </>
          )}
        </Grid>
        <GridWithSlider
          data={settingsData}
          setProductRange={setProductRange}
          isMobile={isMobile}
        />
      </Container>
      {/* CTA cards */}
      <Container className="swsCTACardsContainer">
        <Grid container className="swsCTACardsGridContainer" spacing={2}>
          {ctaCardsData.map((item, index) => (
            <Grid
              item
              md={6}
              xs={12}
              key={index}
              className="ctaCardBox swsCardBox"
            >
              <CTACard item={item} index={index} isMobile={isMobile} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      {isMobile ? <MobileFooter /> : <Footer />}
    </>
  );
};
export default StartWithSetting;
