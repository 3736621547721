/**
 * This file has the code for customize your ring menu
 */
import onlineCustomizationImage from "../../assets/media/mega-menu-media/online-customization-img.png";
import inStoreConsultation from "../../assets/media/mega-menu-media/instore-consultation-img.png";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { closeIcon, leftArrowIcon } from "../../assets/icons";
const MobileCustomizeYourRing = ({ setCurrentmenu, setToggleMenu }) => {
  return (
    <Grid container>
      <Grid item xs={9}>
        <Stack   className="jpSandwichSubMenu"
            direction="row"
            justifyContent="space-between">
          <Typography className="jpSandwichSubMenuTitle">
            <img src={leftArrowIcon} onClick={() => setCurrentmenu(null)} />
            CUSTOMIZE YOUR RING
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <img className="jpSandwichSubMenuCancel" src={closeIcon} onClick={() => setToggleMenu(false)} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid className="collectionWhiteSeriesRow" container spacing={2}>
        <Grid item xs={4}>
          <img src={onlineCustomizationImage} className="mobileMenuContentImage" />
        </Grid>
        <Grid className="collectionColumn" item xs={8}>
            <Typography variant="h3">
            Online <br />
            Customization
            </Typography>
            <Typography variant="subtitle">
            Online Customization Description
            </Typography>
          <Link to={"/choose-your-diamond"}>
          <Button className="black-filled" variant="contained">Start Now</Button>
        </Link>
        </Grid>
      </Grid>  
      <Grid className="customzieYourRingRow" container spacing={2}>
        <Grid item xs={4}>
          <img src={inStoreConsultation} className="mobileMenuContentImage" />
        </Grid>
        <Grid className="collectionColumn" item xs={8}>
            <Typography variant="h3">
            In Store<br />
            Consultation
            </Typography>
            <Typography variant="subtitle">
            In Store Consultation Description
            </Typography>
          <Link to={"/homepage-sakshi"}>
          <Button className="black-filled" variant="contained">Start Now</Button>
        </Link>
        </Grid>
      </Grid> 

      {/* <Grid item xs={5}>
        <img
          src={onlineCustomizationImage}
          className="mobileMenuContentImage"
        />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h5">
          Online <br />
          Customization
        </Typography>
        <Typography variant="subtitle1">
          Online Customization Description
        </Typography>
        <Link to={"/choose-your-diamond"}>
          <Button variant="contained">Start Now</Button>
        </Link>
      </Grid> */}
      {/* <Grid item xs={5}>
        <img src={inStoreConsultation} className="mobileMenuContentImage" />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h5">
          In Store <br />
          Consultation
        </Typography>
        <Typography variant="subtitle1">
          In Store Consultation Description
        </Typography>
        <Link to={"/homepage-sakshi"}>
          <Button variant="contained">Start Now</Button>
        </Link>
      </Grid> */}
    </Grid>
  );
};
export default MobileCustomizeYourRing;
