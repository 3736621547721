/**
 * This file has the structure for view report pop up for mobile
 */
import { useState } from "react";
import {
    closeIcon,
  } from "../../assets/icons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
const ViewReportPopUp = ({report, setOpenReportPopup}) =>{
  // handling drawer open and close
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setOpenReportPopup(false);
  };
  const handleOpen = () => setOpen(true);
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      className="mobileViewReportDrawer"
    >
        <List className="ddpViewReportPopupList">
        <Box className="ddpViewReportPopupBox1">
          <ListItem className="ddpViewReportPopupListItem">
            <Typography className="ddpViewReportPopupTitle">
              Lab Report
            </Typography>
            {/* Closing the drawer when clicked on closeIcon */}
            <img
              src={closeIcon}
              onClick={handleClose}
              className="ddpViewReportPopupImage"
            />
          </ListItem>
        </Box>
        <Box className="ddpViewReportPopupBox2">
            <ListItem>
            <img src={report} className="ddpViewReportImage" />
            </ListItem>
        </Box>
        </List>
        <Box className="ddpViewReportPopupBox3">
        <ListItem className="ddpViewReportPopupListItem">
            <Button className="black-filled ddpViewReportButton">Download Report</Button>
        </ListItem>
        </Box>
    </SwipeableDrawer>
  );
}
export default ViewReportPopUp;