/**
 * This file contains the structure for grid in start with settings page
 */
import React, { useState } from "react";
import GridSliders from "./sws-grid-sliders.js";
import PaginationComponent from "../../components/pagination.js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { settingGridImage } from "../../assets/start-with-setting-media.js";
import { wishlistIcon } from "../../assets/icons.js";
const GridWithSlider = ({ data, setProductRange, isMobile }) => {
  const gridsPerPage = isMobile ? 8 : 12;
  // formatted data array
  let formattedArray = [];
  const updatedArrayForMobile = () => {
    for (let i = 0; i < data.length; i += gridsPerPage) {
      // First 4 products
      formattedArray.push(...data.slice(i, i + gridsPerPage / 2));
      // Fixed image
      formattedArray.push(settingGridImage);
      // Next 4 products
      formattedArray.push(
        ...data.slice(i + gridsPerPage / 2, i + gridsPerPage)
      );
    }
  };
  const updatedArrayForDesktop = () => {
    for (let i = 0; i < data.length; i += gridsPerPage) {
      formattedArray.push(data.slice(i, i + gridsPerPage));
      // console.log(data.slice(i, i + 12));
    }
  };

  isMobile ? updatedArrayForMobile() : updatedArrayForDesktop();
  // console.log(formattedArray);
  // setting default page to show and setting page changes for pagination
  const [page, setPage] = useState(1);
  // for first page
  // 8 grids and one image for mobile and one object for desktop
  const totalItemsPerPage = isMobile ? 9 : 1;
  // Settings for pagination
  const totalPages = Math.ceil(formattedArray.length / totalItemsPerPage);
  const pageContent = formattedArray.slice(
    (page - 1) * totalItemsPerPage,
    page * totalItemsPerPage
  );
  // console.log((page-1) * gridsPerPage, page*gridsPerPage);
  function handlePageChange(event, value) {
    setPage(value);
    // console.log("Page is changed");
    // Setting product range from the value
    setProductRange([(value - 1) * gridsPerPage + 1, value * gridsPerPage]);
  }
  return (
    <>
      {isMobile ? (
        <Grid container className="productGridContainer" spacing={1}>
          {pageContent &&
            pageContent.map((item, index) =>
              item === settingGridImage ? (
                <Grid
                  item
                  xs={12}
                  className="productFixedImageGrid"
                  key={index}
                >
                  <img
                    src={settingGridImage}
                    width={"100%"}
                    className="productGridCenterImage"
                  />
                </Grid>
              ) : (
                <Grid item xs={6} className="productDataGrid" key={index}>
                  <img src={wishlistIcon} className="productWishlistIcon" />
                  {/* <Box> */}
                  <GridSliders images={item.images} isMobile={isMobile} />
                  {/* </Box> */}
                  <Box className="productDetailsBox">
                    <Typography className="productDescription">
                      {item.productText}
                    </Typography>
                    <Typography className="productPriceText">
                      {item.price}
                    </Typography>
                  </Box>
                </Grid>
              )
            )}
        </Grid>
      ) : (
        pageContent.map((arr, index) => (
          <React.Fragment key={index}>
            <Grid container className="productGridContainer" spacing={1}>
              <Grid item md={3} className="gridItemFirstRowItem">
                {arr[0] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[0].price, arr[0].productText]}
                    images={arr[0].images}
                    index={0}
                  />
                )}
              </Grid>
              <Grid item md={3} className="gridItemFirstRowItem">
                {arr[1] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[1].price, arr[1].productText]}
                    images={arr[1].images}
                    index={1}
                  />
                )}
              </Grid>
              <Grid item md={3} className="gridItemFirstRowItem">
                {arr[2] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[2].price, arr[2].productText]}
                    images={arr[2].images}
                    index={2}
                  />
                )}
              </Grid>
              <Grid item md={3} className="gridItemFirstRowItem">
                {arr[3] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[3].price, arr[3].productText]}
                    images={arr[3].images}
                    index={3}
                  />
                )}
              </Grid>
            </Grid>
              <Grid container className="productGridMiddleContainer" spacing={1} >
                <Grid item md={3} className="gridItemSecondRowCol">
                  <Box className="gridItemSecondRowBox">
                  {arr[4] && (
                    <GridSliders
                      isMobile={isMobile}
                      data={[arr[4].price, arr[4].productText]}
                      images={arr[4].images}
                      index={4}
                    />
                  )}
                  </Box>
                  <Box className="gridItemSecondRowBox">
                  {arr[5] && (
                    <GridSliders
                      isMobile={isMobile}
                      data={[arr[5].price, arr[5].productText]}
                      images={arr[5].images}
                      index={5}
                    />
                  )}
                  </Box>
                </Grid>
                <Grid item md={3} className="gridItemSecondRowCol">
                  <Box className="gridItemSecondRowBox">
                  {arr[6] && (
                    <GridSliders
                      isMobile={isMobile}
                      data={[arr[6].price, arr[6].productText]}
                      images={arr[6].images}
                      index={6}
                    />
                  )}
                  </Box>
                  <Box className="gridItemSecondRowBox">
                  {arr[7] && (
                    <GridSliders
                      isMobile={isMobile}
                      data={[arr[7].price, arr[7].productText]}
                      images={arr[7].images}
                      index={7}
                    />
                  )}
                  </Box>
                </Grid>
                <Grid item md={6} className="gridItemSecondRowCol gridItemSecondRowFixedImage" >
                <img src={settingGridImage} className="productGridFixedImage" />
              </Grid>
              </Grid>
            <Grid container className="productGridContainer" spacing={1}>
              <Grid item md={3} className="gridItemThirdRowItem" >
                {arr[8] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[8].price, arr[8].productText]}
                    images={arr[8].images}
                    index={8}
                  />
                )}
              </Grid>
              <Grid item md={3} className="gridItemThirdRowItem" >
                {arr[9] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[9].price, arr[9].productText]}
                    images={arr[9].images}
                    index={9}
                  />
                )}
              </Grid>
              <Grid item md={3} className="gridItemThirdRowItem">
                {arr[10] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[10].price, arr[10].productText]}
                    images={arr[10].images}
                    index={10}
                  />
                )}
              </Grid>
              <Grid item md={3} className="gridItemThirdRowItem">
                {arr[11] && (
                  <GridSliders
                    isMobile={isMobile}
                    data={[arr[11].price, arr[11].productText]}
                    images={arr[11].images}
                    index={11}
                  />
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        ))
      )}

      {/* Pagination */}
      <PaginationComponent
        totalPages={totalPages}
        page={page}
        handlePageChange={handlePageChange}
        isMobile={isMobile}
      />
    </>
  );
};
export default GridWithSlider;
