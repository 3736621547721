import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import {
  closeIcon,
  calendarIcon,
  phoneIcon,
  mailIcon,
} from "../../assets/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import { faEnvelope, faCalendar } from '@fortawesome/free-regular-svg-icons';
const ConsultDiamondExpert = ({handleClose, handleOpen, open, image, diamondData}) =>{
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
    return (
      <Modal open={open} onClose={handleClose}>
        <Box  className="consultExpertPopUpContainer">
          <Stack direction="row" spacing={7}>
            <Box className="consultExpertPopUpLeftContent">
              <Box className="consultDiamondExpertProductDetailsBox">
                <img src={image} className="consultExpertPopUpImage" />
                <Box className="modalProductTitle">
                <Typography className="popupProductTitle">
                  {diamondData.carat}ct {diamondData.cut} {diamondData.shape}
                </Typography>
                <Typography>
                  {diamondData.color} Color · {diamondData.clarity}
                </Typography>
                </Box>
              </Box>
              <Box className="consultDiamondContactDetailParentBox">
              <Box className="consultDiamondContactDetailBox">
              <img src={mailIcon} className="contactIcons contactIcon1" />
                <Link href="#" className="contactDetails ddpEmailAddress">sales@jannpaul.com</Link>
              </Box>
              <Divider className="consultDiamondContactDetailDivider1" />
              <Box className="consultDiamondContactDetailBox">
              <img src={phoneIcon} className="contactIcons contactIcon2" />
                <Link className="contactDetails ddpPhoneNumber">+65-90219891</Link>
              </Box>
              <Divider className="consultDiamondContactDetailDivider2" />
              <Box className="consultDiamondContactDetailBox">
              <img src={calendarIcon} className="contactIcons contactIcon3" />
                <Typography underline="hover" className="contactDetails ddpBookAnAppointment">BOOK AN APPOINTMENT <FontAwesomeIcon icon={faAngleRight} /></Typography>
              </Box>
              <Divider className="consultDiamondContactDetailDivider3" />
              </Box>
            </Box>
            <Box>
            <Typography variant="h3" className="ddpConsultDiamondExpertFormTitle">
                Consult a diamond expert
              </Typography>
              <Grid container spacing={2}>
                <Grid  item md={6}>
                  <Typography className="labelTitile">Title*</Typography>
                  <FormControl className="formInputFeild" fullWidth size="small">
                    <Select
                      value={title}
                      onChange={handleTitleChange}
                      displayEmpty
                      fullWidth
                      IconComponent={() => (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="dropdownArrow"
                        />
                      )}
                    >
                      <MenuItem value="">Select Title</MenuItem>
                      <MenuItem value={"Title1"}>Title1</MenuItem>
                      <MenuItem value={"Title2"}>Title2</MenuItem>
                      <MenuItem value={"Title3"}>Title3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}></Grid>
                <Grid  item md={6}>
                  <Typography className="labelTitile">First Name*</Typography>
                  <TextField className="formInputFeild" fullWidth variant="outlined" size="small" />
                </Grid>
                <Grid  item md={6}>
                  <Typography className="labelTitile">Last Name*</Typography>
                  <TextField className="formInputFeild" fullWidth variant="outlined" size="small" />
                </Grid>
                <Grid  item md={12}>
                  <Typography className="labelTitile">E-mail*</Typography>
                  <TextField className="formInputFeild" fullWidth variant="outlined" size="small" />
                </Grid>
                <Grid item md={6}>
                  <Typography className="labelTitile">Phone*</Typography>
                  <TextField className="formInputFeild"  fullWidth variant="outlined" size="small" />
                </Grid>
                <Grid  item md={6}>
                  <Typography className="labelTitile">Location*</Typography>
                  <FormControl className="formInputFeild" fullWidth size="small">
                    <Select
                      value={location}
                      onChange={handleLocationChange}
                      displayEmpty
                      fullWidth
                      IconComponent={() => (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="dropdownArrow"
                        />
                      )}
                    >
                      <MenuItem value="">Singapore</MenuItem>
                      <MenuItem value={"Location1"}>Location1</MenuItem>
                      <MenuItem value={"Location2"}>Location2</MenuItem>
                      <MenuItem value={"Location3"}>Location3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <Typography className="labelTitile">Enquire Message</Typography>
                  <TextField className="formInputFeild" 
                    fullWidth
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item md={8}>
                  <Button className="black-filled consultDiamondExpertButton">SEND ENQUIRY</Button>
                  <Box>
          <img
              src={closeIcon}
              onClick={handleClose}
              className="ddpConsultDiamondExpertPopupImage"
            />
          </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
          
        </Box>
      </Modal>
    );
}
export default ConsultDiamondExpert;