/**
 * This file has the code for engagement rings menu
 */
import engagementRingsImage from "../../assets/media/mega-menu-media/engagement-rings-img.png";
import { settingIcon, closeIcon, leftArrowIcon, diamondRound, diamondCushion, diamondOval, diamondDecagon, diamondRadiant, diamondShield, diamondEmerald } from "../../assets/icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
// shop by diamond list
const shopByDiamondList = [
  {
    name: "Round",
    image: diamondRound,
  },
  {
    name: "Oval",
    image: diamondOval,
  },
  {
    name: "Decagon",
    image: diamondDecagon,
  },
  {
    name: "Cushion",
    image: diamondCushion,
  },
  {
    name: "Radiant",
    image: diamondRadiant,
  },
  {
    name: "Shield",
    image: diamondShield,
  },
  {
    name: "Emerald",
    image: diamondEmerald,
  },
];
// Shop by setting list
const shopBySettingList = [
  "Solitare",
  "Petite",
  "Halo",
  "Side Stone",
  "Pave",
  "Ring Stone 6",
  "Ring Stone 7",
];
// Shop by category list
const shopByCategoryList = [
  "Engagement Rings",
  "Wedding Bands",
  "Category 3",
  "Category 4",
];
const MobileEngagementRingsMenu = ({ setCurrentmenu, setToggleMenu }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Stack  sm={10}
            className="jpSandwichSubMenu"
            direction="row"
            justifyContent="space-between">
            <Typography className="jpSandwichSubMenuTitle">
              <img src={leftArrowIcon} onClick={() => setCurrentmenu(null)} />
              ENGAGEMENT RINGS
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <img className="jpSandwichSubMenuCancel" src={closeIcon} onClick={() => setToggleMenu(false)} />
        </Grid>
      </Grid>
      <Divider />
      <Box direction="column">
        <Accordion defaultExpanded className="jpHeaderAccordion">
          <AccordionSummary  className="shopByDiamondTitleOne" expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
            SHOP BY SETTING
          </AccordionSummary>
          <AccordionDetails className="accordianColumn">
            {shopBySettingList.map((ringStone, index) => (
              <Typography key={index}>
                <Link className="shopByItemsMobile" underline="none" variant="subtitle1">
                  <img src={settingIcon} className="shopBySettingImages" />{" "}
                  {ringStone}
                </Link>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="jpHeaderAccordion">
          <AccordionSummary className="shopByDiamondTitleOne" expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
            SHOP BY DIAMOND
          </AccordionSummary>
          <AccordionDetails className="accordianColumn">
            {shopByDiamondList.map((diamond, index) => (
              <Typography  key={index}>
                <Link className="shopByItemsMobile" underline="none" variant="subtitle1">
                  <img src={diamond.image} className="shopByDiamondImages" />{" "}
                  {diamond.name}
                </Link>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="jpHeaderAccordion">
          <AccordionSummary className="shopByDiamondTitleOne" expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
            SHOP BY CATEGORY
          </AccordionSummary>
          <AccordionDetails className="accordianColumn">
            {shopByCategoryList.map((category, index) => (
              <Typography key={index}>
                <Link className="shopByItemsMobile" underline="none" variant="subtitle1">{category}</Link>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Grid className="collectionWhiteSeriesRow" container spacing={2}>
        <Grid item xs={4}>
          <img src={engagementRingsImage} className="mobileMenuContentImage" />
        </Grid>
        <Grid className="collectionColumn" item xs={8}>
            <Typography variant="h3">
              Engagement <br />
              Rings
            </Typography>
            <Typography variant="subtitle">
              Engagement Rings Description
            </Typography>
            <Button className="black-filled" variant="contained">
              Shop Now
            </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default MobileEngagementRingsMenu;
