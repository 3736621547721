/**
 * This file contains accordion
 */
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { plusIcon, minusIcon } from "../assets/icons";
import "../assets/css/components.css";
const AccordionComponent = ({content}) => {
    const [ isOpen, setIsOpen ] = useState([false, null]);
    const handleClick = (index) =>{
      setIsOpen([true, index]);
    }
  return (
    <Box className="accordionBox">
      {content.map((item, index)=>(
        <Accordion className={`${isOpen[0] && isOpen[1] === index ? "activeAccordionComponent" : ""} accordionComponent`} key={index} onClick={()=>handleClick(index)} expanded={isOpen[1] === index}>
        <AccordionSummary
          expandIcon={
          <img src={isOpen[0] && isOpen[1] === index ?  minusIcon : plusIcon} className="accordionIcons" />
          }
        className = "accordionTitleSummaryHeader"
        id={"panel-header-"+index}
        >
          <Typography className="">{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Typography className="accordionDetailsText">
            {item.text}
          </Typography>
          { item.cta && <Link className="accordionTextCTA">{item.cta}</Link>}
        </AccordionDetails>
      </Accordion>
      ))}
    </Box>
  );
};
export default AccordionComponent;
