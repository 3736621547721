/**
 * This file has the code for desktop footer
 */
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { facebookIcon, instagramIcon, youtubeIcon } from "../../assets/icons";
import "../../assets/css/jp-footer-desktop.css";
const Footer = ({ paths }) => {
  return (
    <>
      <Grid container className="footerRow">
        <Grid item md={12}>
          <Divider className="divider">
            <img src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png" />
          </Divider>
        </Grid>
        <Grid item md={12} className="footerLogo">
          <img src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png" />
        </Grid>
      </Grid>
      <Grid container className="footerRow1">
        <Grid item  className="footerColumn">
          
          <Typography className="footerTitle" variant="h4">
            QUICK LINKS
          </Typography>
          
          <Box className="footerLinks">
            <Typography className="shopByItems" variant="subtitle2">
              Contact Us
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Book an Appointment
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Store Locator
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Diamond Collection
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Customize Your Ring
            </Typography>
          </Box>
        </Grid>
        <Grid item className="footerColumn">
          <Typography className="footerTitle" variant="h4">
            CONSUMER EXPERIENCE
          </Typography>
          <Box className="footerLinks">
            <Typography className="shopByItems" variant="subtitle2">
              Diamond Cuts
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              White Series
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Ring Designs
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Ring Sizing Guide
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              After-Sales Policy
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Our Process
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Customization & Bespoke Process
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Timeline
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Packaging
            </Typography>
          </Box>
        </Grid>
        <Grid item className="footerColumn">
          <Typography className="footerTitle" variant="h4">
            ABOUT JANNPAUL
          </Typography>
          <Box className="footerLinks">
            <Typography className="shopByItems" variant="subtitle2">
              Miyabi Master Crafter
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Education
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Blog
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Press
            </Typography>
          </Box>
        </Grid>
        <Grid item  className="footerColumn">
          <Typography className="footerTitle" variant="h4">
            OUR POLICIES
          </Typography>
          <Box className="footerLinks">
            <Typography className="shopByItems" variant="subtitle2">
              Return Policy
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Lead Time
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Shipping Time
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Lifetime Warranty
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Terms & Conditions
            </Typography>
            <Typography className="shopByItems" variant="subtitle2">
              Privacy Policy
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container className="footerIconRow">
      <Grid  className="copyrightRow">
          <Typography className="copyRightText" variant="body2">
            &copy; JANNPAUL 2024 (VAT Number: 00739600153)
          </Typography>
        </Grid>       
        <Grid  >
          <Box className="footerIcon" direction={"row"}>
            <img className="headerIconList" src={facebookIcon} />
            <img className="headerIconList" src={instagramIcon} />
            <img className="headerIconList" src={youtubeIcon} />
          </Box>
        </Grid>

      </Grid>
    </>
  );
};
export default Footer;
