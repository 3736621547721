import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  plusIcon,
  minusBlackIcon
} from "../../assets/icons.js";
const ThumbnailVerticalSlider = ({ thumbnailArrayData, setImageIndex }) =>{
  // handling current image to be shown
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  // handling zoom level
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  // for zoom in
  const handleZoomIn = () => {
    const newZoomLevel = zoomLevel + 0.2;
    setZoomLevel(newZoomLevel);
  };
  //for zoom out
  const handleZoomOut = () => {
    const newZoomLevel = zoomLevel - 0.2;
    if (newZoomLevel >= 1) {
      setZoomLevel(newZoomLevel);
    }
  };
  // zoom in and out with respect to center
  const centerZoom = () => {
    const imgRect = imageRef.current.getBoundingClientRect();
    setPosition({
      x: imgRect.width / 2 / zoomLevel,
      y: imgRect.height / 2 / zoomLevel,
    });
  };
  // handle image change
  const handleChange = (tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex", height: 500 }} className="productShowcaseBox">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={currentTabIndex}
        onChange={handleChange}
        styles={{ marginRight: "30px" }}
        className="productShowcaseTabs"
      >
        {thumbnailArrayData.map((tab, index) => (
          <Tab
            label={<img src={tab} className="productShowcaseTabImage" />}
            onClick={() => {handleChange(index); setImageIndex(index)}}
            className={`${index === currentTabIndex ? "activeProductShowcaseTab" : "inactiveProductShowcaseTab"} productShowcaseTab`}
            key={index}
          />
        ))}
      </Tabs>
      <Box className="productShowcaseZoomableImageBox" >
        <img
          src={thumbnailArrayData[currentTabIndex]}
          ref={imageRef}
          style={{
            transform: `scale(${zoomLevel})`,
            transformOrigin: `${position.x}px ${position.y}px`,
          }}
          className="productShowcaseZoomableImage"
        />
              <Stack className="zoomInZoomOutSection">
        {/* <Button className="zoomButtons" onClick={() => {handleZoomIn(); centerZoom();}}>
          {"+"}
        </Button> */}
        <img src={plusIcon} className="zoomButtons" onClick={() => {handleZoomIn(); centerZoom();}}/>
        {/* <Button className="zoomButtons" onClick={() => { handleZoomOut(); centerZoom(); }}>
          {"-"}
        </Button> */}
        <img src={minusBlackIcon} className="zoomButtons" onClick={() => { handleZoomOut(); centerZoom(); }}/>
      </Stack>
      </Box>
    </Box>
  );
}
export default ThumbnailVerticalSlider;