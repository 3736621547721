/**
 * This file has the structure of Diamond information table
 */
import { useState } from "react";
import PopoverComponent from "../../components/popover.js";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { circleQuestionIcon } from "../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
const DiamondInformation = ({ diamondData, isMobile }) => {
  // on click popover
  const [showPopover, setShowPopover] = useState([false, null]);
  const [popoverKey, setPopoverKey] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e, popoverKey) => {
    setAnchorEl(e.currentTarget);
    console.log(e.currentTarget);
    setPopoverKey(popoverKey);
  };
  // show more content of table
  const [showMoreContent, setShowMoreContent] = useState(false);
  const data1 = [
    {
      key: "Shape",
      value: diamondData.shape,
    },
    {
      key: "Carat",
      value: diamondData.carat,
      popoverKey: "carat",
    },
    {
      key: "Color",
      value: diamondData.color,
      popoverKey: "color",
    },
    {
      key: "Clarity",
      value: diamondData.clarity,
      popoverKey: "clarity",
    },
    {
      key: "Cut",
      value: diamondData.cut,
      popoverKey: "cut",
    },
    {
      key: "Polish",
      value: diamondData.polish,
      popoverKey: "polish",
    },
    {
      key: "Symmetry",
      value: diamondData.symmetry,
      popoverKey: "symmetry",
    },
  ];
  const data2 = [
    {
      key: "Measurement",
      value: "5.30 x 5.31 x 3.26mm",
      popoverKey: "measurement",
    },
    {
      key: "Table %",
      value: diamondData.table,
      popoverKey: "table",
    },
    {
      key: "Depth %",
      value: diamondData.depth,
      popoverKey: "depth",
    },
    {
      key: "Fluorescent",
      value: "None",
      popoverKey: "fluorescence",
    },
    {
      key: "Brilliance",
      value: "Excellent",
      popoverKey: "brilliance",
    },
    {
      key: "Fire",
      value: "Excellent",
      popoverKey: "fire",
    },
    {
      key: "Scintillation",
      value: "Excellent",
      popoverKey: "scintillation",
    },
  ];

  return (
    <Container className="diamondInformationContainer">
      <Box className="diamondInformationTitleBox">
      <Typography variant="h3" className="diamondInformationTitle">Diamond Information</Typography>
      </Box>
      <Box className="diamondInformationGridContainersBox">
      <Grid container className="diamondInformationGridContainer1">
        <Grid item xs={6} md={6} className="diamondInformationKeysGrid">
          {data1.map((row, index) => (
            <Typography key={index} className={`${index%2 === 0 ? "diamondInformationGrayBg" : ""} diamonInformationKeysText`}>
              {row.key}
              {row.key === "Shape" ? (
                ""
              ) : (
                <img
                  src={circleQuestionIcon}
                  className="ddpCircleIcon"
                  onClick={(e) => {
                    setShowPopover([true, row.popoverKey]);
                    handleClick(e, row.popoverKey);
                  }}
                />
              )}
            </Typography>
          ))}
          {/* Showing popovers to the items */}
          {showPopover[0] && showPopover[1] === popoverKey && (
            <PopoverComponent
              filterKey={popoverKey}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setShowPopover={setShowPopover}
            />
          )}
        </Grid>
        <Grid item md={6} xs={6} className="diamondInformationValueGrid">
          {data1.map((row, index) => (
            <Typography key={index} className={`${index%2 === 0 ? "diamondInformationGrayBg" : ""} diamonInformationValuesText`}>{row.value}</Typography>
          ))}
        </Grid>
      </Grid>
      {isMobile ? (
        <Grid container className="diamondInformationGridContainer2">
          {showMoreContent && (
            <>
              <Grid item xs={6} md={6} className="diamondInformationKeysGrid">
                {data2.map((row, index) => (
                  <Typography className={`${index%2 !== 0 ? "diamondInformationGrayBg" : ""} diamonInformationKeysText`}>
                    {row.key}
                    <img
                      src={circleQuestionIcon}
                      className="ddpCircleIcon"
                      onClick={(e) => {
                        setShowPopover([true, row.popoverKey]);
                        handleClick(e, row.popoverKey);
                      }}
                    />
                  </Typography>
                ))}
              </Grid>
              <Grid item md={6} xs={6} className="diamondInformationValueGrid">
                {data2.map((row, index) => (
                  <Typography className={`${index%2 !== 0 ? "diamondInformationGrayBg" : ""} diamonInformationValuesText`}>{row.value}</Typography>
                ))}
              </Grid>
            </>
          )}
          <Grid item xs={12} className="diamondInformationShowMoreButtonGrid">
            <Box
              className="ShowMoreOrLessBox"
              onClick={() => setShowMoreContent(!showMoreContent)}
            >
              {showMoreContent ? (
                <Typography className="diamondInformationShowMoreText">
                  Show Less{" "}
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    className="diamondInformationShowMoreIcon"
                  />
                </Typography>
              ) : (
                <Typography className="diamondInformationShowMoreText">
                  Show More{" "}
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="diamondInformationShowMoreIcon"
                  />
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="diamondInformationGridContainer2">
          <Grid item md={6} className="diamondInformationKeysGrid">
            {data2.map((row, index) => (
              <Typography key={index} className={`${index%2 === 0 ? "diamondInformationGrayBg" : ""} diamonInformationKeysText`}>
                {row.key}
                <img
                  src={circleQuestionIcon}
                  className="ddpCircleIcon"
                  onClick={(e) => {
                    setShowPopover([true, row.popoverKey]);
                    handleClick(e, row.popoverKey);
                  }}
                />
              </Typography>
            ))}
          </Grid>
          <Grid item md={6} className="diamondInformationValueGrid">
            {data2.map((row, index) => (
              <Typography key={index} className={`${index%2 === 0 ? "diamondInformationGrayBg" : ""} diamonInformationValuesText`}>{row.value}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
      </Box>
    </Container>
  );
};
export default DiamondInformation;
