/**
 * This file has the structure for report modal in desktop
 */
import {useState } from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
    closeIcon
  } from "../../assets/icons.js";
const ViewReportModal = ({report, setViewReport}) =>{
    const [open, setOpen] = useState(true);
    const handleClose = () =>{
        setOpen(false);
        setViewReport(false);
    }
    return(
        <Modal
        open={open}
        onClose={handleClose}
        className="viewReportModal"
        >
           <Box className="viewReportGridBox">
           <Grid container className="viewReportGridContainer" >
            <Grid item md={12} className="viewReportImageGrid">
                <img src={report} className="viewReportImage" />
            </Grid>
            <Grid item md={1} className="viewReportCloseIconGrid">
                <img src={closeIcon} onClick={handleClose} className="viewReportCloseIcon" />
            </Grid>
            <Grid item md={12} className="viewReportButtonGrid" >
                <Button className="black-filled">Download report</Button>
            </Grid>
           </Grid>
           </Box>
        </Modal>
    );
}
export default ViewReportModal;