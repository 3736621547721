import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "html-react-parser";
import "../assets/css/components.css";
const InnerPageBanner = ( {bannerContentData, isMobile} ) =>{
 return(
    <Grid container className="bannerContainer">
        <Grid item md={12} >
          {/* Shifting between images for mobile and desktop */}
          <img
            src={isMobile ? bannerContentData.mobileImage : bannerContentData.desktopImage}
            className="bannerImage"
            loading="lazy"
          />
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={6} className="bannerContent">
          <Typography variant="h3" className="bannerText1">
            {parse(bannerContentData.title)}
          </Typography>
          <Typography variant="subtitle" className="bannerText2">
           {bannerContentData.text}
          </Typography>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
 );
}
export default InnerPageBanner;