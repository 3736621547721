/**
 * This file has the structure for consult an diamond expert pop up
 */
import { useState } from "react";
import {
  closeIcon,
  calendarIcon,
  phoneIcon,
  mailIcon,
} from "../../assets/icons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
const ConsultDiamondExpertPopUp = ({ setOpenConsultPopup, diamondData, image }) => {
  // handling drawer open and close
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setOpenConsultPopup(false);
  };
  const handleOpen = () => setOpen(true);
  // title dropdown
  const [title, setTitle] = useState(0);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  // location dropdown
  const [location, setLocation] = useState(0);

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      className="mobileConsultDiamondExpertDrawer"
    >
      <List className="ddpConsultDiamondExpertPopupList">
        <Box className="ddpConsultDiamondExpertPopupBox1">
          <ListItem className="ddpConsultDiamondExpertPopupListItem">
            <Typography className="ddpConsultDiamondExpertPopupTitle">
              Consult a diamond expert
            </Typography>
            {/* Closing the drawer when clicked on closeIcon */}
            <img
              src={closeIcon}
              onClick={handleClose}
              className="ddpConsultDiamondExpertPopupImage"
            />
          </ListItem>
        </Box>
        <Box className="ddpConsultDiamondExpertPopupBox2">
          <ListItem className="ddpConsultDiamondExpertPopupListItem">
            <Box className="consultDiamondExpertProductDetailsBox">
              <img src={image} className="consultDiamondExpertDiamondImage" />
              <Box className="popupProductDetailsSection">
              <Typography className="popupProductTitle">
                {diamondData.carat} {diamondData.cut} {diamondData.shape}
              </Typography>
              <Typography>
                {diamondData.color} Color · {diamondData.clarity}
              </Typography>
              </Box>
            </Box>
          </ListItem>
          <ListItem className="ddpConsultDiamondExpertPopupListItem">
            <Box className="consultDiamondContactDetailsBox">
              <Stack direction="row" className="consultDiamondContactDetailStack">
                <img src={mailIcon} className="contactIcons contactIcon1" />
                <Link className="contactDetails ddpEmailAddress">sales@jannpaul.com</Link>
              </Stack>
              <Divider className="consultDiamondContactDetailDivider1" />
              <Stack direction="row" className="consultDiamondContactDetailStack">
                <img src={phoneIcon} className="contactIcons contactIcon2" />
                <Link className="contactDetails ddpPhoneNumber">+65-90219891</Link>
              </Stack>
              <Divider className="consultDiamondContactDetailDivider2" />
              <Stack direction="row" className="consultDiamondContactDetailStack">
                <img src={calendarIcon} className="contactIcons contactIcon3" />
                <Typography className="contactDetails ddpBookAnAppointment">Book An Appointment<FontAwesomeIcon icon={faAngleRight} className="consultDiamondContactDetailsAngleRight" /></Typography>
                
              </Stack>
              <Divider className="consultDiamondContactDetailDivider3" />
            </Box>
          </ListItem>
          <ListItem className="ddpConsultDiamondExpertPopupListItem">
            <Stack className="ddpConsultDiamondExpertFormStack">
              <Typography variant="h3" className="ddpConsultDiamondExpertFormTitle">
                Consult a diamond expert
              </Typography>
              <Box component="form" className="ddpConsultDiamondExpertForm">
                <Typography className="ddpConsultDiamondExpertFormLabel">Title*</Typography>
                <FormControl fullWidth className="ddpConsultDiamondExpertFormControl" size="small">
                  <Select
                    id="select-title"
                    value={title}
                    onChange={handleTitleChange}
                    IconComponent={() => (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="dropdownArrow"
                      />
                    )}
                    placeholder={"Select Title"}
                    className="ddpConsultDiamondExpertFormSelectdd"
                  >
                    <MenuItem value={0} className="ddpConsultDiamondExpertFormSelectMenuItem">Select Title</MenuItem>
                    <MenuItem value={1} className="ddpConsultDiamondExpertFormSelectMenuItem">Title1</MenuItem>
                    <MenuItem value={2} className="ddpConsultDiamondExpertFormSelectMenuItem">Title2</MenuItem>
                    <MenuItem value={3} className="ddpConsultDiamondExpertFormSelectMenuItem">Title3</MenuItem>
                  </Select>
                </FormControl>
                <Typography className="ddpConsultDiamondExpertFormLabel">First Name*</Typography>
                <TextField size="small" required id="popup-first-name" fullWidth />
                <Typography className="ddpConsultDiamondExpertFormLabel">Last Name*</Typography>
                <TextField size="small" required id="popup-last-name" fullWidth />
                <Typography className="ddpConsultDiamondExpertFormLabel">E-mail*</Typography>
                <TextField size="small" required id="popup-email" fullWidth />
                <Typography className="ddpConsultDiamondExpertFormLabel">Phone*</Typography>
                <TextField size="small" required id="popup-phone" fullWidth />
                <Typography className="ddpConsultDiamondExpertFormLabel">Location*</Typography>
                <FormControl fullWidth className="ddpConsultDiamondExpertFormControl" size="small">
                  <Select
                    id="select-location"
                    value={location}
                    onChange={handleLocationChange}
                    IconComponent={() => (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="dropdownArrow"
                      />
                    )}
                    className="ddpConsultDiamondExpertFormSelectdd"
                  >
                    <MenuItem value={0} className="ddpConsultDiamondExpertFormSelectMenuItem">Singapore</MenuItem>
                    <MenuItem value={1} className="ddpConsultDiamondExpertFormSelectMenuItem">Location2</MenuItem>
                    <MenuItem value={2} className="ddpConsultDiamondExpertFormSelectMenuItem">Location3</MenuItem>
                  </Select>
                </FormControl>
                <Typography className="ddpConsultDiamondExpertFormLabel">Enquire Message</Typography>
                <TextField size="small"
                  id="mutiline-query"
                  multiline
                  rows={3}
                  fullWidth
                />
                <Button className="black-filled consultDiamondExpertButton">Send Enquiry</Button>
              </Box>
            </Stack>
          </ListItem>
        </Box>
      </List>
    </SwipeableDrawer>
  );
};
export default ConsultDiamondExpertPopUp;
