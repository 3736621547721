/**
 * This file has strcture for Diamond journey section in diamond details page
 */
import { useState } from "react";
import DropDownComponent from "../../components/dropdown.js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const DiamondJourney = ({ isMobile }) => {
  const [dataIndex, setDataIndex] = useState(0);
  const handleTabChange = (index) => {
    setDataIndex(index);
  };
  const roughSToneData = [
    {
      name: "Rough Stone 1",
      text1: "Lorem ipsum dolor sit amet.",
      image:
        "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
      certificateNo: "123456789",
      paragraph:
        "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
    },
    {
      name: "Rough Stone 2",
      text1: "Lorem ipsum dolor sit amet.2",
      image:
        "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
      certificateNo: "123456789",
      paragraph:
        "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
    },
    {
      name: "Rough Stone 3",
      text1: "Lorem ipsum dolor sit amet.3",
      image:
        "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
      certificateNo: "123456789",
      paragraph:
        "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
    },
    {
      name: "Rough Stone 4",
      text1: "Lorem ipsum dolor sit amet.4",
      image:
        "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
      certificateNo: "123456789",
      paragraph:
        "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
    },
    {
      name: "Rough Stone 5",
      text1: "Lorem ipsum dolor sit amet.5",
      image:
        "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
      certificateNo: "123456789",
      paragraph:
        "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
    },
    {
        name: "Rough Stone 6",
        text1: "Lorem ipsum dolor sit amet.6",
        image:
          "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
        certificateNo: "123456789",
        paragraph:
          "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
      },
      {
        name: "Rough Stone 7",
        text1: "Lorem ipsum dolor sit amet.7",
        image:
          "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
        certificateNo: "123456789",
        paragraph:
          "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
      },
      {
        name: "Rough Stone 8",
        text1: "Lorem ipsum dolor sit amet.8",
        image:
          "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
        certificateNo: "123456789",
        paragraph:
          "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
      },
      {
        name: "Rough Stone 9",
        text1: "Lorem ipsum dolor sit amet.9",
        image:
          "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
        certificateNo: "123456789",
        paragraph:
          "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
      },
      {
        name: "Rough Stone 10",
        text1: "Lorem ipsum dolor sit amet.10",
        image:
          "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
        certificateNo: "123456789",
        paragraph:
          "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
      },
      {
        name: "Rough Stone 11",
        text1: "Lorem ipsum dolor sit amet.11",
        image:
          "https://staging.jannpaul.com/wp-content/uploads/product-uploads/5933/diamondJourneyData/HP23011601 Round 10 Hearts & Arrows 0.39 D VVS1 Gcal 340570000(1).jpg",
        certificateNo: "123456789",
        paragraph:
          "(SAMPLE PARA - 6) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.",
      },
  ];
  const ddData = [];
  for (let i = 0; i < roughSToneData.length; i++) {
    ddData.push(roughSToneData[i].name);
  }
  // console.log(dataIndex);
  return (
    <Grid container className="DiamondJourneyBox">
      <Grid item md={5} xs={12} className="ddpDiamondJourneyGrid1">
        {isMobile ? (
          <DropDownComponent data={ddData} setDataIndex={setDataIndex} />
        ) : (
          <Box className="ddpDiamondJourneyTabBox">
            <Tabs
            orientation="vertical"
            variant="scrollable"
            value={dataIndex}
            onChange={handleTabChange}
            className="ddpDiamondJourneyTabsContainer"
          >
            {roughSToneData.map((tab, index) => (
              <Tab
              key={index}
                label={
                  <Stack
                    direction="row"
                    className={`${
                      dataIndex === index
                        ? "ddpDiamondJourneyTabLabelStackActive"
                        : ""
                    } ddpDiamondJourneyTabLabelStack`}
                  >
                    <Box className="ddpDiamondJourneyTabLabelBox">
                      <Typography
                        variant="h3"
                        className="diamondJourneyRoughStoneTitle"
                      >
                        {tab.name}
                      </Typography>
                      <Typography className="diamondJourneyRoughStoneText">
                        {tab.text1}
                      </Typography>
                    </Box>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="ddpDiamondJourneyTabIcon"
                    />
                  </Stack>
                }
                onClick={() => handleTabChange(index)}
                className={`${
                  dataIndex === index
                    ? "ddpDiamondJourneyActiveTab"
                    : "ddpDiamondJourneyTab"
                }  ddpDiamondJourneyTab${
                  index + 1
                }`}
              />
            ))}
          </Tabs>
          </Box>
        )}
      </Grid>
      {isMobile ? (
        <Grid item xs={12} className="ddpDiamondJourneyGridMobile">
          <Typography variant="h3" className="diamondJourneyRoughStoneTitle">
            {roughSToneData[dataIndex].name}
          </Typography>
          <Typography className="diamondJourneyRoughStoneText">
            {roughSToneData[dataIndex].text1}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid item md={7} xs={12} className="ddpDiamondJourneyGrid2">
        <Box className="ddpDiamondJourneyGridBox">
          <img
            src={roughSToneData[dataIndex].image}
            className="diamondJourneyroughStoneImage"
          />
          <Stack className="diamondJourneyImageDescStack" direction="row">
          <Typography className="diamondJourneyImageDesc1">
            {roughSToneData[dataIndex].name} 
          </Typography>
          <Typography className="diamondJourneyImageDesc2">
          / Certificate No.{" "}
          {roughSToneData[dataIndex].certificateNo}
          </Typography>
          </Stack>
        </Box>
        <Typography className="diamondJourneyParagraph">
          {roughSToneData[dataIndex].paragraph}
        </Typography>
        <Button className="black-filled">View More on EBook</Button>
      </Grid>
    </Grid>
  );
};
export default DiamondJourney;
