/**
 * This file contains the choose your diamond diamond slider
 */
import {useRef, useState} from 'react';
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import "../assets/css/components.css";
const DiamondSlider = ( {diamondTypes, isMobile, pageName, setSelectedFilters = null} ) =>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ activeSlideBox, setActiveSlideBox ] = useState(null);
    // Using ref for next and prev buttons
    let sliderRef = useRef(null);
    //  function for shifting to next item
    const next = () => {
        sliderRef.current.slickNext();
    };
    //  function for shifting to prev item
    const prev = () => {
        sliderRef.current.slickPrev();
    };
    // Need to show 8 for start with setting page and 7 for choose your diamond
    const visbleItems = pageName === "Start With Setting" ? 8 : 7;
    // Settings for slider
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? 3 : visbleItems,
        slidesToScroll: 1,
        afterChange: (index) => {setCurrentIndex(index)},
      };
      // console.log(diamondTypes);
      // array to store name of diamond or settings shapes
      let arr = [];
      for(let i=0;i<diamondTypes.length;i++){
        arr.push(diamondTypes[i].name);
      }
      // check whether the value is shape
      const isShape = (shape) =>{
        let flag = false;
        // console.log(arr);
        for(let i=0;i<arr.length;i++){
          if(arr[i] === shape){
            flag = true;
          }
        }
        // console.log(flag);
        // if it is a shape then return true else return false
        return flag;
      }
      // handle selected shape - one at a time
      const handleSelectedShapes = (shape) =>{
        // if the prev array contains same shape or the item is another shape then remove it
        setSelectedFilters((prevArray)=> prevArray.filter(item => item !== shape && !isShape(item)));
        // add the shape in state variable
        setSelectedFilters((prevArray)=>[...prevArray, shape]);
        // console.log(shape);
      }
    return (
      <Grid container id="slider-box" className="sliderBox">
        <Grid item xs={1} className="sliderPrevBox">
          <FontAwesomeIcon
            onClick={prev}
            icon={faAngleLeft}
            className="sliderPrevBtn sliderBtns"
            style={currentIndex === 0 ? { cursor: "no-drop" } : ""}
          />
        </Grid>
        <Grid item xs={10} className="sliderGrid">
          <Slider
            className="sliderContainer"
            id="slider-container"
            {...settings}
            ref={sliderRef}
          >
            {diamondTypes.map((diamond, index) => (
              <Box
                key={index}
                className={`${
                  index === activeSlideBox
                    ? "activeSliderBox"
                    : "InactiveSliderBox"
                } sliderBox`}
                // if selected filter is present in the page then only go to handleSelectedShapes function
                onClick={() => {setSelectedFilters && handleSelectedShapes(diamond.name); setActiveSlideBox(index)}}
              >
                <img src={diamond.image} className="sliderImage" />
                <Typography className="sliderText">{diamond.name}</Typography>
              </Box>
            ))}
          </Slider>
        </Grid>
        <Grid item xs={1} className="sliderNextBox">
          <FontAwesomeIcon
            onClick={next}
            icon={faAngleRight}
            className="sliderNextBtn sliderBtns"
            style={
              currentIndex === diamondTypes.length - 7
                ? { cursor: "no-drop" }
                : ""
            }
          />
        </Grid>
      </Grid>
    );
}
export default DiamondSlider;