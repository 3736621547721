/**
 * This file has the code for homepage banner for mobile
 */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
const MobileBanner = ({ bannerMedia }) => {
  // props for Slider component
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box id="homepage-banner-mobile">
      {/* Slider component from react-slick library */}
      <Slider {...settings}>
        {/* looping through array */}
        {bannerMedia.map((item, index) =>
          index === 0 || index === 2 ? (
            // For video
            <Box key={index}>
              {/* <video autoPlay muted loop className="mobileBannerVideo">
                <source src={item.source} type="video/mp4" />
              </video>
                <Box>
                    <Divider />
                    <Typography>{item.name}</Typography>
                    <Divider />
                    <Typography>{item.bannerText}</Typography>
                    <Button variant="outlined">Learn More</Button>
                </Box> */}
              {/* Using ImageList */}
              <ImageListItem key={item.name}>
                <video
                  src={item.source}
                  autoPlay
                  muted
                  loop
                  alt={item.name}
                  className="mobileBannerVideo mobileBannerMedia"
                ></video>
                <ImageListItemBar className="mobileBannerColumn"
                  title={
                    <>
                      <Divider className="mobileBannerDivider" />
                      <Typography className="mobileBannerTitle">{item.name}</Typography>
                      <Divider className="mobileBannerDivider" />
                    </>
                  }
                  subtitle={
                    <Typography className="mobileBannerText">
                      {item.bannerText}
                      <br />
                      <Button className="mobileBannerButton" variant="outlined">Learn More</Button>
                    </Typography>
                  }
                />
              </ImageListItem>
            </Box>
          ) : (
            // For Image
            <Box key={index}>
              <ImageListItem key={item.name}>
                <img
                  srcSet={`${item.source}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.source}?w=248&fit=crop&auto=format`}
                  alt={item.name}
                  loading="lazy"
                  className="mobileBannerImage mobileBannerMedia"
                />
                <ImageListItemBar className="mobileBannerColumn"
                  title={ 
                    <>
                      <Divider className="mobileBannerDivider" />
                      <Typography className="mobileBannerTitle">{item.name}</Typography>
                      <Divider className="mobileBannerDivider" />
                    </>
                  }
                  subtitle={
                    <Typography className="mobileBannerText">
                      {item.bannerText}
                      <br />
                      <Button className="mobileBannerButton" variant="outlined">Learn More</Button>
                    </Typography>
                  }
                />
              </ImageListItem>
            </Box>
          )
        )}
      </Slider>
    </Box>
  );
};
export default MobileBanner;