/**
 * This file contains search bar
 */
import { searchIcon, closeIcon } from "../../assets/icons";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { useState } from "react";
const SearchBar = ({ handleClose }) => {
    // toggling after enter key down
    const [ toggleSearchBar, setToggleSearchBar ] = useState(false);
    return(
        <Grid container className="headerToggleRow" >
            <Grid item className="jpToggleSearchIcon" xs={1}>
                <img src={searchIcon} />
            </Grid>
            <Grid item className="jpToggleSearchbox" xs={10}>
                <InputBase className="inputText"
                placeholder="I am looking for..."
                onKeyDown={(e)=>{(e.key === "Enter" && e.target.value !== "") && setToggleSearchBar(!toggleSearchBar)}}
                // onChange={(e)=>{e.key === "Enter" && setToggleSearchBar(!toggleSearchBar)}}
                />
            </Grid>
            <Grid item className="jpToggleSearchCancel" xs={1}>
                <img src={closeIcon} onClick={()=>handleClose(false)} />
            </Grid>
            <Grid item xs={12} id="search-result">
            <Collapse orientation="down" in={toggleSearchBar}>
                <Typography>No data found</Typography>
            </Collapse>
            </Grid>
        </Grid>
    );
}
export default SearchBar;