/**
 * This file has the code for filter popup in Start with setting page mobile
 */
import { useState } from "react";
import DualSlider from '../choose-your-diamond/cyd-dual-slider';
import RadioButton from "../../components/radio-button.js";
import CheckboxComponent from "../../components/checkbox-button.js";
import { closeIcon, circleQuestionIcon } from '../../assets/icons';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Chip from '@mui/material/Chip';
const MobileFilterPopup = ({ dataLength, setToggleFilter, metalFilterData, diamondShapeFilterData }) => {
  // Opening popup
  const [open, setOpen] = useState(true);
  // Setting toggleFilter value to false and the open value to false as well
  const handleClose = () => {
    setToggleFilter(false);
    setOpen(false);
  };
  // Selected filter values
  const [ selectedFilters, setSelectedFilters ] = useState([]);
  // price slider min-max values
  const [priceSliderRange, setPriceSliderRange] = useState([1000, 1000000]);
  // Remove filter when clicked on respective filter
  const handleChipRemoval = (filterToRemove) =>{
    // remove the selected chip
    setSelectedFilters((chips) => chips.filter((chip) => chip !== filterToRemove));
  }
  // Reset all links
  const [resetLinks , setResetLinks] = useState({
    resetMetalSection: false,
    resetDiamondShapeSection: false,
    resetAll: false
  });
  // reset slider
  const [resetAllSliders, setResetAllSliders] = useState(false);
  // handle reset all
  const handleResetAll = () =>{
    setResetLinks({...resetLinks, resetAll: true});
    setPriceSliderRange([1000, 1000000]);
    setSelectedFilters([]);
    setResetAllSliders(true);
  }
  // handle section wise reset
  const handleSectionReset = (section) =>{
    // if(section === "Metal"){
    //   console.log("Metal section reset");
    //   setResetLinks({...resetLinks, resetMetalSection: true});
    // }else if(section === "Diamond shape"){
    //   console.log("Diamond shape section reset");
    //   setResetLinks({...resetLinks, resetDiamondShapeSection: true});
    // }
    setResetLinks(prevResetLinks => {
      // Create a new state object based on the current state
      const newResetLinks = { ...prevResetLinks };

      // Update the appropriate section based on the provided `section`
      if (section === "Metal") {
          console.log("Metal section reset");
          newResetLinks.resetMetalSection = true;
      } else if (section === "Diamond shape") {
          console.log("Diamond shape section reset");
          newResetLinks.resetDiamondShapeSection = true;
      }

      console.log("==================== SWAP ==================== newResetLinks: ", newResetLinks);

      // Return the new state object
      return newResetLinks;
  });
  }
  // console.log("reset: ",resetLinks);
  // diamond shape names array
  let diamondShapesNames = [];
  for(let i = 0; i < diamondShapeFilterData.length ; i++){
    diamondShapesNames[i] = diamondShapeFilterData[i].shape;
  }
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      onOpen={()=>setOpen(true)}
      className="swsMobileFilterPopup"
    >
      <List className="swsMobileFilter">
        <Box className="swsMobileFilterHeaderBox">
          <ListItem className="swsFilterHeader">
            <Typography className="swsFilterTitle">Filter By</Typography>
            {/* Closing the drawer when clicked on closeIcon */}
            <img
              src={closeIcon}
              className="filterCloseButton"
              onClick={() => setToggleFilter(false)}
            />
          </ListItem>
        </Box>
        <Box className="swsMobileFilterContentBox">
          <ListItem className="sortByRadioButtonsBox">
            <FormControl className="sortByRadioButtonsContainer">
              <FormLabel
                id="sort-by-filter-title"
                className="filterRadioButtonsTitle"
              >
                Sort by
              </FormLabel>
              <RadioGroup
                defaultValue="New To Old"
                className="filterRadioButtonsGroup"
              >
                <RadioButton className="jpLabel" value={"New To Old"} />
                <RadioButton className="jpLabel" value={"Price Low To High"} />
                <RadioButton className="jpLabel" value={"Price High To Low"} />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem className="swsMobileFilterPriceBox">
            <DualSlider
              min={priceSliderRange[0]}
              max={priceSliderRange[1]}
              steps={1000}
              filterName={"Price"}
              prefix={"SGD"}
              filterKey="price"
              resetAllSliders={resetAllSliders}
              setResetAllSliders={setResetAllSliders}
            />
          </ListItem>
          <ListItem className="swsMobileMetalFilterBox">
            <Grid container className="metalFilterCheckboxContainer">
              <Grid item xs={6} className="metalFilterTitleAndResetlink">
                <Typography className="metalFilterTitle">Metal<img src={circleQuestionIcon} className="swsCircleQuestionIcon"/></Typography>
              </Grid>
              <Grid item xs={6} className="metalFilterReset">
                <Link className="swsFilterResetLink" onClick={()=>handleSectionReset("Metal")}>Reset</Link>
              </Grid>
              <Grid item xs={12} className="metalFilterCheckboxes">
              <FormGroup className="filterCheckboxGroup">
                {metalFilterData.map((item, index)=>(
                  <CheckboxComponent key={index} value={item} setSelectedFilters={setSelectedFilters} resetAll={resetLinks.resetAll} sectionReset={resetLinks.resetMetalSection} setResetLinks={setResetLinks} data={metalFilterData}/>
                ))}
              </FormGroup>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className="swsMobileDiamondShapeFilterBox">
            <Grid container className="diamondShapeFilterCheckboxContainer">
              <Grid item xs={6} className="diamondShapeFilterTitleAndResetlink">
                <Typography className="diamondShapeFilterTitle">Diamond Shape<img src={circleQuestionIcon} className="swsCircleQuestionIcon"/></Typography>
              </Grid>
              <Grid item xs={6} className="diamondShapeFilterReset">
                <Link className="swsFilterResetLink" onClick={()=>handleSectionReset("Diamond shape")}>Reset</Link>
              </Grid>
              <Grid item xs={6} className="diamondShapeFilterCheckboxes1">
              <FormGroup className="filterCheckboxGroup">
                {diamondShapeFilterData.slice(0,4).map((item, index)=>(
                  <CheckboxComponent key={index} value={item.shape} img={item.image} setSelectedFilters={setSelectedFilters} resetAll={resetLinks.resetAll} sectionReset={resetLinks.resetDiamondShapeSection} setResetLinks={setResetLinks} data={diamondShapesNames}/>
                ))}
              </FormGroup>
              </Grid>
              <Grid item xs={6} className="diamondShapeFilterCheckboxes2">
              <FormGroup className="filterCheckboxGroup">
              {diamondShapeFilterData.slice(4).map((item, index)=>(
                  <CheckboxComponent key={index} value={item.shape} img={item.image} setSelectedFilters={setSelectedFilters} resetAll={resetLinks.resetAll} sectionReset={resetLinks.resetDiamondShapeSection} setResetLinks={setResetLinks} data={diamondShapesNames}/>
                ))}
              </FormGroup>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
        <Box className="swsMobileFilterFooterBox">
          <ListItem className="swsFilterFooter">
            <Grid container className="swsFilterFooterGridContainer">
              {selectedFilters.length !== 0 && (<>
                <Grid item xs={9} className="swsSelectedFiltersGrid">
                {selectedFilters && selectedFilters.map((filter, index)=>(
                <Chip key={index} icon={<img src={closeIcon} className="jpSelectedChipCloseIcon" onClick={()=>{console.log(filter); handleChipRemoval(filter)}} />} label={filter} className="jpSelectedItemChip" />
              ))}
            </Grid>
            <Grid item xs={3} className="swsResetLinkGrid">
              <Link className="swsResetLink" onClick={handleResetAll}>Reset All</Link>
            </Grid>
            </>
              )}
              <Grid item xs={12} className="swsViewResultsButtonGrid">
                <Button
                className="black-filled swsFilterViewResultsButton"
                onClick={() => setToggleFilter(false)}
              >
                View {dataLength} Results
              </Button>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      </List>
    </SwipeableDrawer>
  );
};
export default MobileFilterPopup;