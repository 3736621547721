/**
 * This file has the structure for product view drawer in mobile view
 */
import { useState } from "react";
import { Link as RoutingLink } from "react-router-dom"; 
import { closeIcon } from "../../assets/icons";
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { cydDiamondImage } from "../../assets/cyd-media.js";
const ProductViewDrawer = ({ data, index, setToggleProductView }) =>{
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setToggleProductView(false);
  }
  const handleOpen = () => {
    setOpen(true);
  }
  console.log(index);
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      className="mobileProductViewDrawer"
    >
      <List className="cydMobileProductView">
      <Box className="cydProductViewTtileSection">
        <ListItem className="cydMobileProductViewItem">
          <Typography className="cydMobileProductViewTitle">Product View</Typography>
          {/* Closing the drawer when clicked on closeIcon */}
          <img
            src={closeIcon}
            onClick={handleClose}
            className="cydMobileProductViewCloseButton"
            loading="lazy"
          />
        </ListItem>
        </Box>
        <Box className="productViewDeatilsSection">
        <ListItem className="cydMobileProductViewItem" >
          <img src={cydDiamondImage} className="cydProductViewImage" loadin="lazy" />
        </ListItem>
        <ListItem className="cydMobileProductViewItem productViewTitle">
          <Stack className="cydProductViewTextStack">
            <Typography className="cydProductViewText1 productTitleBlack">
              {data.carat} {data.color} {data.clarity} {data.cut}
              {data.shape}
            </Typography>
            <Typography className="cydProductViewText2">
              {data.shape} · {data.carat} · {data.color} ·{data.clarity} ·{" "}
              {data.cut}
            </Typography>
            <Typography className="cydProductViewText3">{"SGD5,200"}</Typography>
          </Stack>
        </ListItem>
        <ListItem className="cydMobileProductViewItem">
          <Grid container className="cydProductViewTable borderTop" spacing={2}>
            <Grid item xs={3} className="cydProductViewTableCol1">
              <Typography>Polish</Typography>
              <Typography>Symmetry</Typography>
              <Typography>Fluorescent</Typography>
              <Typography>Depth%</Typography>
              <Typography>Table%</Typography>
              <Typography>Certified Lab</Typography>
            </Grid>
            <Grid item xs={3} className="cydProductViewTableCol2">
              <Typography>{data.polish}</Typography>
              <Typography>{data.symmetry}</Typography>
              {/* <Typography>{data.fluorescent}</Typography> */}
              <Typography>{"none"}</Typography>
              <Typography>{data.depth}%</Typography>
              <Typography>{data.table}%</Typography>
              {/* <Typography>{data.certifiedLab}</Typography> */}
              <Typography>{"GCAL"}</Typography>
            </Grid>
            <Grid item xs={3} className="cydProductViewTableCol3"></Grid>
            <Grid item xs={3} className="cydProductViewTableCol4"></Grid>
          </Grid>
        </ListItem>
        </Box>
        <Box className="cydProductViewCTASection">
        <ListItem className="cydMobileProductViewItem">
          <Grid container className="cydMobileProductViewButtonStack" spacing={2}>
           <Grid item xs={12}> 
           <RoutingLink key={"startWithSettingLink"} to="/start-with-setting" state={data} className="linkComponent fullWidthButton" >
            <Button className="black-filled">Select this diamond</Button>
            </RoutingLink>
            </Grid>
            <Grid item xs={12}>
            <RoutingLink to="/diamond-details" state={data} className="linkComponent">
            <Button className="blackOutlinedButton">View Details</Button>
            </RoutingLink>
            </Grid>
          </Grid>
        </ListItem>
        </Box>
      </List>
    </SwipeableDrawer>
  );
}
export default ProductViewDrawer;