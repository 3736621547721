/**
 * This file has the code for desktop engagement rings menu
 */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import engagementRingsImage from "../../assets/media/mega-menu-media/engagement-rings-img.png";
import { settingIcon, diamondRound, diamondCushion, diamondOval, diamondDecagon, diamondRadiant, diamondShield, diamondEmerald } from "../../assets/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const shopByDiamondList = [
  [{
    name: "Round",
    image: diamondRound,
  },
  {
    name: "Oval",
    image: diamondOval,
  },
  {
    name: "Decagon",
    image: diamondDecagon,
  },
  {
    name: "Cushion",
    image: diamondCushion,
  },],
  [ {
    name: "Radiant",
    image: diamondRadiant,
  },
  {
    name: "Shield",
    image: diamondShield,
  },
  {
    name: "Emerald",
    image: diamondEmerald,
  },]
];
const shopBySettingList = [["Solitare", "Petite", "Halo", "Side Stone"],["Pave", "Ring Stone 6", "Ring Stone 7"]];
const shopByCategoryList = [
  "Engagement Rings",
  "Wedding Bands",
  "Category 3",
  "Category 4",
];
const EngagementRings = () => {
  return (
    <Container className="menuTabContainer">
      <Grid container className="insideTabContainer">
        <Grid item md={3}>
          <Typography variant="subtitle1" className="shopByTitles">
            Shop by Setting <FontAwesomeIcon icon={faAngleRight} />
          </Typography>
          {/* Shop by setting stack */}
          <Stack className="settingList" direction="row">
            <Grid item md={6}>
              {shopBySettingList[0].map((ringStone, index) => (
                <Typography
                  variant="subtitle2"
                  className="shopByItems"
                  key={index}
                >
                  <img src={settingIcon} className="shopByDiamondImages"/> {ringStone}
                </Typography>
              ))}
            </Grid>
            <Grid item md={6}>
              {shopBySettingList[1].map((ringStone, index) => (
                <Typography
                  variant="subtitle2"
                  className="shopByItems"
                  key={index}
                >
                  <img src={settingIcon} className="shopByDiamondImages"/> {ringStone}
                </Typography>
              ))}
            </Grid>
          </Stack>
          <Typography variant="subtitle1" className="shopByTitles">
            Shop by Diamond <FontAwesomeIcon icon={faAngleRight} />
          </Typography>
          {/* Shop by Diamond stack */}
          <Stack direction="row">
            <Grid item md={6}>
              {shopByDiamondList[0].map((diamond, index) => (
                <Typography
                  variant="subtitle2"
                  className="shopByItems"
                  key={index}
                >
                  <img src={diamond.image} className="shopByDiamondImages" />{" "}
                  {diamond.name}
                </Typography>
              ))}
            </Grid>
            <Grid item md={6}>
              {shopByDiamondList[1].map((diamond, index) => (
                <Typography
                  variant="subtitle2"
                  className="shopByItems"
                  key={index}
                >
                  <img src={diamond.image} className="shopByDiamondImages" />{" "}
                  {diamond.name}
                </Typography>
              ))}
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={3} className="shopByContainer">
          <Typography variant="subtitle1" className="shopByTitles">
            Shop by Collection <FontAwesomeIcon icon={faAngleRight} />
          </Typography>
          {/* Shop by Collection */}
          {shopByCategoryList.map((category, index) => (
            <Typography variant="subtitle2" className="shopByItems" key={index}>
              {category}
            </Typography>
          ))}
        </Grid>
        {/* Card to be displayed */}
        <Grid item md={6} className="menuRightSideContentOne">
          <img src={engagementRingsImage} className="menuRightSideContentImage"/>
          <Box className="menuRightSideContentText">
            <Typography className="engagementTitle" variant="h5">Engagement <br />Rings</Typography>
            <Typography className="paraDiamond" variant="subtitle1">
              Engagement Rings Description
            </Typography>
            <Button className="menuBtnDiamond">Shop Now</Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EngagementRings;