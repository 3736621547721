/**
 * This file has the structure for sliders in grid
 */
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { wishlistIcon } from "../../assets/icons.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const GridSliders = ({ data, images, isMobile, index }) => {
  // On mouse enter for desktop
  const [showDesktopSlider, setShowDesktopSlider] = useState(false);
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIcon
        className={className + " gridSliderArrows gridSliderNextArrow"}
        onClick={onClick}
        icon={faAngleRight}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIcon
        className={className + " gridSliderArrows gridSliderPrevArrow"}
        onClick={onClick}
        icon={faAngleLeft}
      />
    );
  }
  //props for slider component
  const settings = {
    dots: isMobile ? true : false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return isMobile ? (
    <Slider {...settings} className="gridSliderContainer">
      {images.map((image, index) => (
        <img src={image} key={index} className="gridSliderImage" />
      ))}
    </Slider>
  ) : (
    <>
      {/* {!showDesktopSlider && ( */}
      {!showDesktopSlider && (
        <img
          className="gridThumbnailImage"
          src={images[0]}
          onMouseEnter={() => {
            setShowDesktopSlider(true);
          }}
        />
      )}
      {showDesktopSlider && (
        <Box
          className={`gridSliderContainerParentBox gridSliderContainerParentBox${index}`}
          onMouseLeave={() => {
            setShowDesktopSlider(false);
          }}
        >
          <Slider {...settings} className="gridSliderContainer">
            {images.map((image, index) => (
              <img src={image} key={index} className="gridSliderImage" />
            ))}
          </Slider>
          <Box className="gridSliderDetailsBox">
            <Stack direction="row" className="gridSliderDetailsStack">
              <Box className="gridSliderText">
                <Typography className="gridSliderText1">{data[1]}</Typography>
                <Typography className="gridSliderText2">{data[0]}</Typography>
              </Box>
              <img src={wishlistIcon} className="productWishlistIcon" />
            </Stack>
            <Button className="swdGridButton blackOutlinedButton fullWidthButton">View details</Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default GridSliders;