/**
 * This component has the structure of Choose Your Diamond page
 */
import { useEffect, useState } from "react";
import ListView from "./cyd-list-view";
import GridView from "./cyd-grid-view";
import DiamondSettingsSlider from "../../components/diamond-settings-slider";
import ProcessSteps from "../../components/process-steps.js";
import InnerPageBanner from "../../components/inner-page-banner";
import MobileProcessSteps from "../../components/mobile-process-steps";
import MobileFilterDrawer from "./cyd-mobile-filters.js";
import RangeSliderFilters from "./cyd-range-slider-filters";
import Header from '../../components/header/header';
import MobileHeader from "../../components/header/mobile-header";
import MobileFooter from "../../components/footer/mobile-footer";
import Footer from "../../components/footer/footer";
import CTACard from "../../components/cta-card";
import Loader from "../../components/loader-popup";
// importing useFetch hook (custom hook) for rendering data
import useFetch from "../../services/fetch-data";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import NativeSelect from "@mui/material/NativeSelect";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
  cydBannerImage,
  cydMobileBannerImage,
  cydDiamondImage,
  cydStepperDiamondImage,
  cydStepperSettingImage,
  cydStepperRingImage,
  cydCTACardImage1,
  cydCTACardImage2,
  roundDiamondImage,
  ovalDiamondImage,
  decagonDiamondImage,
  cushionDiamondImage,
  radiantDiamondImage,
  shieldDiamondImage,
  emeraldDiamondImage,
  cushionXImage, heart2Image, marquiseImage, octagonNovaImage, ovalXImage, radiantXImage, shield2Image
} from "../../assets/cyd-media.js";
import { filterIcon, activeGridViewIcon, inactiveListViewIcon, inactiveGridViewIcon, activeListViewIcon } from "../../assets/icons.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
// Min and max values for range sliders
const rangeSlidersDefaultMinMaxValues = {
  carat: [0.5, 7],
  color: ["G", "F", "E", "D"],
  price: [1000, 1000000],
  clarity: ["FL", "IF", "VVS1", "VVS2", "VS1", "VS2"],
  polish: ["Good", "Very Good", "Excellent"],
  symmetry: ["Good", "Very Good", "Excellent"],
  depth: [0, 100],
  table: [0, 100],
  activeCertifiedLab: "GCAL",
};
// CTA cards data
const ctaCardsData = [
  {
    image: cydCTACardImage1,
    imageTitle: "Ask a JANNPAUL Diamond Specialist",
    imageSubtitle:
      "Jannpaul Diamond Specialist can assist you in choosing an engagement ring, personalizing a wedding band or selecting a special anniversary gift.",
    linkToNewPage: "",
    ctaName: "BOOK An APPOINTMENT"
  },
  {
    image: cydCTACardImage2,
    imageTitle: "SEND US YOUR REQUEST",
    imageSubtitle:
      "Not all diamond inventory are listed, for more options please contact us.",
      linkToNewPage: "",
    ctaName: "Contact Us"
  },
];
// Banner content
const bannerContentData = {
  desktopImage: cydBannerImage,
  mobileImage: cydMobileBannerImage,
  title: "JannPaul White Series<sup>TM</sup> Diamonds",
  text: "Your journey begins here, select your preferred choice of the highest cut standards in the world within our in-house inventory."
}
const ChooseYourDiamondLanding = ({ isMobile, paths }) => {
  //importing css files according to desktop and mobile view
  isMobile ? import("../../assets/css/choose-your-diamond/choose-your-diamond-mobile.css") : import("../../assets/css/choose-your-diamond/choose-your-diamond-desktop.css");
  // api url for data fetching
  const apiURL =
    "https://jannpaulvc.learnedstudio.com/wp-content/plugins/les-product-management/public/factory-jannpaul-sync-handler.php";
  const [chooseYourDiamondData, setChooseYourDiamondData] = useState(
    rangeSlidersDefaultMinMaxValues
  );
  // Handling natural and lab grown diamond diamond shifts
  const [diamondCategory, setDiamondCategory] = useState("lab grown diamond");
  // handling tabs
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleChange = (tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const [ resetAllSliders, setResetAllSliders ] = useState(false);
  // Toggle filter drawer for mobile
  const [ toggleFilter, setToggleFilter ] = useState(false);
  //will be true for List view and false for Grid view
  const [isList, setIslist] = useState(false);
  // Triggering loader
  const [ trigger, setTrigger ] = useState(false);
  // total number of diamonds in the backend
  const [ totalDiamonds, setTotalDiamonds ] = useState({allTab: 0, resultTab: 0});
  // flag for setting diamonds number in tabs
  const [flag, setFlag] = useState(true);
  // diamond data
  let [ diamondData ] = useFetch(apiURL, chooseYourDiamondData, setTrigger,flag, setFlag, setTotalDiamonds, totalDiamonds);
  // const [ valuesBeforeTabChange, setValuesBeforeTabChange] = useState({});
  // handle All tab
  const handleAllTab = () =>{
    setResetAllSliders(true);
  }
  
  //DiamondTypes data
  const diamondTypes = [
    {
      name: "ROUND 10 H&A",
      image: roundDiamondImage,
    },
    {
      name: "OVAL 8 H&A",
      image: ovalDiamondImage,
    },
    {
      name: "DECAGON 10 H&A",
      image: decagonDiamondImage,
    },
    {
      name: "CUSHION 8 H&A",
      image: cushionDiamondImage,
    },
    {
      name: "RADIANT X",
      image: radiantDiamondImage,
    },
    {
      name: "SHIELD 2.0",
      image: shieldDiamondImage,
    },
    {
      name: "EMERALD 2.0",
      image: emeraldDiamondImage,
    },
    {
      name: "Cushion X",
      image: cushionXImage,
    },
    {
      name: "Heart 2.0",
      image: heart2Image,
    },
    {
      name: "Marquise X",
      image: marquiseImage,
    },
    {
      name: "OCTAGON NOVA",
      image: octagonNovaImage,
    },
    {
      name: "Oval X",
      image: ovalXImage,
    },
  ];
  return (
    <>
      {/* Header */}
      {isMobile ? <MobileHeader paths={paths} /> : <Header paths={paths} />}
      {/*  Banner */}
      <InnerPageBanner
        bannerContentData={bannerContentData}
        isMobile={isMobile}
      />
      {/* Steps */}
      {isMobile ? (
        <MobileProcessSteps pageName={"choose-your-diamond"} />
      ) : (
        <ProcessSteps pageName={"choose-your-diamond"} />
      )}
      {/* Container1 contains Diamond search title, ctas, slider and filters */}
      <Container className="cydContainer1">
        <Grid container className="cydDiamondSearchTitleAndButtonContainer">
          <Grid item md={12} xs={12} className="cydDiamondSearch">
            <Typography variant="h6" align="center">
              Diamond Search
            </Typography>
          </Grid>
          {isMobile ? (
            ""
          ) : (
            <Grid item md={3} className="cydDesktopTitleEmptyGrid"></Grid>
          )}
          <Grid item md={3} xs={6} className="cydDiamondSearchBtn">
            <Button
              variant="contained"
              className={`${diamondCategory === "natural diamond" ? "brownFilledButton" : "blackOutlinedButton"} cydLabDiamonds`}
              onClick={()=>setDiamondCategory("natural diamond")}
            >
              Natural Diamond
            </Button>
          </Grid>
          <Grid item md={3} xs={6} className="cydDiamondSearchBtn">
            <Button
              variant="outlined"
              onClick={()=>setDiamondCategory("lab grown diamond")}
              className={`${diamondCategory === "lab grown diamond" ? "brownFilledButton" : "blackOutlinedButton"} cydLabDiamonds`}
            >
              Lab Diamond
            </Button>
          </Grid>
          {isMobile ? (
            ""
          ) : (
            <Grid item md={3} className="cydDesktopTitleEmptyGrid"></Grid>
          )}
        </Grid>
        {/* Image Slider */}
        <DiamondSettingsSlider
          diamondTypes={diamondTypes}
          isMobile={isMobile}
          pageName={"Choose Your Diamond"}
        />
        {/* Basic and Advanced filters */}
        {isMobile ? (
          <Button className="cydFilter" onClick={() => setToggleFilter(true)}>
            <img className="cydFilterImage" src={filterIcon} loading="lazy" />
            Filter
          </Button>
        ) : (
          <RangeSliderFilters
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            handleTabChange={handleChange}
            resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
          />
        )}
        {/* Mobile filter drawer */}
        <SwipeableDrawer
          anchor={"bottom"}
          open={toggleFilter}
          onClose={() => setToggleFilter(false)}
          onOpen={() => setToggleFilter(true)}
        >
          <MobileFilterDrawer
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            setToggleFilter={setToggleFilter}
            numberOfDiamondsRendered={diamondData.length}
            handleTabChange={handleChange}
            resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
          />
        </SwipeableDrawer>
      </Container>
      {isMobile ? (
        <>
          <Box className="filterTabContainer">
            {/* Setting the tabs */}
            <Tabs
              value={currentTabIndex}
              onChange={handleChange}
              className="cydFilterTabs"
            >
              <Tab
                label={`All (${totalDiamonds.allTab})`}
                onClick={() => {handleChange(0); handleAllTab()}}
                className="cydFilterTab"
              />
              <Tab
                label={`Result (${totalDiamonds.resultTab})`}
                onClick={() => {handleChange(1)}}
                className="cydFilterTab"
              />
              <Tab
                label="Comparison (2)"
                onClick={() => handleChange(2)}
                className="cydFilterTab"
              />
            </Tabs>
            <Divider className="filterBreakLine" />
          </Box>
          <Container>
            <Grid container className="sortByAndIconsBox">
              {isMobile ? (
                ""
              ) : (
                <Grid item md={1} className="cydSortByTextGrid">
                  <Typography className="cydSortByText">Sort By: </Typography>
                </Grid>
              )}
              <Grid item xs={6} md={5} className="cydDropDownGrid">
                <NativeSelect className="cydNativeSelectdd" IconComponent={()=>( <FontAwesomeIcon icon={faAngleDown} className="selectDropdownArrow" /> )}>
                  <option className="cydDdOption1">Default Sorting</option>
                  <option className="cydDdOption2">Sorting</option>
                </NativeSelect>
              </Grid>
              <Grid item xs={6} md={6} className="cydListAndGridViewIconsGrid">
                <img
                  onClick={() => setIslist(false)}
                  className="cydGridViewIcon"
                  src={isList ? inactiveGridViewIcon : activeGridViewIcon}
                  loading="lazy"
                />
                <img
                  onClick={() => setIslist(true)}
                  className="cydListViewIcon"
                  src={isList ? activeListViewIcon : inactiveListViewIcon}
                  loading="lazy"
                />
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <Container className="filterTabContainerDesktop">
          {/* Setting the tabs */}
          <Grid container>
            <Grid item md={8}>
              <Tabs
                value={currentTabIndex}
                onChange={handleChange}
                className="cydFilterTabsDesktop"
              >
                <Tab
                  label={`All (${totalDiamonds.allTab})`}
                  onClick={() => {handleChange(0); handleAllTab()}}
                  className="cydFilterTabDesktop"
                />
                <Tab
                  label={`Result (${totalDiamonds.resultTab})`}
                  onClick={() => {handleChange(1)}}
                  className="cydFilterTabDesktop"
                />
                <Tab
                  label="Comparison (2)"
                  onClick={() => handleChange(2)}
                  className="cydFilterTabDesktop"
                />
              </Tabs>
            </Grid>
            <Grid className="sortByColumn" item md={3}>
              <Typography>Sort By: </Typography>
              <NativeSelect className="cydNativeSelectdd" IconComponent={()=>( <FontAwesomeIcon icon={faAngleDown} className="selectDropdownArrow" /> )}>
                <option variant="body1" className="cydDdOption1">Default Sorting</option>
                <option variant="body1" className="cydDdOption2">Sorting</option>
              </NativeSelect>
            </Grid>
            <Grid className="sortByColumn" item md={1}>
              <img
                onClick={() => setIslist(false)}
                className="cydGridViewIcon"
                src={isList ? inactiveGridViewIcon : activeGridViewIcon}
                loading="lazy"
              />
              <img
                onClick={() => setIslist(true)}
                className="cydListViewIcon"
                src={isList ? activeListViewIcon : inactiveListViewIcon}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Container>
      )}
      {/* Grid and List Views fetching*/}
      {/* Container2 contains Grid view and list view */}
      {isMobile ? (
        isList ? (
          <ListView diamondData={diamondData} isMobile={isMobile} />
        ) : (
          <Container className="cydMobileGridViewContainer">
            <GridView diamondData={diamondData} isMobile={isMobile} />
          </Container>
        )
      ) : (
        <Container className="cydContainer2">
          {isList ? (
            <ListView diamondData={diamondData} isMobile={isMobile} />
          ) : (
            <GridView diamondData={diamondData} isMobile={isMobile} />
          )}
        </Container>
      )}
      {/* Container3 contains CTA cards */}
      {/* CTA cards */}
      <Container className="cydContainer3">
        <Grid container className="cydCTACardsContainer" spacing={2}>
          {ctaCardsData.map((item, index) => (
            <Grid
              item
              md={6}
              xs={12}
              key={index}
              className="ctaCardBox cydCardBox"
            >
              <CTACard item={item} index={index} isMobile={isMobile} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      {isMobile ? <MobileFooter /> : <Footer />}
      {/* Calling loader */}
      {trigger && <Loader />}
    </>
  );
};
export default ChooseYourDiamondLanding;
