/**
 * This file contains the structure for mobile and desktop pagination
 */
import Pagination from '@mui/material/Pagination';
import "../assets/css/components.css";
const PaginationComponent = ({isMobile = true, totalPages, page, handlePageChange  }) =>{
    if(isMobile){
        // Pagination for mobile
        return(
            <Pagination
            className="mobilePagination"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            siblingCount={0}
            boundaryCount={2}
          />
        );
    }
    return(
        // Pagination for desktop
        <Pagination
        className="desktopPagination"
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        shape="rounded"
        siblingCount={0}
        boundaryCount={3}
      />
    );
}
export default PaginationComponent;