/**
 * This section contains desktop CTA section currently present in Homepage and Diamond details page
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/css/components.css";
const DesktopCTASection = () =>{
    return(
        <Grid
        container
        key={"ctaRow"}
        className="ctaRow paddingTopBottomEightypx"
      >
        <Grid key={0} item md={3} className="ctaColumn">
          <Typography className="ctaHeading" variant="h6">
            LOREM IPSUM
          </Typography>
          <Typography className="ctaText">
            Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing
            id viverra
          </Typography>
          <Link className="ctaLink">
            LEARN MORE <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Grid>
        <Grid key={1} item md={3} className="ctaColumn">
          <Typography className="ctaHeading" variant="h6">
            OUR PROCESS
          </Typography>
          <Typography className="ctaText">
            Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing
            id viverra
          </Typography>
          <Link className="ctaLink">
            LEARN MORE <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Grid>
        <Grid key={2} item md={3} className="ctaColumn">
          <Typography className="ctaHeading" variant="h6">
            AFTER-SALES POLICY
          </Typography>
          <Typography className="ctaText">
            Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing
            id viverra
          </Typography>
          <Link className="ctaLink">
            LEARN MORE <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Grid>
        <Grid key={3} item md={3} className="ctaColumn">
          <Typography className="ctaHeading" variant="h6">
            BOOK AN APPOINTMENT
          </Typography>
          <Typography className="ctaText">
            Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing
            id viverra
          </Typography>
          <Link className="ctaLink">
            BOOK NOW <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Grid>
      </Grid>
    );
}
export default DesktopCTASection;