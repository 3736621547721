/**
 * This file contains code for homepage mobile discover our designs slider
 */
import Slider from 'react-slick';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
const MobileDiscoverOurDesignsSlider = ({ images }) => {
  //props for slider component
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings} className="discoverOurDesignSlider">
      {/* Adding elevation prop for overriding mat ui css in build */}
      <Card className="discoverOurDesignCard" elevation={0} align="left">
        <CardMedia
          component="img"
          height="140"
          image={images[0]}
          alt="Collection 1"
          className="discoverOurDesignMobileImage"
        />
        <CardContent className="discoverOurDesignCTARow">
          <Typography variant="h6">Collection 1</Typography>
          <Typography variant="subtitle">
            Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra.
          </Typography>
          <Typography><Link className="discoverOurDesignCTA">FIND OUT MORE <FontAwesomeIcon icon={faAngleRight} /></Link></Typography>
          </CardContent>
      </Card>
      {/* Adding elevation prop for overriding mat ui css in build */}
      <Card className="discoverOurDesignCard" elevation={0} align="left">
        <CardMedia
          component="img"
          height="140"
          image={images[1]}
          alt="Collection 2"
          className="discoverOurDesignMobileImage"
        />
        {/* Adding temperory sx prop */}
        <CardContent className="discoverOurDesignCTARow">
          <Typography variant="h6">Collection 2</Typography>
          <Typography variant="subtitle">
            Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra.
          </Typography>
          <Typography><Link className="discoverOurDesignCTA">FIND OUT MORE <FontAwesomeIcon icon={faAngleRight} /></Link></Typography>
          </CardContent>
      </Card>
    </Slider>
  );
};
export default MobileDiscoverOurDesignsSlider;
