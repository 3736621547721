/**
 * This file has the structure of Homepage
 */
import { useState, useEffect, useRef } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "./homepage-banner";
import MobileBanner from "./homepage-banner-mobile.js";
import HomepageSlider from "./homepage-sliders.js";
import MobileCTASlider from "../../components/cta-slider-mobile.js";
import DesktopCTASection from "../../components/cta-section-desktop.js";
import MobileFooter from "../../components/footer/mobile-footer";
import Footer from "../../components/footer/footer";
import CTACard from "../../components/cta-card.js";
import MobileDiscoverOurDesignsSlider from "./homepage-discover-our-designs-slider-mobile.js";
import Header from '../../components/header/header';
import MobileHeader from "../../components/header/mobile-header";
import CuratorWidget from "./curator/curator-widgets";
import {
  discoverMoreImg1,
  discoverMoreImg2,
  discoverMoreImg3,
  discoverOurDesignImg1,
  discoverOurDesignImg2,
  pursuitOfPerfection,
  ringCustomizationImg,
  homepageFirstSection1stImage,
  homepageFirstSection2ndImage,
  homepageFirstSection1stImageDesktop,
  homepageFirstSection2ndImageDesktop,
  diamondJourneyMobileImage,
  diamondJourneyDesktopImage,
} from "../../assets/homepage-media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { facebookIcon, instagramIcon, youtubeIcon } from "../../assets/icons";
import {
  Stack,
  Box,
  Grid,
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
  NativeSelect,
  Link,
  Typography,
  Input,
  OutlinedInput,
  sizing,
  Card,
  CardMedia,
  CardContent,
  ListSubheader,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

export default function Homepage({ isMobile, paths }) {
  const [scrollY, setScrollY] = useState(0);
  const contentWrapperRef = useRef(null);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  // handling mobile and desktop css file calls
  isMobile ? import('../../assets/css/homepage/homepage-mobile.css') : import('../../assets/css/homepage/homepage-desktop.css');
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    // console.log(contentWrapper.clientHeight);
    if (contentWrapper) {
      const maxHeight = contentWrapper.clientHeight;
      const diamondRingImage = contentWrapper.querySelector(
        ".firstSection1stImageMobile img"
      );
      const diamondSettingImage = contentWrapper.querySelector(
        ".firstSection2ndImageMobile img"
      );

      const maxScroll = isMobile ? maxHeight / 1.5 : maxHeight / 7.3;

      let diamondRingImageTranslate;
      let diamondSettingImageTranslate;
      if (isMobile) {
        diamondRingImageTranslate = Math.min(scrollY * 0.5, maxScroll);
        diamondSettingImageTranslate = Math.min(scrollY * 0.5, maxScroll);
      } else {
        diamondRingImageTranslate = Math.min(scrollY * 0.09, maxScroll);
        diamondSettingImageTranslate = Math.min(scrollY * 0.09, maxScroll);
      }

      // console.log("maxsscroll: " + maxScroll);
      // console.log("diamond ring image translate: " + diamondRingImageTranslate);
      // console.log(
      //   "diamond setting image translate: " + diamondSettingImageTranslate
      // );

      diamondRingImage.style.transform = `translateY(${diamondRingImageTranslate}px)`;
      diamondSettingImage.style.transform = `translateY(-${diamondSettingImageTranslate}px)`;
    }
  }, [scrollY]);
  //hover flag to show and hide buttons
  // const [hoverFlag, setHoverFlag] = useState([false, null]);
  // media for banner
  const bannerMedia = [
    {
      source:
        "https://staging.jannpaul.com/wp-content/uploads/2022/08/Sequence-01.mp4",
      name: "White Series",
      bannerText: "Designed And Patented...",
    },
    {
      source:
        "https://staging.jannpaul.com/wp-content/uploads/2022/09/classic-cut-diamonds-black-background-3d-render-scaled.jpg",
      // source:
      //   "https://staging.jannpaul.com/wp-content/uploads/2022/08/ab-world.jpg",
      name: "Labgrown",
      bannerText: "The chemical purest diamond",
    },
    {
      source:
        "https://staging.jannpaul.com/wp-content/uploads/2022/08/Sequence-01.mp4",
      // source:
      //   "https://staging.jannpaul.com/wp-content/uploads/2022/09/classic-cut-diamonds-black-background-3d-render-scaled.jpg",
      name: "Crafting",
      bannerText: "Diamond Master Setter,\nMiyabi X JannPaul",
    },
    {
      source:
        "https://staging.jannpaul.com/wp-content/uploads/2022/09/classic-cut-diamonds-black-background-3d-render-scaled.jpg",
      // source:
      //   "https://staging.jannpaul.com/wp-content/uploads/2022/03/Jannn-Paul-white-series.png",
      name: "Diamond Cut",
      bannerText: "JANNPUAL utilizes the latest\ntechnology to analyze each...",
    },
  ];
  const discoverMoreData = [
    {
      image: discoverMoreImg1,
      imageTitle: "EDUCATION",
      imageSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      linkToNewPage: "",
      ctaName: "LEARN MORE"
    },
    {
      image: discoverMoreImg2,
      imageTitle: "YOUTUBE",
      imageSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      linkToNewPage: "",
      ctaName: "LEARN MORE"
    },
    {
      image: discoverMoreImg3,
      imageTitle: "PRESS",
      imageSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      linkToNewPage: "",
      ctaName: "LEARN MORE"
    },
  ];
  const whiteSeriesCollectionData = [
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/ROUND-10-HA.png",
      name: "ROUND 10 H&A",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/SHIELD.png",
      name: "SHIELD",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/DECAGON-10-HA.png",
      name: "DECAGON 10 H&A",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/Cushion-1.png",
      name: "CUSHION 8 H&A",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/OVAL-8-HA.png",
      name: "OVAL 8 H&A",
    },
  ];
  const handcraftedCollectionData = [
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/solitare.png",
      name: "HALO",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/solitare.png",
      name: "PETITE",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/solitare.png",
      name: "SOLITARE",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/solitare.png",
      name: "SIDE STONE",
    },
    {
      image:
        "https://staging.jannpaul.com/wp-content/uploads/2023/11/solitare.png",
      name: "PAVE",
    },
  ];
  return (
    <>
    {/* Header */}
    {isMobile ? (<MobileHeader paths={paths} />) : (<Header paths={paths} />)}
      {/* Banner */}
      {isMobile ? (
        <MobileBanner bannerMedia={bannerMedia} />
      ) : (
        <Banner bannerMedia={bannerMedia} />
      )}
      <Grid
        container
        className="jpHomepageContent content-wrapper"
        id={"home-create-engagement-ring"}
        ref={contentWrapperRef}
      >
        {/*First section*/}
        {/* <Box className="content-wrapper" ref={contentWrapperRef}> */}
        <Grid
          item
          xs={12}
          md={4}
          // sx={{ display: { xs: 'block', md: 'none' } }}
          sx={{ display: "block" }}
          className="firstSection1stImageMobile image-container"
        >
          <img
            className={`${isMobile ? "" : "firstSectionImagesDesktop"}`}
            src={
              isMobile
                ? homepageFirstSection1stImage
                : homepageFirstSection1stImageDesktop
            }
            alt="Engagement Ring"
          />
        </Grid>
        <Grid item xs={12} md={4} className="textAlignCenter firstSection">
          <Typography variant="h3" align="center">
            CREATE YOUR OWN ENGAGEMENT RING
          </Typography>
          <Typography variant="subtitle" align="center">
            Design your own engagement ring in your own way
          </Typography>
          <Grid item xs={12} className="textAlignCenter marginTopThirtyFivepx">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              className="firstSectioncta"
            >
              <Button className="black-filled startWithDiamond">
                {" "}
                START WITH A DIAMOND
              </Button>
              <Button className="black-filled startWithASetting">
                START WITH A SETTING
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          // sx={{ display: { xs: 'block', md: 'none' } }}
          sx={{ display: "block" }}
          className="firstSection2ndImageMobile image-container"
        >
          <img
            className={`${isMobile ? "" : "firstSectionImagesDesktop"}`}
            src={
              isMobile
                ? homepageFirstSection2ndImage
                : homepageFirstSection2ndImageDesktop
            }
            alt="Engagement Setting"
          />
        </Grid>
        {/* </Box> */}
      </Grid>

      {/*Second section - White Series Collection*/}
      <Grid container className="secondSectionHomepage">
        <Grid item md={3}></Grid>
        <Grid
          item
          md={6}
          xs={12}
          className="textAlignCenter paddingTopBottomSliderSpacing secondSectionHeadings"
        >
          <Typography variant="h4">
            WORLD'S HIGHEST STANDARD OF SUPER IDEAL CUTS
          </Typography>
          <Typography variant="h3">
            JANNPAUL WHITE SERIES<sup>TM</sup> DIAMONDS
          </Typography>
          <Typography className="textBody" variant="subtitle">
            World's largest physical inventory of Super Ideal Cut diamonds.
          </Typography>
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={12} xs={12} className="whiteSeries">
          <HomepageSlider
            sliderData={whiteSeriesCollectionData}
            isWhiteSeries={true}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Button className="black-filled shopThisCollection ">
              SHOP THIS COLLECTION
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/*third section - Handcrafted Collection */}
      <Grid container className="thirdSectionHomepage">
        <Grid item md={3}></Grid>
        <Grid
          item
          md={6}
          xs={12}
          className="textAlignCenter  paddingTopBottomSliderSpacing"
        >
          <Typography variant="h4">
            INDIVIDUALLY CRAFTED, UNIQUELY YOURS
          </Typography>
          <Typography variant="h3">HANDCRAFTED COLLECTION</Typography>
          <Typography variant="subtitle">
            World's largest physical inventory of Super Ideal Cut diamonds.
          </Typography>
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={12} xs={12}>
          <HomepageSlider
            sliderData={handcraftedCollectionData}
            isWhiteSeries={false}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            className="handCraftedBottomSpacing"
          >
            <Button className="black-filled shopThisCollection ">
              SHOP THIS COLLECTION
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/*fourth section - Discover our designs*/}
      <Grid
        container
        className="fourthSectionHomepage paddingTopBottomEightypx"
        spacing={2}
      >
        <Grid className="discoverOurDesignPadding" item md={12} xs={12}>
          <Typography variant="h3" className="textAlignCenter">
            DISCOVER OUR DESIGNS
          </Typography>
        </Grid>
        {isMobile ? (
          // Slider for Mobile
          <Grid item xs={12}>
            <MobileDiscoverOurDesignsSlider
              images={[discoverOurDesignImg1, discoverOurDesignImg2]}
            />
          </Grid>
        ) : (
          // Grid for Desktop
          <Stack
            direction="row"
            md={12}
            spacing={2}
            className="discoverOurDesignImageRow"
          >
            <ImageListItem className="collectionImage">
              <img
                srcSet={discoverOurDesignImg1}
                src={`Collection1?w=248&fit=crop&auto=format`}
                loading="lazy"
              />
              <ImageListItemBar
                className="collectionHead"
                title={"Collection 1"}
              />
            </ImageListItem>
            <ImageListItem className="collectionImage">
              <img
                srcSet={discoverOurDesignImg2}
                src={`Collection2?w=248&fit=crop&auto=format`}
                loading="lazy"
              />
              <ImageListItemBar
                className="collectionHead"
                title={"Collection 2"}
              />
            </ImageListItem>
          </Stack>
        )}
      </Grid>

      {/*fifth section - Ring Customization*/}
      <Grid container spacing={10} className="ringCustomizationContainer">
        <Grid item md={8} xs={12} className="ringCustomizationImgColumn">
          <img src={ringCustomizationImg} className="ringCustomizationImg" />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          className="ringCustomizationContentColumn ringCustomizationDesktopContentColumn"
        >
          <Stack>
            <Typography variant="h4">WORLD'S HIGHEST STANDARD</Typography>
            <Typography variant="h3">RING CUSTOMIZATION</Typography>
            <Typography variant="subtitle" className="">
              Meticulos craftsmanship. Time-tested traditions in fine jewelry,
              advanced by technology.
            </Typography>
            <Button className="black-filled customizeYourOwnRing marginTopThirtyFivepx">
              CUSTOMIZE YOUR OWN
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* Art of Crafting */}
      <Grid
        container
        spacing={10}
        className="paddingTopBottomEightypx"
        direction={isMobile ? "column-reverse" : "row"}
      >
        <Grid
          item
          md={4}
          xs={12}
          className="ArtOfCraftingContentColumn ArtOfCraftingDesktopContentColumn"
        >
          <Stack>
            <Typography variant="h4">
              MIYABI, JANNPAUL MASTER CRAFTER
            </Typography>
            <Typography variant="h3">ART OF CRAFTING</Typography>
            <Typography variant="subtitle">
              Meticulos craftsmanship. Time-tested traditions in fine jewelry,
              advanced by technology.
            </Typography>
            <Button
              className="black-filled ArtofCrafting marginTopThirtyFivepx"
              variant="contained"
            >
              READ THE STORY
            </Button>
          </Stack>
        </Grid>
        <Grid item md={8} xs={12} className="artOfCraftingImgColumn">
          <img src={pursuitOfPerfection} className="ArtOfCraftingImg" />
        </Grid>
      </Grid>

      {/* CTAs container */}
      {isMobile ? (
        <MobileCTASlider />
      ) : (
       <DesktopCTASection />
      )}

      {/* Discover More section */}
      <Grid
        container
        className="discoverRow paddingTopBottomEightypx"
        spacing={2}
      >
        <Grid item md={12} xs={12}>
          <Typography variant="h3" className="textAlignCenter">
            DISCOVER MORE
          </Typography>
        </Grid>
        {isMobile ? (
          ""
        ) : (
          <Grid item md={3} className="discoverMoreColumnOne"></Grid>
        )}
        {discoverMoreData.map((item, index) => (
          // CTA cards
          <Grid item md={2} xs={12} key={index} className="discoverMoreCtaBox">
            <CTACard item={item} isMobile={isMobile}/>
          </Grid>
        ))}
        {isMobile ? (
          ""
        ) : (
          <Grid item md={3} className="discoverMoreColumnfive"></Grid>
        )}
      </Grid>

      {/* Diamond Journey section */}
      <Grid container className="tenthSectionHomepage">
        <Grid item md={3}></Grid>
        <Grid item md={6} className="tenthSectionSecondColumn">
          <Typography className="journeyHead" variant="h3">
            DIAMOND JOURNEY
          </Typography>
          <Typography className="journeyText" variant="subtitle">
            JANNPAUL abides by the highest professional, ethical, social,
            environmental and legal standards. Every diamond JANNPAUL sell is
            conflict free. We have a zero tolerance policy towards conflict
            diamonds. We only offer diamonds that are obtained using legitimate
            means.
          </Typography>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <Grid item md={12}>
        {isMobile ? (
          <img
            className="diamondJourneyImageMobile"
            src={diamondJourneyMobileImage}
          />
        ) : (
          <img
            className="diamondJourneyImage"
            src={diamondJourneyDesktopImage}
          />
        )}
      </Grid>

      {/* Instagram feed */}
      <Grid
        container
        className="thirteenthSectionHomepage instaRow"
      >
        <Grid className="instaRowPadding" item xs={12}>
          <Typography className="journeyText" align="center" variant="h4">
            FOLLOW OUR INSTAGRAM
          </Typography>
          <Typography className="instaName" align="center" variant="h3">
            <span className="atTheRate">@</span>JANNPAUL
          </Typography>
        </Grid>
        <Grid item md={12} className="instaColumn">
          <CuratorWidget feedId="c6b24491-363b-4798-8447-c4dbb2e374a5" />
        </Grid>
      </Grid>
      
      {/* Footer */}
      {isMobile ? (
        <MobileFooter />
      ) : (
        <Footer />
      )}
    </>
  );
}
