/**
 * This component is used for Carat, Price, Depth% and Table% range sliders
 */
import { useState, useEffect,  } from 'react';
import PopoverComponent from "../../components/popover.js";
import Grid from "@mui/material/Grid";
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField  from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { circleQuestionIcon } from "../../assets/icons";

const DualSlider = ({min, max, steps, filterName, prefix, postfix, filterKey, setChooseYourDiamondData = null, chooseYourDiamondData = null, resetAllSliders, setResetAllSliders , handleTabChange }) => {
    const [value, setValue] = useState([min, max]);
    // start value
    const [startValue, setStartValue] = useState(min);
    // end value
    const [endValue, setEndValue] = useState(max);
    // on click popover
    const [ showPopover, setShowPopover ] = useState([false, null]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
      console.log(e.currentTarget);
    };
    const updatedObj = chooseYourDiamondData;
    // Added for mobile drawer reset all button
    useEffect(()=>{
      if(resetAllSliders){
        handleReset();
        setResetAllSliders(false);
      }
    },[resetAllSliders]);
    // handling value change
    const handleChange = (event, newValue, activeThumb) => {
      // console.log(newValue);
      if (activeThumb === 0) {
        setValue([Math.min(newValue[0], value[1] - steps), value[1]]);
        setStartValue(Math.min(newValue[0], value[1] - steps));
      } else {
        setValue([value[0], Math.max(newValue[1], value[0] + steps)]);
        setEndValue(Math.max(newValue[1], value[0] + steps));
      }
      if(updatedObj) updatedObj[filterKey] = newValue;
      setChooseYourDiamondData && setChooseYourDiamondData({...updatedObj});
      handleTabChange && handleTabChange(1);
      // console.log(value);
    };
    const handleReset = () =>{
      //resetting the value array, start value and end value
        setValue([min, max]);
        if(updatedObj) updatedObj[filterKey] = [min, max];
        setStartValue(min);
        setEndValue(max);
        // resetting the data
        setChooseYourDiamondData && setChooseYourDiamondData({...updatedObj});
    }
    const handleInput1Change = (e) =>{
      setStartValue(e.target.value); 
      setValue([parseFloat(e.target.value) < min ? min : Math.min(parseFloat(e.target.value), value[1] - steps), value[1]]);
      // console.log(value);
    }
    const handleInput2Change = (e) =>{
        setEndValue(e.target.value); 
        setValue([value[0], parseFloat(e.target.value) > max ? max : Math.max(parseFloat(e.target.value), value[0] + steps)]);
      // console.log(value);
    }
    return (
      <Grid container className="dualSliderContainer">
        <Grid item md={10} xs={10} className="dualSliderFilterNameGrid">
          <Typography className="dualSliderFilterName">{filterName} <img src={circleQuestionIcon} className="dualSliderCircleIcon"  onClick={(e)=>{setShowPopover([true, filterKey]); handleClick(e)}} /></Typography>
          {showPopover[0] && showPopover[1] === filterKey && <PopoverComponent filterKey={filterKey} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setShowPopover={setShowPopover} />}
        </Grid>
        <Grid item md={2} xs={2} className="dualSliderResetGrid">
          <Link onClick={handleReset} className="dualSliderResetLink">Reset</Link>
        </Grid>
        <Grid item md={12} xs={12} className="dualSliderGrid">
          <Slider
            size="small"
            value={value}
            min={min}
            max={max}
            step={steps}
            onChange={handleChange}
            disableSwap
            className="dualSlider"
          />
        </Grid>
        <Grid item md={7} xs={6} className="dualSliderValueLeft">
          <Typography className="dualSliderValue1">{prefix}{value[0].toLocaleString('en-US')}{postfix}</Typography>
          {/* <Typography className="cydDualSliderValue1"> */}
          {/* <TextField 
          className="cydDualSliderValue1"
            // startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
            // endAdornment={<InputAdornment position="end">{postfix}</InputAdornment>}
            value={startValue}
            onChange={handleInput1Change}
            inputProps={{
              min: min,
              max: max,
              type: 'number',
            }}
            // type="number"
          /> */}
          {/* </Typography> */}
        </Grid>
        <Grid item md={5} xs={6} className="dualSliderValueRight">
          <Typography className="dualSliderValue2">{prefix}{value[1].toLocaleString('en-US')}{postfix}</Typography>
          {/* <Typography className="cydDualSliderValue2"> */}
          {/* <TextField 
          className="cydDualSliderValue2"
            // startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
            // endAdornment={<InputAdornment position="end">{postfix}</InputAdornment>}
            value={endValue}
            onChange={handleInput2Change}
            inputProps={{
              min: min,
              max: max,
              type: 'number',
            }} */}
            {/* // type="number"
          /> */}
          {/* </Typography> */}
        </Grid>
      </Grid>
    );
}
export default DualSlider;