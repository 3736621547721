/**
 * This file has strcture for white series section in diamond details page
 */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { decagonWhiteSeriesImage } from "../../assets/diamond-details-media";
const WhiteSeries = () =>{
    return (
        <Box>
            <Box>
            <Typography variant="h3" className="whiteSeriesTitle">Decagon</Typography>
            <Typography className="whiteSeriesSubTitle">WHITE SERIES<sup>TM</sup></Typography>
            </Box>
            <img src={decagonWhiteSeriesImage} className="decagonWhiteSeriesImage"/>
            <Typography className="whiteSeriesParagraphs">(SAMPLE PARA) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.</Typography>
        </Box>
    );
}
export default WhiteSeries;