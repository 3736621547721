/**
 * This file has the structure for range slider present in Carat Size popup for both mobile and desktop
 */
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
const marks = [
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 1.5,
    label: "1.5",
  },
  {
    value: 2,
    label: "2.5",
  },
  {
    value: 2.5,
    label: "2.5",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 3.5,
    label: "3.5",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 4.5,
    label: "4.5",
  },
];

const CaratSizeRangeSlider = ({ caratSize }) => {
  const [value, setValue] = useState(caratSize);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container className="caratSizeRangeSliderContainer">
      <Grid item md={12} className="caratSizeRangeSliderGrid">
        <Stack
          direction="row"
          spacing={2}
          className="caratSizeRangeSliderStack"
        >
          <Typography className="caratSizeRangeSliderMinValue">0 ct</Typography>
          <Box className="viewCaratSizeBox">
            <Slider
              size="small"
              min={0}
              max={5}
              step={0.5}
              marks={marks}
              value={parseInt(value)}
              onChange={handleChange}
              className="viewCaratSizeSlider"
            />
          </Box>
          <Typography className="caratSizeRangeSliderMaxValue">5 ct</Typography>
        </Stack>
      </Grid>
      <Grid item md={12} className="caratSizeRangeSliderValueGrid">
        <Typography className="caratSizeRangeSliderValueText">
          Current Carat Size
        </Typography>
        <Typography className="caratSizeRangeSliderValueTextField">{value}</Typography>
      </Grid>
    </Grid>
  );
};
export default CaratSizeRangeSlider;