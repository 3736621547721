/**
 * This file has the structure of product showcase for mobile
 */
import { useState } from "react";
// for routing
import { Link as RoutingLink } from "react-router-dom"; 
import ConsultDiamondExpertPopUp from "./ddp-consult-diamond-expert-popup";
import ThumbnailSlider from "./ddp-thumbnail-slider.js";
import ViewReportPopUp from "./ddp-view-report";
import ViewCaratSizePopup from "./ddp-view-carat-size.js";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  caratSizeImage,
  giaReportImage,
  ebookImage,
  gcalReportImage,
  gcalLogo
} from "../../assets/diamond-details-media";
import {
  wishlistIcon,
  compareIcon,
  shareIcon
} from "../../assets/icons.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const ProductShowcaseMobile = ({ thumbnailArrayData, diamondData }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  // opening consult an diamond expert pop up
  const [ openConsultPopup, setOpenConsultPopup ] = useState(false);
  // opening view lab report pop up
  const [ openReportPopup, setOpenReportPopup ] = useState(false);
  // opening view carat size pop up
  const [ openViewCaratSizePopup, setOpenViewCaratSizePopup] = useState(false);
  return (
    <Container className="productShowcaseContainer">
      <Grid container className="productShowcaseGridContainer">
        <Grid item xs={12} className="productShowcaseGrid1">
          <Breadcrumbs
          className="productShowcaseBreadcrumb"
            separator={
              <FontAwesomeIcon
                icon={faAngleRight}
                className="breadcrumbSeperatorIcon"
              />
            }
          >
            <Typography className="productShowcaseBreadcrumbLink breadcrumbTypography">Diamond Collections Name</Typography>
            <Typography  className="breadcrumbTypography">Diamond Product Name</Typography>
          </Breadcrumbs>
          <img src={thumbnailArrayData[currentTabIndex]} className="productShowcaseProductImage" />
          <ThumbnailSlider thumbnailImages={thumbnailArrayData} setCurrentTabIndex={setCurrentTabIndex} />
        </Grid>
        <Grid item xs={12} className="productShowcaseProductDetails">
          <Stack className="productShowcaseStack1">
            <Typography className="productShowcaseText1 productTitleBlack">
            {diamondData.carat} {diamondData.color} {diamondData.clarity} {diamondData.cut} {diamondData.shape}
            </Typography>

            <Typography className="productShowcaseText2">
            {diamondData.shape} · {diamondData.carat} · {diamondData.color} · {diamondData.clarity} ·{" "}
            {diamondData.cut}
            </Typography>

            <Typography className="productShowcaseText3">
            Short description about the diamond lorem ipsum dolor sit amet consectetur gravida accumsan turpis viverra. Curabitur aliquet ligula sit in pharetra ultricies.
            </Typography>

            {/* <Typography className="productShowcaseText4">{diamondData.price}</Typography> */}
            <Typography className="productShowcaseText4">SGD5,200</Typography>
            <Stack spacing={2} direction="row" className="productShowcaseIconStack">
              <img src={wishlistIcon} className="whishlistIcon" />
              <img src={compareIcon} className="compareIcon" />
              <img src={shareIcon} className="shareIcon" />
            </Stack>
            <Stack spacing={2} className="productShowcaseButtonStack">
            <RoutingLink key={"startWithSettingLink"} to="/start-with-setting" state={diamondData} className="linkComponent fullWidthButton" >
              <Button variant="contained" className="black-filled fullWidthButton">SELECT THIS DIAMOND</Button>
              </RoutingLink>
              <Button variant="outlined" onClick={()=>setOpenConsultPopup(true)} className="blackOutlinedButton">CONSULT A DIAMOND EXPERT</Button>
              {/* Consult a diamond expert popup */}
              { openConsultPopup && <ConsultDiamondExpertPopUp setOpenConsultPopup={setOpenConsultPopup} diamondData={diamondData} image={thumbnailArrayData[currentTabIndex]} />}
            </Stack>
            <Divider className="productShowcaseDivider1" />
            <Stack direction="row" spacing={2} className="productShowcaseStack2">
              <Stack className="productShowcaseGIAReportStack" >
                <img src={gcalLogo} className="giaReportImage" onClick={()=>setOpenReportPopup(true)}/>
                <Typography className="giaReportLink ddpLink" onClick={()=>setOpenReportPopup(true)}>GCAL Report</Typography>
                {/* View report popup */}
                { openReportPopup && <ViewReportPopUp setOpenReportPopup={setOpenReportPopup} report={gcalReportImage} />}
              </Stack>
              <Stack className="productShowcaseCaratSizeStack" >
                <img src={caratSizeImage}  className="caratSizeImage" onClick={()=>setOpenViewCaratSizePopup(true)} />
                <Typography className="caratSizeLink ddpLink" onClick={()=>setOpenViewCaratSizePopup(true)}>View Carat Size</Typography>
                {openViewCaratSizePopup && <ViewCaratSizePopup setOpenViewCaratSizePopup={setOpenViewCaratSizePopup} caratSize={diamondData.carat} />}
              </Stack>
              <Stack className="productShowcaseEBookStack">
                <img src={ebookImage} className="eBookImage"/>
                <Link href="https://ebook.jannpaul.com/" className="eBookLink ddpLink">View eBook</Link>
              </Stack>
            </Stack>
            <Divider className="productShowcaseDivider2" />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
export default ProductShowcaseMobile;
