/**
 * This file has the code for Homepage banner
 */
import { React, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
const Banner = ({ bannerMedia }) => {
  const [activeMedia, setActiveMedia] = useState(0);
  return (
        <Box className="jpHomepageBanner" id="homepage-banner">
          {/* Banner Media */}
          {activeMedia === 0 || activeMedia === 2  ? (
            <CardMedia
              className="jpBannerMedia bannerMediaVideo"
              component="video"
              src={bannerMedia[activeMedia].source}
              autoPlay
              muted
            />
          ) : (
            <CardMedia
              className="jpBannerMedia bannerMediaImgs"
              component="img"
              image={bannerMedia[activeMedia].source}
            />
          )}

          {/* Banner Text */}
          <Grid container className="bannerMediaContent">
            {bannerMedia.map((data, index) => (
              <Grid
                item
                xs={3}
                key={index}
                onMouseEnter={() => setActiveMedia(index)}
                className={`${index === bannerMedia.length-1 ? "bannerCol4":""} bannerBorder bannerPadding`}
              >
                <h5 className="white-border">{data.name.toUpperCase()}</h5>
                <p className="jp-banner-text">{data.bannerText}</p>
                {activeMedia === index && (
                  <Button variant="outlined" className="jp-banner-cta">Learn More</Button>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
  );
};

export default Banner;
