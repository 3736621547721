/**
 * This file has the structure for view carat size modal in desktop
 */
import {useState } from "react";
import CaratSizeRangeSlider from "./ddp-carat-size-range-slider.js";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
    closeIcon
  } from "../../assets/icons.js";
  import { caratSizePreviewImage } from "../../assets/diamond-details-media";
const ViewCaratSizeModal = ({caratSize, setViewCaratSize}) =>{
    const [open, setOpen] = useState(true);
    const handleClose = () =>{
        setOpen(false);
        setViewCaratSize(false);
    }
    return(
        <Modal
        open={open}
        onClose={handleClose}
        className="viewCaratSizeModal"
        >
           <Box className="viewCaratSizeGridBox">
           <Grid container className="viewCaratSizeGridContainer" >
            <Grid item md={12} className="viewCaratSizeImageGrid">
                <Typography className="viewCaratSizePreviewTitle">Carat Size Preview</Typography>
                <img src={caratSizePreviewImage} className="viewCaratSizePreviewImage" />
                <CaratSizeRangeSlider caratSize={caratSize} />
            </Grid>
            <Grid item md={1} className="viewReportCloseIconGrid">
                <img src={closeIcon} onClick={handleClose} className="viewCaratSizeCloseIcon" />
            </Grid>         
           </Grid>
           </Box>
        </Modal>
    );
}
export default ViewCaratSizeModal;