/**
 * This file has the code for mobile footer
 */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { facebookIcon, instagramIcon, youtubeIcon } from "../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/jp-footer-mobile.css";
const MobileFooter = () => {
  return (
    <>
      <Grid container className="footerRow">
        <Grid item xs={12}>
          <Divider className="divider">
            <img src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png" />
          </Divider>
        </Grid>
        <Grid item xs={12} className="footerLogo">
          <img src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png" />
        </Grid>
      </Grid>
      <Box>
        <Accordion className="jpFooterAccordion">
          <AccordionSummary
            className="jpFooterAccordionMobile"
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            QUICK LINKS
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography className="shopByItems" variant="subtitle">
                Contact Us
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Book an Appointment
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Store Locator
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Diamond Collection
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Customize Your Ring
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion className="jpFooterAccordion">
          <AccordionSummary
            className="jpFooterAccordionMobile"
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            CONSUMER EXPERIENCE
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography className="shopByItems" variant="subtitle">
                Diamond Cuts
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                White Series
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Ring Designs
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Ring Sizing Guide
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                After-Sales Policy
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Our Process
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Customization & Bespoke Process
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Timeline
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Packaging
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion className="jpFooterAccordion">
          <AccordionSummary
            className="jpFooterAccordionMobile"
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            ABOUT JANNPAUL
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography className="shopByItems" variant="subtitle">
                Miyabi Master Crafter
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Education
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Blog
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Press
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion className="jpFooterAccordion">
          <AccordionSummary
            className="jpFooterAccordionMobile"
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            OUR POLICIES
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography className="shopByItems" variant="subtitle">
                Return Policy
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Lead Time
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Shipping Time
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Lifetime Warranty
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Terms & Conditions
              </Typography>
              <Typography className="shopByItems" variant="subtitle">
                Privacy Policy
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Grid container className="footerIconRow">
        <Grid item xs={12} md={9} className="">
          <Box className="footerIcon" direction={"row"}>
            <img className="headerIconList" src={facebookIcon} />
            <img className="headerIconList" src={instagramIcon} />
            <img className="headerIconList" src={youtubeIcon} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} className="copyrightRow">
          <Typography className="copyRightText" variant="body2">
            &copy; JANNPAUL 2024 (VAT Number: 00739600153)
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default MobileFooter;
