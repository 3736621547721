/**
 * This component is used for Color, Clarity, Polish and Symmetry range sliders
 */
import { useState, useEffect } from 'react';
import PopoverComponent from "../../components/popover.js";
import Grid from "@mui/material/Grid";
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { circleQuestionIcon } from "../../assets/icons";
const ComplexDualSlider = ({min, max, filterName, marksLabelArray, filterKey, setChooseYourDiamondData, chooseYourDiamondData, resetAllSliders, setResetAllSliders, handleTabChange}) => {
    const [value, setValue] = useState([min, max]);
    const updatedObj = chooseYourDiamondData;
     // on click popover
     const [ showPopover, setShowPopover ] = useState([false, null]);
     const [anchorEl, setAnchorEl] = useState(null);
 
   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };
    // Added for mobile drawer reset all button
    useEffect(()=>{
      if(resetAllSliders){
        handleReset();
        setResetAllSliders(false);
      }
    },[resetAllSliders]);
    // creating array for labels
    let labelArray = [];
    for (let i = 0; i < marksLabelArray.length - 1; i++) {
      labelArray[i] = marksLabelArray[i].label;
    }
    // handling value change
    const handleChange = (event, newValue, activeThumb) => {
        setValue(newValue);
        // console.log(activeThumb);
        if(activeThumb === 0){
            setValue([Math.min(newValue[0], value[1] - 1), value[1]]);
        }else{
            setValue([value[0], Math.max(newValue[1], value[0] + 1)]);
        }
       updatedObj[filterKey] = labelArray.slice(newValue[0], newValue[1]);
       setChooseYourDiamondData({...updatedObj});
       handleTabChange(1);
    };
    const handleReset = () =>{
        setValue([min, max]);
        updatedObj[filterKey] = labelArray;
        setChooseYourDiamondData({...updatedObj});
    }
    return (
      <Grid container className="cydComplexDualSliderContainer">
        <Grid item md={10} xs={10} className="cydComplexDualSliderFilterNameGrid">
          <Typography className="cydComplexDualSliderFilterName">{filterName} <img src={circleQuestionIcon} className="cydSliderCircleIcon" onClick={(e)=>{setShowPopover([true, filterKey]); handleClick(e)}} /></Typography>
          {showPopover[0] && showPopover[1] === filterKey && <PopoverComponent filterKey={filterKey} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setShowPopover={setShowPopover} />}
        </Grid>
        <Grid item md={2} xs={2} className="cydComplexDualSliderResetGrid">
          <Link onClick={handleReset} className="cydComplexDualSliderResetLink">Reset</Link>
        </Grid>
        <Grid item md={12} xs={12} className="cydComplexDualSliderGrid">
          <Slider
            size="small"
            value={value}
            min={min}
            max={max}
            marks={marksLabelArray}
            onChange={handleChange}
            disableSwap
            className="cydComplexDualSlider"
          />
        </Grid>
      </Grid>
    );
}
export default ComplexDualSlider;