/**
 * This file contains dropdown component
 */
import { useState  } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/css/components.css";
const DropDownComponent = ({ data, setDataIndex }) =>{
    const [ selectedValue, setSelectedValue ] = useState(data[0]);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    return (
        <FormControl className="dropdownFormControl" fullWidth>
        <Select
          value={selectedValue}
          onChange={handleChange}
          className="dropdownSelect"
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={()=>( <FontAwesomeIcon icon={faAngleDown} className="dropdownArrow" /> )}
        >
            {data.map((name, index)=>(
                <MenuItem value={name} key={index} onClick={()=>setDataIndex(index)} className="dropdownMenuItem">{name}</MenuItem>
            ))}
        </Select>
      </FormControl>
    );
}
export default DropDownComponent;