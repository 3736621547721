/**
 * This file contains cta slider for homepage mobile
 */
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/components.css";
const MobileCTASlider = () => {
    //props for slider component
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    }
    return(
            <Slider className="ctaSection" {...settings}>
                <Box>
                    <Typography className="ctaHeading" variant="h6">LOREM IPSUM</Typography>
                    <Typography className="ctaText">Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra</Typography>
                    <Link className="ctaLink">LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                </Box>
                <Box>
                    <Typography className="ctaHeading" variant="h6">OUR PROCESS</Typography>
                    <Typography className="ctaText">Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra</Typography>
                    <Link className="ctaLink">LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                </Box>
                <Box>
                    <Typography className="ctaHeading" variant="h6">AFTER-SALES POLICY</Typography>
                    <Typography className="ctaText">Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra</Typography>
                    <Link className="ctaLink">LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                </Box>
                <Box>
                    <Typography className="ctaHeading" variant="h6">BOOK AN APPOINTMENT</Typography>
                    <Typography className="ctaText">Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra</Typography>
                    <Link className="ctaLink">LEARN MORE <FontAwesomeIcon icon={faAngleRight} /></Link>
                </Box>
            </Slider>
    )
}
export default MobileCTASlider;