/**
 * This component has the structure of filter drawer
 */
import { useState } from 'react';
import { closeIcon } from '../../assets/icons';
import DualSlider from './cyd-dual-slider';
import ComplexDualSlider from './cyd-complex-dual-slider';
import PopoverComponent from "../../components/popover.js";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { circleQuestionIcon } from '../../assets/icons';
const MobileFilterDrawer = ({
  setChooseYourDiamondData,
  chooseYourDiamondData,
  fecthData,
  setToggleFilter,
  numberOfDiamondsRendered,
  handleTabChange,
  resetAllSliders,
  setResetAllSliders,
}) => {
   // on click popover
   const [ showPopover, setShowPopover ] = useState([false, null]);
   const [anchorEl, setAnchorEl] = useState(null);

 const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
 };
  const color = [
    {
      value: 0,
      label: 'G',
    },
    {
      value: 1,
      label: 'F',
    },
    {
      value: 2,
      label: 'E',
    },
    {
      value: 3,
      label: 'D',
    },
    {
      value: 4,
    },
  ];
  const clarity = [
    {
      value: 0,
      label: 'FL',
    },
    {
      value: 1,
      label: 'IF',
    },
    {
      value: 2,
      label: 'VVS1',
    },
    {
      value: 3,
      label: 'VVS2',
    },
    {
      value: 4,
      label: 'VS1',
    },
    {
      value: 5,
      label: 'VS2',
    },
    {
      value: 6,
    },
  ];
  const polishAndSymmetry = [
    {
      value: 0,
      label: 'Good',
    },
    {
      value: 1,
      label: 'Very Good',
    },
    {
      value: 2,
      label: 'Excellent',
    },
    {
      value: 3,
    },
  ];
  return (
    <List className="mobileFilterDrawer">
      <Box className="cydFilterTitleSection">
        <ListItem className="cydFilterTitle cydFilters">
          <Typography className="cydFilterTitle">Filter</Typography>
          {/* Closing the drawer when clicked on closeIcon */}
          <img src={closeIcon} className="filterCloseButton" onClick={() => setToggleFilter(false)} />
        </ListItem>
      </Box>
      <Box className="cydfilterDetailsSection">
        <ListItem className="cydFilters paddingTopThirty">
          {/* Carat range slider */}
          <DualSlider
            min={0.5}
            max={7}
            steps={0.5}
            filterName={'Carat'}
            filterKey="carat"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            fecthData={fecthData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Color range slider */}
          <ComplexDualSlider
            min={0}
            max={4}
            filterName={'Color'}
            className={'colorRangeSlider'}
            marksLabelArray={color}
            filterKey="color"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Price range slider */}
          <DualSlider
            min={1000}
            max={1000000}
            steps={1000}
            filterName={'Price'}
            prefix={'SGD'}
            filterKey="price"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Clarity range slider */}
          <ComplexDualSlider
            min={0}
            max={6}
            filterName={'Clarity'}
            marksLabelArray={clarity}
            filterKey="clarity"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Certified lab */}
          <Grid container spacing={2}>
            <Grid item xs={12} className="cydCertifiedLabTtile">
              <Typography>Certified Lab</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" className="blackOutlinedButton cydGIA">
                GIA
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" className="blackOutlinedButton cydAGS">
                AGS
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" className="brownFilledButton cydGCAL">
                GCAL
              </Button>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Polish range slider */}
          <ComplexDualSlider
            min={0}
            max={3}
            filterName={'Polish'}
            marksLabelArray={polishAndSymmetry}
            filterKey="polish"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Symmetry range slider */}
          <ComplexDualSlider
            min={0}
            max={3}
            filterName={'Symmetry'}
            marksLabelArray={polishAndSymmetry}
            filterKey="symmetry"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Depth% range slider */}
          <DualSlider
            min={0}
            max={100}
            steps={1}
            filterName={'Depth %'}
            postfix={'%'}
            filterKey="depth"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Table% range slider */}
          <DualSlider
            min={0}
            max={100}
            steps={1}
            filterName={'Table %'}
            postfix={'%'}
            filterKey="table"
            setChooseYourDiamondData={setChooseYourDiamondData}
            chooseYourDiamondData={chooseYourDiamondData}
            resetAllSliders={resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
          />
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* cut */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="cydFiltersCutTitle">
                Cut <img src={circleQuestionIcon} onClick={(e)=>{setShowPopover([true, "cut"]); handleClick(e)}} className="cydSliderCircleIcon" />
              </Typography>
              {showPopover[0] && showPopover[1] === "cut" && <PopoverComponent filterKey={"cut"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setShowPopover={setShowPopover} />}
            </Grid>
            <Grid item xs={12}>
              <Typography>JANNPAUL only carries Super Ideal Cut range diamonds.</Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem className="cydFilters paddingTopThirty">
          {/* Fluorescent */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="cydFiltersFluroscentTitle">
                Fluorescent <img src={circleQuestionIcon} onClick={(e)=>{setShowPopover([true, "fluorescence"]); handleClick(e)}} className="cydSliderCircleIcon" />
              </Typography>
              {showPopover[0] && showPopover[1] === "fluorescence" && <PopoverComponent filterKey={"fluorescence"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setShowPopover={setShowPopover} />}
            </Grid>
            <Grid item xs={12}>
              <Typography>JANNPAUL only carries non fluorescent diamonds.</Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
      <Box className="cydFiltersButtonSection">
        <ListItem className="cydFilters">
          <Grid container className="cydFilterButtonStack">
            <Grid item xs={12}>
              <Button className="black-filled cydFiltersViewResults" onClick={() => setToggleFilter(false)}>
                View {numberOfDiamondsRendered} Results
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Link className="resetAllLink cydResetLinkForFilter" onClick={() => setResetAllSliders(true)}>
                Reset All
              </Link>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
    </List>
  );
};
export default MobileFilterDrawer;
