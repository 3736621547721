/**
 * This file has the code for White series diamonds slider and handcrafted collection slider
 */
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { Grid, Card, Typography, CardMedia, Button, Box } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
// import "./homepage-styles.css";
// 
const HomepageSlider = ({sliderData,  isWhiteSeries, isMobile  }) => {
  // Taking the current slide index afterChange
  const [currentSlide, setCurrentSlide] = useState(0);
  // Using ref for next and prev buttons
  let sliderRef = useRef(null);
  // console.log("Slider Ref: ",sliderRef);
  //  function for shifting to next item
  const next = () => {
    sliderRef.current.slickNext();
  };
  //  function for shifting to prev item
  const prev = () => {
    sliderRef.current.slickPrev();
  };
  // props for Slider component
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 3 : 5,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),    
  };
    // console.log("sliderRef ",sliderRef);
  return (
    <Box>
      <Slider className="homepageMobileDiamondSlider homepageDesktopDiamondSlider" ref={sliderRef} {...settings}>
          <Box className={`${isMobile ? currentSlide === 4 ? "homepageSliderMobileActiveCard" : "singleSliderCardMobile" : currentSlide === 3 ? "homepageSliderActiveCard" : "singleSliderCard"}`}>
            <img src={isMobile ? sliderData[1].image : sliderData[0].image} className={`${isMobile ? "homepageSliderImageMobile" : "homepageSliderImage"}`} />
            <Grid container className="paddingTopBottomThirty">
              <Grid item xs={2} >
                {isMobile ? currentSlide === 4 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) : currentSlide === 3 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) }
              </Grid>
              <Grid item xs={8}>
                <Typography className="diamondSliderTitle">{isMobile ? sliderData[1].name : sliderData[0].name}</Typography> 
                {isWhiteSeries ? (<Typography>
                  white series<sup>TM</sup>
                </Typography>): ""}
              </Grid>
              <Grid item xs={2}>
              {isMobile ? currentSlide === 4 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) : currentSlide === 3 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) }
              </Grid>
            </Grid>
          </Box>
        <Box className={`${isMobile ? currentSlide === 0 ? "homepageSliderMobileActiveCard" : "singleSliderCardMobile" : currentSlide === 4 ? "homepageSliderActiveCard" : "singleSliderCard"}`}>
            <img src={isMobile ? sliderData[2].image : sliderData[1].image} className={`${isMobile ? "homepageSliderImageMobile" : "homepageSliderImage"}`} />
            <Grid container className="paddingTopBottomThirty">
              <Grid item xs={2}>
              {isMobile ? currentSlide === 0 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) : currentSlide === 4 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) }
              </Grid>
              <Grid item xs={8}>
                <Typography className="diamondSliderTitle">{isMobile ? sliderData[2].name : sliderData[1].name}</Typography>
               {isWhiteSeries ? (<Typography>
                  white series<sup>TM</sup>
                </Typography>): ""}
              </Grid>
              <Grid item xs={2}>
              {isMobile ? currentSlide === 0 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) : currentSlide === 4 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) }
              </Grid>
            </Grid>
          </Box>
          <Box className={`${isMobile ? currentSlide === 1 ? "homepageSliderMobileActiveCard" : "singleSliderCardMobile" : currentSlide === 0 ? "homepageSliderActiveCard" : "singleSliderCard"}`}>
            <img src={isMobile ? sliderData[3].image : sliderData[2].image} className={`${isMobile ? "homepageSliderImageMobile" : "homepageSliderImage"}`} />
            <Grid container className="paddingTopBottomThirty">
              <Grid item xs={2}>
              {isMobile ? currentSlide === 1 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) : currentSlide === 0 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) }
              </Grid>
              <Grid item xs={8}>
                <Typography className="diamondSliderTitle">{isMobile ? sliderData[3].name : sliderData[2].name}</Typography>
               {isWhiteSeries ? (<Typography>
                  white series<sup>TM</sup>
                </Typography>): ""}
              </Grid>
              <Grid item xs={2}>
              {isMobile ? currentSlide === 1 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) : currentSlide === 0 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) }
              </Grid>
            </Grid>
          </Box>
          <Box className={`${isMobile ? currentSlide === 2 ? "homepageSliderMobileActiveCard" : "singleSliderCardMobile" : currentSlide === 1 ? "homepageSliderActiveCard" : "singleSliderCard"}`}>
            <img src={isMobile ? sliderData[4].image : sliderData[3].image} className={`${isMobile ? "homepageSliderImageMobile" : "homepageSliderImage"}`} />
            <Grid container className="paddingTopBottomThirty">
              <Grid item xs={2}>
              {isMobile ? currentSlide === 2 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) : currentSlide === 1 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) }
              </Grid>
              <Grid item xs={8}>
                <Typography className="diamondSliderTitle">{isMobile ? sliderData[4].name : sliderData[3].name}</Typography>
               {isWhiteSeries ? (<Typography>
                  white series<sup>TM</sup>
                </Typography>): ""}
              </Grid>
              <Grid item xs={2}>
              {isMobile ? currentSlide === 2 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) : currentSlide === 1 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) }
              </Grid>
            </Grid>
          </Box>
          <Box className={`${isMobile ? currentSlide === 3 ? "homepageSliderMobileActiveCard" : "singleSliderCardMobile" : currentSlide === 2 ? "homepageSliderActiveCard" : "singleSliderCard"}`}>
            <img src={isMobile ? sliderData[0].image : sliderData[4].image} className={`${isMobile ? "homepageSliderImageMobile" : "homepageSliderImage"}`} />
            <Grid container className="paddingTopBottomThirty">
              <Grid item xs={2}>
              {isMobile ? currentSlide === 3 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) : currentSlide === 2 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={prev} icon={faAngleLeft} />) }
              </Grid>
              <Grid item xs={8}>
                <Typography className="diamondSliderTitle">{isMobile ? sliderData[0].name : sliderData[4].name}</Typography>
               {isWhiteSeries ? (<Typography>
                  white series<sup>TM</sup>
                </Typography>): ""}
              </Grid>
              <Grid item xs={2}>
              {isMobile ? currentSlide === 3 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) : currentSlide === 2 && (<FontAwesomeIcon className="paddingFifteenTop homePageSliderButton" onClick={next} icon={faAngleRight} />) }
              </Grid>
            </Grid>
          </Box>
      </Slider>
    </Box>
  );
};

export default HomepageSlider;