/**
 * This file calls all the menus
 */
import { useState } from 'react';
import MobileDiamondsmenu from './mobile-diamonds-menu';
import MobileEngagementRingsMenu from './mobile-engagement-rings-menu';
import MobileCustomizeYourRing from './mobile-customize-your-ring';
import MobileEducationMenu from './mobile-education-menu';
import { globeIcon, pinIcon, userIcon, wishlistIcon, closeIcon, rightArrowIcon } from '../../assets/icons';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import NativeSelect from '@mui/material/NativeSelect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
const MobileMenu = ({ setToggleMenu }) => {
  // state variable to handle menu change
  const [currentMenu, setCurrentmenu] = useState(null);
  // menu items list
  const menuList = [
    'DIAMONDS',
    'ENGAGEMENT RINGS',
    'CUSTOMIZE YOUR RING',
    'EDUCATION',
    'BOOK AN APPOINTMENT',
    'CONTACT US',
  ];
  return (
    <Box className="mobileMenuContainer">
      {/* Changing the classnames according to submenus and main-menu */}
      <Box className={`${(currentMenu === null || currentMenu === 4 || currentMenu === 5) ? "mobileMenuListRow" : currentMenu === 2 ? "customizeYourRingMenu" : "mobileSubMenuListRow"}`}>
        {(currentMenu === null || currentMenu === 4 || currentMenu === 5) && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <img
                src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png"
                className="jpMobileMenuHeaderLogo"
              />
              <img className="jpMobileMenuCancelIcon" src={closeIcon} onClick={() => setToggleMenu(false)} />
            </Stack>
            <Divider />
            {menuList.map((menuItem, index) => (
              <Stack
                className="jpSandwichMenuMobile"
                key={index}
                direction="row"
                justifyContent="space-between"
                onClick={() => {
                  setCurrentmenu(index);
                }}
              >
                <Typography>{menuItem}</Typography>
                {menuItem !== 'BOOK AN APPOINTMENT' && menuItem !== 'CONTACT US' && (
                  <img src={rightArrowIcon} />
                )}
              </Stack>
            ))}
          </>
        )}
        {/* passing functions to set current menu and close the menu */}
        {currentMenu === 0 && (
          <MobileDiamondsmenu setCurrentmenu={setCurrentmenu} setToggleMenu={setToggleMenu} />
        )}
        {currentMenu === 1 && (
          <MobileEngagementRingsMenu setCurrentmenu={setCurrentmenu} setToggleMenu={setToggleMenu} />
        )}
        {currentMenu === 2 && (
          <MobileCustomizeYourRing setCurrentmenu={setCurrentmenu} setToggleMenu={setToggleMenu} />
        )}
        {currentMenu === 3 && (
          <MobileEducationMenu setCurrentmenu={setCurrentmenu} setToggleMenu={setToggleMenu} />
        )}
      </Box>
      <Divider />
      <Stack>
        <Typography variant="subtitle" className="jpSandwichMenuIcons account">
          <Link>
            <img src={userIcon} />
            My Account
          </Link>
        </Typography>
        <Typography variant="subtitle" className="jpSandwichMenuIcons whishlist">
        <Link>
          <img src={wishlistIcon} />
          Wishlist
        </Link>
        </Typography>
        <Typography variant="subtitle" className="jpSandwichMenuIcons locator">
        <Link>
          <img src={pinIcon} />
          Store Locator
        </Link>
        </Typography>
        <Typography variant="subtitle" className="jpSandwichMenuIcons globe">
        <Link>
          <img src={globeIcon} />
          <NativeSelect disableUnderline className="jpSandwichMenudd" IconComponent={()=>( <FontAwesomeIcon icon={faAngleDown} className="selectDropdownArrow" /> )}>
            <option>SGD</option>
            <option>AUG</option>
          </NativeSelect>
        </Link>
        </Typography>
      </Stack>
    </Box>
  );
};
export default MobileMenu;
