// import { useEffect } from "react";
// import ReactGA from 'react-ga';
import ChooseYourDiamondLanding from "./pages/choose-your-diamond/cyd-landing";
import DiamondDetailsLanding from "./pages/diamond-details-page/diamond-details-landing";
import StartWithSetting from "./pages/start-with-setting/start-with-setting-landing";
import Homepage from "./pages/homepage/homepage";
import MasterCrafterLanding from "./pages/master-crafter/master-crafter-landing";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
// const TRACKING_ID = "G-C2QG09P6Q8"; // TRACKING_ID for Google analytics
// ReactGA.initialize(TRACKING_ID);
function JannPaul() {
  const isMobile = useMediaQuery('(max-width:600px)');
  if(isMobile){
    // import('./assets/css/homepage/homepage-mobile.css'); 
    import('./assets/css/jannpaul.css');
  }else{
    // import('./assets/css/homepage/homepage-desktop.css');
    import('./assets/css/jannpaul.css');
  }
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  const paths = {
    homepage : "/",
    chooseYourDiamond : "/choose-your-diamond",
    diamondDetails: "/diamond-details",
    startWithSetting: "/start-with-setting",
    masterCrafter: "/master-crafter"
  }
  return (
  <BrowserRouter>
    <Routes>
      <Route path={paths.homepage} element={<Homepage isMobile={isMobile} paths={paths} />} />
      <Route path={paths.chooseYourDiamond} element={<ChooseYourDiamondLanding isMobile={isMobile} paths={paths} />} />
      <Route path={paths.diamondDetails} element={<DiamondDetailsLanding isMobile={isMobile} paths={paths} />} />
      <Route path={paths.startWithSetting} element={<StartWithSetting isMobile={isMobile} paths={paths} />} />
      <Route path={paths.masterCrafter} element={<MasterCrafterLanding isMobile={isMobile} paths={paths} />} />
    </Routes>
  </BrowserRouter>
  );
}
export default JannPaul;