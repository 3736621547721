/**
 * This file has the code for desktop diamonds menu
 */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import whiteSeriesImg from "../../assets/media/mega-menu-media/white-series-collection.png";
import { diamondRound, diamondCushion, diamondOval, diamondDecagon, diamondRadiant, diamondShield, diamondEmerald } from "../../assets/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const shopByDiamondList = [
  {
    name: "Round",
    image: diamondRound,
  },
  {
    name: "Oval",
    image: diamondOval,
  },
  {
    name: "Decagon",
    image: diamondDecagon,
  },
  {
    name: "Cushion",
    image: diamondCushion,
  },
  {
    name: "Radiant",
    image: diamondRadiant,
  },
  {
    name: "Shield",
    image: diamondShield,
  },
  {
    name: "Emerald",
    image: diamondEmerald,
  },
];
const shopByCollectionList =[
  "White Series", "Collection 2", "Collection 3", "Collection 4"
];
const DiamondsMenu = () => {
  return (
    <Container className="menuTabContainer">
      <Grid container className="insideTabContainer">
        <Grid item md={3} className="shopByDiamondColumn">
            <Typography variant="subtitle1" className="shopByTitles">Shop by Diamond <FontAwesomeIcon icon={faAngleRight} /></Typography>
            {shopByDiamondList.map((diamond, index) => (  
              <Typography variant="subtitle2" className="shopByItems" key={index}><img src={diamond.image} className="shopByDiamondImages" /> {diamond.name}</Typography>
            ))}
        </Grid>
        <Grid item md={3} className="shopByDiamondColumn">
          <Typography variant="subtitle1" className="shopByTitles">Shop by Collection <FontAwesomeIcon icon={faAngleRight} /></Typography>
          {shopByCollectionList.map((collection, index) => (
              <Typography variant="subtitle2" className="shopByItems" key={index}>{collection}</Typography>
          ))}
        </Grid>
        <Grid item md={6} className="menuRightSideContent">
            <img src={whiteSeriesImg} className="menuRightSideContentImage"/>
            <Box className="menuRightSideContentText">
            <Typography variant="h5">White Series <br />Collection</Typography>
            <Typography className="paraDiamond" variant="subtitle1">White Series Collection Description</Typography>
            <Button className="menuBtnDiamond">Shop Now</Button>
            </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default DiamondsMenu;