/**
 * This file has the code for desktop customize your ring menu
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Link } from 'react-router-dom';
import onlineCustomizationImage from "../../assets/media/mega-menu-media/online-customization-img.png";
import inStoreConsultation from "../../assets/media/mega-menu-media/instore-consultation-img.png";
const CustomizeYourRing=({linkValue, handleTabChange})=>{
    return (
        <Container className="menuTabContainer">
        <Grid container className="insideTabContainer">
          <Grid item md={6} className="menuRightSideContent" >
              <img src={onlineCustomizationImage} className="menuRightSideContentImage" />
              <Box className="menuRightSideContentText">
              <Typography variant="h5">Online <br />Customization</Typography>
              <Typography className="paraDiamond" variant="subtitle1">Online Customization Description</Typography>
              <Link to={linkValue}><Button className="menuBtnDiamond" onClick={handleTabChange}>Start Now</Button></Link>
              </Box>
          </Grid>
          <Grid item md={6} className="menuRightSideContentOne">
              <img src={inStoreConsultation} className="menuRightSideContentImage"/>
              <Box className="menuRightSideContentText">
              <Typography variant="h5">In Store < br/>Consultation</Typography>
              <Typography className="paraDiamond" variant="subtitle1">In Store Consultation Description</Typography>
              <Button className="menuBtnDiamond">Start Now</Button>
              </Box>
          </Grid>
        </Grid>
       </Container>
    );
}
export default CustomizeYourRing;