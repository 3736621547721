/**
 * This file has the structure for carat size popup in mobile view
 */
import {useState} from "react";
import CaratSizeRangeSlider from "./ddp-carat-size-range-slider";
import { closeIcon } from "../../assets/icons";
import { caratSizePreviewImage } from "../../assets/diamond-details-media";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
const ViewCaratSizePopup = ({caratSize, setOpenViewCaratSizePopup}) =>{
    const [open, setOpen] = useState(true);
    const handleClose = () =>{
        setOpen(false);
        setOpenViewCaratSizePopup(false);
    }
    const handleOpen = () =>setOpen(true);
    return(
        <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      className="mobileViewCaratSizeDrawer"
    >
        <List className="ddpViewCaratSizeList">
        <Box className="ddpViewCaratSizeBox1">
          <ListItem className="ddpViewCaratSizeListItem">
            <Typography className="ddpViewCaratSizeTypography">
            Carat Size Preview
            </Typography>
            {/* Closing the drawer when clicked on closeIcon */}
            <img
              src={closeIcon}
              onClick={handleClose}
              className="ddpDrawerCloseIcon"
            />
          </ListItem>
        </Box>
        <Box className="ddpViewCaratSizeBox2">
            <ListItem className="ddpViewCaratSizeListItem">
            <img src={caratSizePreviewImage} className="ddpCaratSizePreviewImage" />
            </ListItem>
        </Box>
        <Box className="ddpViewCaratSizeBox3">
        <ListItem className="ddpViewCaratSizeListItem">
            <CaratSizeRangeSlider caratSize={caratSize} />
        </ListItem>
        </Box>
        </List>
    </SwipeableDrawer>
    );
}
export default ViewCaratSizePopup;