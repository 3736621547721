/**
 * This file contains code for grid view
 */
import { useState } from "react";
import { Link } from "react-router-dom"; 
import DiamondDetailsModal from "./cyd-grid-view-modal";
import ProductViewDrawer from "./cyd-mobile-product-view.js";
import PaginationComponent from "../../components/pagination.js";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { cydDiamondImage } from "../../assets/cyd-media.js";
import {
  wishlistIcon,
  quickViewIcon,
  compareIcon,
} from "../../assets/icons.js";
// custom Tooltip code from mui -> majorly used for styling of tooltip
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top"
    className="onHoverTooltip"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    boxShadow: theme.shadows[5],
  },
}));
const GridView = ({ diamondData, isMobile }) => {
  // toggling product view for mobile
  const [toggleProductView, setToggleProductView] = useState([false, null]);
  const [open, setOpen] = useState([false, null]);
  const [hasMouseEntered, setHasMouseEntered] = useState([false, null]);
  const handleOpen = (index) => setOpen([true, index]);
  // setting default page to show and setting page changes for pagination
  const [page, setPage] = useState(1);
  const totalItemsPerPage = isMobile ? 8 : 16;
  // Settings for pagination
  const totalPages = Math.ceil(diamondData.length / totalItemsPerPage);
  const pageContent = diamondData.slice(
    (page - 1) * totalItemsPerPage,
    page * totalItemsPerPage
  );
  function handlePageChange(event, value) {
    setPage(value);
    // console.log(page);
  }
  function handlePropagation(event, index){
    event.preventDefault();
    event.stopPropagation();
    setToggleProductView([true, index]);
    setOpen([true, index]);
    console.log(toggleProductView);
  }
  return (
    <>
      <Grid container spacing={1} className="cydGridView">
        {pageContent &&
          pageContent.map((item, index) => (
              <Grid
              item
              md={3}
              xs={6}
              onMouseEnter={() => setHasMouseEntered([true, index])}
              onMouseLeave={() => setHasMouseEntered([false, null])}
              className="cydGridViewContainer"
              key={index}
            >
              <Link key={index} to="/diamond-details" state={item} className="linkComponent">
              <Box className="cydGridViewImageAndContent">
              <img src={cydDiamondImage} className="cydGridViewImage" loading="lazy" />
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ md: 8 }}
                className="cydGridViewStack"
              >
                <Box className={`${hasMouseEntered[0] && hasMouseEntered[1] === index ? "cydActiveGridViewBox" : ""} cydGridViewBox`}>
                  <Typography className="cydGridViewText1 productNameTitleBlack">
                    {item.carat}ct {item.cut} {item.shape}
                  </Typography>
                  <Typography className="cydGridViewText3">
                    {item.color} COLOR · {item.clarity}
                  </Typography>
                  {/* Will replace price value with actual price - currently price value is not loaded */}
                  <Typography className="cydGridViewText2">SGD5,200</Typography>
                </Box>
                {(isMobile ||
                  (hasMouseEntered[0] && hasMouseEntered[1] === index)) && (
                  <Stack
                    direction={{ xs: "row", md: "column" }}
                    className="cydGridViewIconStack"
                  >
                    {isMobile ? (
                      <>
                          <img src={wishlistIcon} className="whishlistIcon" loading="lazy" />
                          <img
                            src={quickViewIcon}
                            className="quichViewIcon"
                            onClick={(e) => handlePropagation(e, index) }
                            loading="lazy"
                          />
                          <img src={compareIcon} className="compareIcon" loading="lazy" />
                      </>
                    ) : (
                      <>
                        <BootstrapTooltip
                          title="Add to Wishlist"
                          className="cydTooltip"
                        >
                          <Fade
                            in={true}
                            timeout={1500}
                            className="actionItemsFadeUp"
                          >
                            <img src={wishlistIcon} className="whishlistIcon" loading="lazy" />
                          </Fade>
                        </BootstrapTooltip>
                        <BootstrapTooltip
                          title="Quick View"
                          className="cydTooltip"
                        >
                          <Fade
                            in={true}
                            timeout={1500}
                            className="actionItemsFadeUp"
                          >
                            <img
                              src={quickViewIcon}
                              className="quichViewIcon"
                              onClick={(e) => { handlePropagation(e, index) }}
                              loading="lazy"
                            />
                          </Fade>
                        </BootstrapTooltip>
                        <BootstrapTooltip
                          title="Compare"
                          className="cydTooltip"
                        >
                          <Fade
                            in={true}
                            timeout={1500}
                            className="actionItemsFadeUp"
                          >
                            <img src={compareIcon} className="compareIcon" loading="lazy" />
                          </Fade>
                        </BootstrapTooltip>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
              </Box>
            </Link>
            {/* Product view drawer mobile and  Product view modal desktop  */}
            {isMobile
                ? toggleProductView[0] &&
                  toggleProductView[1] === index && (
                    <ProductViewDrawer
                      data={item}
                      index={index}
                      setToggleProductView={setToggleProductView}
                    />
                  )
                : open[0] === true &&
                  open[1] === index && (
                    <DiamondDetailsModal
                      itemData={item}
                      setOpenModal={setOpen}
                    />
                  )}
            </Grid>
            
          ))}
      </Grid>
      {/* Pagination */}
      <PaginationComponent
        totalPages={totalPages}
        page={page}
        handlePageChange={handlePageChange}
        isMobile={isMobile}
      />
    </>
  );
};
export default GridView;
