/**
 * This file contains the structure for CTA cards for example cards in discover more section
 */
import { useState, useRef, useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import "../assets/css/components.css";
const CTACard = ({ item, index, isMobile }) => {
  //hover flag to show and hide buttons
  const [hoverFlag, setHoverFlag] = useState([false, null]);
  // by how much height ImageListItemBar will shift down
  const [height, setHeight] = useState(0);
  const measuredRef = useRef(null);
  // function to get height of ImageListItemBar
  useEffect(() => {
    if (measuredRef.current) {
      const updateHeight = () => {
        setHeight(measuredRef.current.getBoundingClientRect().height);
        // console.log(measuredRef.current.getBoundingClientRect().height);
      };
      // Using setTimeout to ensure measurement happens after layout
      const timer = setTimeout(updateHeight, 3000);

      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <ImageListItem
      className="jpCTACardHeading"
      onMouseEnter={() => setHoverFlag([true, index])}
      onMouseLeave={() => setHoverFlag([false, null])}
    >
      <img
        // srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
        // src={`${item.image}?w=248&fit=crop&auto=format`}
        srcSet={item.image}
        src={item.image}
        loading="lazy"
        className="jpCTACardImages"
        // onLoad={updateHeight}
        style={{ paddingBottom: `${isMobile ? height : 0}px` }}
      />
      <ImageListItemBar
        className="jpCTACardHead"
        title={item.imageTitle}
        ref={measuredRef}
        subtitle={
          (hoverFlag[0] && index === hoverFlag[1]) || isMobile ? (
            <>
              <Typography className="jpCTACardText">
                {item.imageSubtitle}
              </Typography>
              <br />
              <Button className="jpCTACardBtn black-filled" variant="contained">
                {item.ctaName}
              </Button>
            </>
          ) : (
            ""
          )
        }
      />
    </ImageListItem>
  );
};
export default CTACard;
