/**
 * This file contains the structure of popover component
 */
import {useState} from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import "../assets/css/components.css";
const popoverData = {
  carat:
    "The international weight unit used in the measurement of gemstones and diamonds. A carat is equivalent to 200 mg, or 0.2 g.",
  color:{
    label: ["G", "F", "E", "D"],
    description : ["The best possible \"near-colorless\" rating. When compared to far higher \"colorless\" grades, color may be detectable. Outstanding value.","Platinum or white gold are the ideal materials to set a \"colorless\" grade. A qualified gemologist is able to identify faint color.", "Platinum or white gold are the ideal materials to set a \"colorless\" grade. Even a skilled eye finds it challenging to perceive color traces.","The highest, most highly valued \"colorless\" grade, best set in white gold or platinum. Exceptionally rare."]
  },
  price: "The diamond's pricing",
  clarity: {
    label: ["FL-IF", "VVS1-VVS2","VS1-VS2"],
    description:["neither exterior nor interior features. Internally Perfect: May only have minor flaws on the outside. Both are extremely rare.","Very, very minimally involved. Features tiny, even to the untrained eye, and hard to notice at 10x magnification.","Very slightly included. Minor inclusions ranging from difficult (VS1) to somewhat easy (VS2) to see at 10x magnification."]
  },
  polish:
    "The general state of a finished diamond's faceted surfaces, such as the degree of edge definition, how smoothly the facets have been polished, and whether any marks can be seen with a polishing wheel. Although polish marks are rarely visible to the unaided eye, high-quality polish is necessary for optimal light performance.",
  symmetry:
    "The accuracy and balance of a diamond's facets and how that affects the stone's brightness.",
  depth:
    "the height of a diamond divided by its average girdle diameter, measured from the culet to the table. one of the fundamental ratios that affects a diamond's fire, brilliance, and beauty.",
  table:
    "The fraction of the diamond's average diameter that represents the width of the table. This parameter, which is part of the total cut grade, is essential to a diamond's light performance.",
  fluorescence:
  "A measure of the visible light some diamonds emit when exposed to ultraviolet (UV) rays. Diamonds with a strong or very strong fluorescence are a better value because the market prices them slightly lower. It is quite rare for fluorescence to have any visual impact on a diamond's appearance, it and it does not compromise the gem's structural integrity in any way.",
  cut: "JANNPAUL only carries Super Ideal Cut range diamonds.",
  measurement: "Measurement description here",
  brilliance: "Brilliance description here",
  fire: "Fire description here",
  scintillation: "Scintillation description here"
};
const PopoverComponent = ({ filterKey, anchorEl, setAnchorEl, setShowPopover, setPopoverKey }) => {
  console.log(popoverData[filterKey]);
  console.log(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setShowPopover([false, null]);
    setPopoverKey && setPopoverKey(null);
  };
  const [open, setOpen] = useState(true);
  const WithTabs = () =>{
    const [ currentTab, setCurrentTab ] = useState(0);
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    }
    return (
      <Box className="popoverTabsBox">
        <Tabs
          value={currentTab}
          onChange={handleChange}
          className="popoverTabsContainer"
        >
          {popoverData[filterKey].label.map((item,index)=>(
            <Tab className="popoverTab" key={index} label={item} onClick={()=>setCurrentTab(index)} />
          ))}
        </Tabs>
        {currentTab === 0 && (<Typography className="popoverText" variant="body1">{popoverData[filterKey].description[0]}</Typography>)}
          {currentTab === 1 && (<Typography className="popoverText" variant="body1">{popoverData[filterKey].description[1]}</Typography>)}
          {currentTab === 2 && (<Typography className="popoverText" variant="body1">{popoverData[filterKey].description[2]}</Typography>)}
          {currentTab === 3 && (<Typography className="popoverText" variant="body1">{popoverData[filterKey].description[3]}</Typography>)}
      </Box>
    );
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
      className="popoverComponent"
    >
      {filterKey === "color" || filterKey === "clarity" ? WithTabs() : (<Typography className="popoverText" variant="body1">{popoverData[filterKey]}</Typography>)}
    </Popover>
  );
};
export default PopoverComponent;
