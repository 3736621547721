/**
 * This component is like the container which holds all the Range Sliders - basic and advance
 */
import { useState } from 'react';
import DualSlider from "./cyd-dual-slider";
import ComplexDualSlider from "./cyd-complex-dual-slider";
import PopoverComponent from "../../components/popover.js";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { filterIcon, circleQuestionIcon } from "../../assets/icons.js";
const RangeSliderFilters = ({ setChooseYourDiamondData, chooseYourDiamondData, handleTabChange, resetAllSliders, setResetAllSliders }) => {
    const [ showAdvancedFilters, setShowAdvancedFilters ] = useState(false);
    // on click popover
    const [ showPopover, setShowPopover ] = useState([false, null]);
    const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
    const color = [
      {
        value: 0,
        label: "G"
      },
      {
        value: 1,
        label: "F"
      },
      {
        value: 2,
        label: "E"
      },
      {
        value: 3,
        label: "D"
      },
      {
        value: 4,
      },
    ]
    const clarity = [
      {
        value: 0,
        label: "FL"
      },
      {
        value: 1,
        label: "IF"
      },
      {
        value: 2,
        label: "VVS1"
      },
      {
        value: 3,
        label: "VVS2"
      },
      {
        value: 4,
        label: "VS1"
      },
      {
        value: 5,
        label: "VS2"
      },
      {
        value: 6,
      },
    ]
    const polishAndSymmetry = [
      {
        value: 0,
        label: "Good"
      },
      {
        value: 1,
        label: "Very Good"
      },
      {
        value: 2,
        label: "Excellent"
      },
      {
        value: 3,
      },
    ]
    // const popoverData = {
    //   carat:"The international weight unit used in the measurement of gemstones and diamonds. A carat is equivalent to 200 mg, or 0.2 g.",
    //   color : "tabs to be developed",
    //   price: "The diamond's pricing",
    //   clarity: "tabs to be developed",
    //   polish: "The general state of a finished diamond's faceted surfaces, such as the degree of edge definition, how smoothly the facets have been polished, and whether any marks can be seen with a polishing wheel. Although polish marks are rarely visible to the unaided eye, high-quality polish is necessary for optimal light performance.",
    //   symmetry: "The accuracy and balance of a diamond's facets and how that affects the stone's brightness.",
    //   depth: "the height of a diamond divided by its average girdle diameter, measured from the culet to the table. one of the fundamental ratios that affects a diamond's fire, brilliance, and beauty.",
    //   table: "The fraction of the diamond's average diameter that represents the width of the table. This parameter, which is part of the total cut grade, is essential to a diamond's light performance."
    // }
    return (
      <>
      <Grid container spacing={5} className="cydRangeSliderContainer">
        <Grid item md={4} className="cydRangeSliderGrid">
          {/* Carat range slider */}
          <DualSlider
            min={0.5}
            max={7}
            steps={0.5}
            filterName={"Carat"}
            filterKey= "carat"
            setChooseYourDiamondData ={ setChooseYourDiamondData }
            chooseYourDiamondData = {chooseYourDiamondData}
            resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
            // popoverData={popoverData}
          />
        </Grid>
        <Grid item md={4} className="cydRangeSliderGrid colorSliderGrid">
          {/* Color range slider */}
          <ComplexDualSlider
            min={0}
            max={4}
            filterName={"Color"}
            marksLabelArray={color}
            filterKey= "color"
            setChooseYourDiamondData = {setChooseYourDiamondData}
            chooseYourDiamondData ={chooseYourDiamondData}
            resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
            // popoverData={popoverData}
          />
        </Grid>
        <Grid item md={4} className="cydRangeSliderGrid">
          {/* cut */}
          <Typography className="cydDualSliderFilterName">Cut <img src={circleQuestionIcon} className="cydSliderCircleIcon" onClick={(e)=>{setShowPopover([true, "cut"]); handleClick(e)}}/></Typography>
          <Typography  className="cydFilterColumnThree">
            JANNPAUL only carries Super Ideal Cut range diamonds.
          </Typography>
          {showPopover[0] && showPopover[1] === "cut" && <PopoverComponent filterKey={"cut"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setShowPopover={setShowPopover} />}
        </Grid>
        <Grid item md={4} className="cydRangeSliderGrid">
          {/* Price range slider */}
          <DualSlider
            min={1000}
            max={1000000}
            steps={1000}
            filterName={"Price"}
            prefix={"SGD"}
            filterKey= "price"
            setChooseYourDiamondData = {setChooseYourDiamondData}
            chooseYourDiamondData = {chooseYourDiamondData}
            resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
            // popoverData={popoverData}
          />
        </Grid>
        <Grid item md={4} className="cydRangeSliderGrid">
          {/* Clarity range slider */}
          <ComplexDualSlider
            min={0}
            max={6}
            filterName={"Clarity"}
            marksLabelArray={clarity}
            filterKey= "clarity"
            setChooseYourDiamondData = {setChooseYourDiamondData}
            chooseYourDiamondData = {chooseYourDiamondData}
            resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
            handleTabChange={handleTabChange}
            // popoverData={popoverData}
          />
        </Grid>
        <Grid item md={4} className="cydRangeSliderGrid">
          {/* Certified lab */}
          <Typography className="cydDualSliderFilterName">Certified Lab</Typography>
          <Stack className="cydFilterColumnThree" spacing={2} direction="row">
            <Button variant="outlined" className="blackOutlinedButton fullWidthButton smallHeightButton">GIA</Button>
            <Button variant="outlined" className="blackOutlinedButton fullWidthButton smallHeightButton">AGS</Button>
            <Button variant="contained" className="brownFilledButton fullWidthButton smallHeightButton">GCAL</Button>
          </Stack>
        </Grid>
        <Grid item md={12} className="cydRangeSliderAdvFilterGrid">
          <Button onClick={() => setShowAdvancedFilters(!showAdvancedFilters)} className='cydAdvancedFilterButton'>
           <img src={filterIcon} className='cydAdvancedFilterImage' /> Advanced Filter {showAdvancedFilters ? (<FontAwesomeIcon icon={faAngleUp} />) : (<FontAwesomeIcon icon={faAngleDown} />)}
          </Button>
        </Grid>
        </Grid>
        {showAdvancedFilters && (
          <Grid container spacing={5} className="cydAdvancedFiltersContainer">
            <Grid item md={4} className="cydRangeSliderGrid">
              {/* Polish range slider */}
              <ComplexDualSlider
                min={0}
                max={3}
  
                filterName={"Polish"}
                marksLabelArray={polishAndSymmetry}
                filterKey = "polish"
                setChooseYourDiamondData ={ setChooseYourDiamondData }
                chooseYourDiamondData = {chooseYourDiamondData}
                resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
                handleTabChange={handleTabChange}
                // popoverData={popoverData}
              />
            </Grid>
            <Grid item md={4} className="cydRangeSliderGrid">
              {/* Symmetry range slider */}
              <ComplexDualSlider
                min={0}
                max={3}
  
                filterName={"Symmetry"}
                marksLabelArray={polishAndSymmetry}
                filterKey = "symmetry"
                setChooseYourDiamondData ={ setChooseYourDiamondData }
                chooseYourDiamondData = {chooseYourDiamondData}
                resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
                handleTabChange={handleTabChange}
                // popoverData={popoverData}
              />
            </Grid>
            <Grid item md={4} className="cydRangeSliderGrid">
              {/* Fluorescent */}
              <Typography className="cydDualSliderFilterName">Fluorescent <img src={circleQuestionIcon} onClick={(e)=>{setShowPopover([true, "fluorescence"]); handleClick(e)}} className="cydSliderCircleIcon" /></Typography>
              <Typography className="cydFilterColumnThree">
                JANNPAUL only carries non fluorescent diamonds.
              </Typography>
              {showPopover[0] && showPopover[1] === "fluorescence" && <PopoverComponent filterKey={"fluorescence"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setShowPopover={setShowPopover} />}
            </Grid>
            <Grid item md={4} className="cydRangeSliderGrid">
              {/* Depth% range slider */}
              <DualSlider
                min={0}
                max={100}
  
                steps={1}
                filterName={"Depth %"}
                postfix={"%"}
                filterKey="depth"
                setChooseYourDiamondData ={ setChooseYourDiamondData }
                chooseYourDiamondData = {chooseYourDiamondData}
                resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
                handleTabChange={handleTabChange}
                // popoverData={popoverData}
              />
            </Grid>
            <Grid item md={4} className="cydRangeSliderGrid">
              {/* Table% range slider */}
              <DualSlider
                min={0}
                max={100}
  
                steps={1}
                filterName={"Table %"}
                postfix={"%"}
                filterKey="table"
                setChooseYourDiamondData ={ setChooseYourDiamondData }
                chooseYourDiamondData = {chooseYourDiamondData}
                resetAllSliders = {resetAllSliders}
            setResetAllSliders={setResetAllSliders}
                handleTabChange={handleTabChange}
                // popoverData={popoverData}
              />
            </Grid>
            {/* Empty grid */}
            <Grid item md={4}></Grid>
          </Grid>
        )}
      </>
    );
}
export default RangeSliderFilters;