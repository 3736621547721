/**
 * This file has the code for diamonds menu
 */
import whiteSeriesImg from "../../assets/media/mega-menu-media/white-series-collection.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { closeIcon, leftArrowIcon, diamondRound, diamondCushion, diamondOval, diamondDecagon, diamondRadiant, diamondShield, diamondEmerald } from "../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
const shopByDiamondList = [
  {
    name: "Round",
    image: diamondRound,
  },
  {
    name: "Oval",
    image: diamondOval,
  },
  {
    name: "Decagon",
    image: diamondDecagon,
  },
  {
    name: "Cushion",
    image: diamondCushion,
  },
  {
    name: "Radiant",
    image: diamondRadiant,
  },
  {
    name: "Shield",
    image: diamondShield,
  },
  {
    name: "Emerald",
    image: diamondEmerald,
  },
];
const shopByCollectionList = [
  "White Series",
  "Collection 2",
  "Collection 3",
  "Collection 4",
];
const MobileDiamondsmenu = ({ setCurrentmenu, setToggleMenu }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Stack
            sm={10}
            className="jpSandwichSubMenu"
            direction="row"
            justifyContent="space-between"
          >
            <Typography className="jpSandwichSubMenuTitle">
              <img src={leftArrowIcon} onClick={() => setCurrentmenu(null)} />
              DIAMONDS
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <img
            className="jpSandwichSubMenuCancel"
            src={closeIcon}
            onClick={() => setToggleMenu(false)}
          />
        </Grid>
      </Grid>
      <Divider />
      <Box direction="column">
        <Accordion defaultExpanded className="jpHeaderAccordion">
          <AccordionSummary 
            className="shopByDiamondTitleOne"
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            SHOP BY DIAMOND
          </AccordionSummary>
          <AccordionDetails className="accordianColumn">
            {shopByDiamondList.map((diamond, index) => (
              <Typography  key={index}>
                <Link className="shopByItemsMobile" underline="none" variant="subtitle1" >
                  <img src={diamond.image} className="shopByDiamondImages" />
                  {diamond.name}
                </Link>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="jpHeaderAccordion">
          <AccordionSummary
            className="shopByDiamondTitleOne"
            expandIcon={<FontAwesomeIcon className="accordionTitleExpand" icon={faAngleDown} />}
          >
            SHOP BY COLLECTION
          </AccordionSummary>
          <AccordionDetails className="accordianColumn">
            <Stack>
            {shopByCollectionList.map((collection, index) => (
              <Typography key={index}>
                <Link className="shopByItemsMobile" underline="none" variant="subtitle1">{collection}</Link>
              </Typography>
            ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Grid className="collectionWhiteSeriesRow" container spacing={2}>
        <Grid item xs={4}>
          <img src={whiteSeriesImg} className="mobileMenuContentImage" />
        </Grid>
        <Grid className="collectionColumn" item xs={8}>
            <Typography variant="h3">
              White Series <br />
              Collection
            </Typography>
            <Typography variant="subtitle">
              White Series Collection <br /> Description
            </Typography>
            <Button className="black-filled" variant="contained">
              Shop Now
            </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default MobileDiamondsmenu;
