/**
 * This file contains the structure for diamond details page
 */
import { useState } from "react";
// for routing
import { useLocation, Navigate } from 'react-router-dom';
import Header from '../../components/header/header';
import MobileHeader from "../../components/header/mobile-header";
import MobileFooter from "../../components/footer/mobile-footer";
import Footer from "../../components/footer/footer";
import CTACard from "../../components/cta-card";
import MobileCTASlider from "../../components/cta-slider-mobile.js";
import DesktopCTASection from "../../components/cta-section-desktop.js";
import ConsumerExperince from "./ddp-consumer-experience.js";
import DiamondInformation from "./ddp-diamond-information.js";
import DiamondJourney from "./ddp-diamond-journey.js";
import CutAnalysis from "./ddp-cut-analysis.js";
import WhiteSeries from "./ddp-white-series.js";
import ProductShowcaseDesktop from "./ddp-product-showcase-desktop";
import ProductShowcaseMobile from "./ddp-product-showcase-mobile";
import MobileProcessSteps from "../../components/mobile-process-steps";
import ProcessSteps from "../../components/process-steps.js";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button"; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import parse from "html-react-parser";
import {
    cydCTACardImage1,
    cydCTACardImage2,
  } from "../../assets/cyd-media";
import { packagingImage, diamondImage, proportionDiagramImage, topImage, bottomImage, fireVideoImage, scintillationImage, opticalBrillianceImage, opticalSymmetryImage, heartImage, arrowImage, viewEbookImage } from "../../assets/diamond-details-media";
import {
  cydStepperDiamondImage,
  cydStepperSettingImage,
  cydStepperRingImage,
} from "../../assets/cyd-media.js";
// CTA cards data
const ctaCardsData = [
    {
      image: cydCTACardImage1,
      imageTitle: "Ask a JANNPAUL Diamond Specialist",
      imageSubtitle:
        "Jannpaul Diamond Specialist can assist you in choosing an engagement ring, personalizing a wedding band or selecting a special anniversary gift.",
      linkToNewPage: "",
      ctaName: "BOOK An APPOINTMENT"
    },
    {
      image: cydCTACardImage2,
      imageTitle: "SEND US YOUR REQUEST",
      imageSubtitle:
        "Not all diamond inventory are listed, for more options please contact us.",
        linkToNewPage: "",
      ctaName: "Contact Us"
    },
];
// steps default data
let steps = [
  {
    text: "Select Your Diamond",
    link: "Browse Diamonds",
    icon: cydStepperDiamondImage,
  },
  {
    text: "Select Your Setting",
    link: "Browse Setting",
    icon: cydStepperSettingImage,
  },
  { text: "Complete Your Ring", icon: cydStepperRingImage },
];
const DiamondDetailsLanding = ({isMobile, paths}) =>{
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const location = useLocation();
  console.log(location);
  const diamondData = location.state;
  if(!diamondData){
    return <Navigate to="/choose-your-diamond" replace />;
  }
  console.log("Data recieved: ", diamondData);
     //importing css files according to desktop and mobile view
    isMobile ? import("../../assets/css/diamond-details/diamond-details-mobile.css") : import("../../assets/css/diamond-details/diamond-details-desktop.css");
    // handle ebook sections tab change
    const handleTabChange = (index) =>{
        setCurrentTabIndex(index)
    }
    const thumbnailArrayData = [diamondImage, proportionDiagramImage, topImage, bottomImage, fireVideoImage, scintillationImage, opticalBrillianceImage, opticalSymmetryImage, heartImage, arrowImage];
    return(
        <>
        {/* Header */}
      {isMobile ? <MobileHeader paths={paths}/>: <Header paths={paths} />}
      {/* Steps */}
      {isMobile ? (
        <MobileProcessSteps pageName={"diamond-details"} data={diamondData.visitedFrom === "start-with-setting" ? diamondData : null } />
      ) : (
        <ProcessSteps pageName={"diamond-details"} data={diamondData.visitedFrom === "start-with-setting" ? diamondData : null } />
      )}
      {/* Product showcase section */}
      {isMobile ?  <ProductShowcaseMobile thumbnailArrayData={thumbnailArrayData} diamondData={diamondData} /> :  <ProductShowcaseDesktop thumbnailArrayData={thumbnailArrayData} diamondData={diamondData} />}
      {/* Diamond Information section */}
      <DiamondInformation diamondData={diamondData} isMobile={isMobile} />
      {/* view Ebook section */}
      {isMobile && (
        <Container className="ddpViewEBookSection">
        <Grid container spacing={2} className="ddpViewEbookGridContainer">
          <Grid item xs={12} className="ddpViewEbookGrid1">
          <img src={viewEbookImage} className="ddpViewEBookImage" />
          </Grid>
          <Grid item xs={12} className="ddpViewEbookGrid2">
          <Box className="ddpViewEBookContentBox">
        <Typography variant="h3" className="ddpViewEbookText1">Explore Full Diamond Information</Typography>
        <Typography className="ddpViewEbookText2">Lorem ipsum dolor sit amet consectetur.</Typography>
        </Box>
          </Grid>
          <Grid item xs={12} className="ddpViewEbookGrid3">
            <Button className="black-filled">View EBook</Button>
          </Grid>
        </Grid>
      </Container>
      )}
      {/* ebook info - diamond journey, cut analysis and white series */}
      <Box className="ddpTabsSection">
            {/* Setting the tabs */}
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              className="ddpTabsContainer"
            >
              <Tab
                label={"Diamond Journey"}
                onClick={() => handleTabChange(0)}
                className="ddpTab"
              />
              <Tab
                label={"Cut Analysis"}
                onClick={() => handleTabChange(1)}
                className="ddpTab"
              />
              <Tab
                label={parse("white series<sup>TM</sup>")}
                onClick={() => handleTabChange(2)}
                className="ddpTab WhiteSeries"
              />
            </Tabs>
            <Divider className="ddpTabsBreakline" />
        </Box>
        <Container className="ddpTabsResultSection">
            {currentTabIndex === 0 && <DiamondJourney isMobile={isMobile} />}
            {currentTabIndex === 1 && <CutAnalysis isMobile={isMobile} />}
            {currentTabIndex === 2 && <WhiteSeries />}
        </Container>
      {/* JP packaging */}
      <Container className="ddpPackagingContainer">
      <Grid
        container
        spacing={2}
        className="paddingTopBottomEightypx"
        direction={isMobile ? "column-reverse" : "row"}
      >
        <Grid
          item
          md={4}
          xs={12}
          className="ddpPackagingContentGrid"
        >
          <Stack className="ddpPackagingContentStack">
            <Typography variant="h3">JANNPAUL Packaging</Typography>
            <Typography>
            Lorem ipsum dolor sit amet consectetur.
            </Typography>
            <Button
              className="black-filled ddpPackagingButton marginTopThirtyFivepx"
              variant="contained"
            >
              Learn More
            </Button>
          </Stack>
        </Grid>
        <Grid item md={8} xs={12} className="ddpPackagingImageGrid">
          <img src={packagingImage} className="ddpPackagingImage" loading="lazy"/>
        </Grid>
      </Grid>
      </Container>
      {/* Consumer Experience */}
        <ConsumerExperince />
      {/* CTAs section */}
      {isMobile ? (
        <MobileCTASlider />
      ) : (
       <DesktopCTASection />
      )}
      {/* CTA cards */}
      <Container className="ddpCTACardsContainer">
        <Grid container className="ddpCTACardsGridContainer" spacing={2}>
          {ctaCardsData.map((item, index) => (
            <Grid
              item
              md={6}
              xs={12}
              key={index}
              className="ctaCardBox ddpCardBox"
            >
              <CTACard item={item} index={index} isMobile={isMobile} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      {isMobile ? <MobileFooter /> : <Footer />}
        </>
    );
}
export default DiamondDetailsLanding;