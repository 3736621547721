import {useState} from 'react';
import { Link as RoutingLink } from "react-router-dom"; 
import ThumbnailVerticalSlider from "./ddp-thumbnail-vertical-slider";
import ConsultDiamondExpert from "./ddp-consult-diamond-expert-modal";
import ViewReportModal from "./ddp-view-report-modal.js";
import ViewCaratSizeModal from "./ddp-view-carat-size-modal.js";
import Grid from "@mui/material/Grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { caratSizeImage, giaReportImage, ebookImage, gcalLogo, gcalReportImage } from "../../assets/diamond-details-media";
import {
  wishlistIcon,
  compareIcon,
  shareIcon
} from "../../assets/icons.js";
const ProductShowcaseDesktop = ({thumbnailArrayData, diamondData }) => {
  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // opening report modal
  const [viewReport, setViewReport] = useState(false);
  //opening carat size modal
  const [viewCaratSize, setViewCaratSize] = useState(false);
  return (
    <Container className="productShowcaseContainer">
      <Grid container spacing={10} className="productShowcaseGridContainer">
        <Grid item md={7} className="productShowcaseThumbnailVerticalSliderGrid">
          <ThumbnailVerticalSlider setImageIndex={setImageIndex} thumbnailArrayData={thumbnailArrayData} />
        </Grid>
        <Grid item md={5} className="productShowcaseDesktopRightContent">
          <Stack direction="row" className="productShowcaseDesktopRightContentStack">
            <Box className="productShowcaseDesktopRightContentStackBox1">
            <Breadcrumbs separator={<FontAwesomeIcon
                icon={faAngleRight}
                className="breadcrumbSeperatorIcon"
              />}
              className="productShowcaseBreadcrumb"
              >
            <Typography className="productShowcaseBreadcrumbLink breadcrumbTypography">Diamond Collections Name</Typography>
            <Typography  className="breadcrumbTypography">Diamond Product Name</Typography>
          </Breadcrumbs>
          <Typography className="productShowcaseText1 productTitleBlack" variant="h3">
            {diamondData.carat} {diamondData.color} {diamondData.clarity}{" "}
            {diamondData.cut} {diamondData.shape}
          </Typography>         
          <Typography className="productShowcaseText2">
            {diamondData.shape} . {diamondData.carat} . {diamondData.color} .{" "}
            {diamondData.clarity} . {diamondData.cut}
          </Typography>         
          <Typography className="productShowcaseText3">
          Short description about the diamond lorem ipsum dolor sit amet consectetur gravida accumsan turpis viverra. Curabitur aliquet ligula sit in pharetra ultricies.
          </Typography>         
          {/* <Typography className="productShowcaseText4">{diamondData.price}</Typography> */}
          <Typography className="productShowcaseText4">SGD5,200</Typography>
          
          <Stack spacing={3} className="productShowcaseButtonStack">
            <RoutingLink key={"startWithSettingLink"} to="/start-with-setting" state={diamondData} className="linkComponent fullWidthButton" >
            <Button variant="contained" className="black-filled fullWidthButton">SELECT THIS DIAMOND</Button>
            </RoutingLink>
            <Button variant="outlined" onClick={handleOpen} className="blackOutlinedButton">CONSULT A DIAMOND EXPERT</Button>
            {/* Consult a diamond expert modal */}
            <ConsultDiamondExpert handleClose={handleClose} handleOpen={handleOpen} open={open} diamondData={diamondData} image={thumbnailArrayData[imageIndex]} />
          </Stack>
          <Divider className="productShowcaseDivider1" />
            <Stack direction="row" className="productShowcasePopupStack">
              <Stack className="productShowcasePopupStack1">
                <img src={gcalLogo} onClick={()=>setViewReport(true)} className="giaReportImage" />
                <Typography onClick={()=>setViewReport(true)} className="giaReportLink ddpLink">GCAL Report</Typography>
                {viewReport && <ViewReportModal report={gcalReportImage} setViewReport={setViewReport}  />}
              </Stack>
              <Stack className="productShowcasePopupStack2">
                <img src={caratSizeImage} onClick={()=>setViewCaratSize(true)} className="caratSizeImage" />
                <Typography onClick={()=>setViewCaratSize(true)} className="caratSizeLink ddpLink">View Carat Size</Typography>
                {viewCaratSize && <ViewCaratSizeModal caratSize={diamondData.carat} setViewCaratSize={setViewCaratSize}  />}
              </Stack>
              <Stack className="productShowcasePopupStack3">
                <img src={ebookImage} className="eBookImage" /> 
                <Link href="https://ebook.jannpaul.com/" className="eBookLink ddpLink">View eBook</Link>
              </Stack>
            </Stack>
          <Divider className="productShowcaseDivider1" />
          <Typography className="productShowcaseText4">{diamondData.price}</Typography>
            </Box>
            <Stack spacing={2} className="productShowcaseIconStack">
              <img src={wishlistIcon} className="whishlistIcon" />
              <img src={compareIcon} className="compareIcon" />
              <img src={shareIcon} className="shareIcon" />
            </Stack>
          </Stack>
          
        </Grid>
        {/* <Grid item md={1} className="productShowcaseIconsGrid">
  
        </Grid> */}
      </Grid>
    </Container>
  );
};
export default ProductShowcaseDesktop;