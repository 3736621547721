/**
 * This file contains the custom hook named useFetch for api calls
 */
import { useState, useEffect } from "react";
import axios from "axios";
const useFetch = (url, data, setTrigger,flag, setFlag, setTotalDiamonds, totalDiamonds) =>{
  // Storing fetched data
    const [ fetchedData, setFetchedData ] = useState([]);
    useEffect(()=>{
        async function fetchData(){
          setTrigger(true);
            await axios.get(url, {
                params: {
                  cydData: data
                }
              }).then( (response) => {setFetchedData(response.data.output); flag ? setTotalDiamonds({allTab: response.data.output.length, resultTab: response.data.output.length }) : setTotalDiamonds({...totalDiamonds, resultTab: response.data.output.length });});
              setTrigger(false);
              setFlag(false);
        }
        fetchData();
    },[ data ]);
    // returning fetched data
    return [ fetchedData ];
}
export default useFetch;