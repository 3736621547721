/**
 * This file has the structure for consumer experince section in Diamond details page
 */
import AccordionComponent from "../../components/accordion.js";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { consumerExperinceImage } from "../../assets/diamond-details-media";
const content = [
  {
    title: "Diamond Cuts",
    text: "Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra.",
    // cta: "Learn More"
  },
  {
    title: "Ring Designs",
    text: "Sagittis praesent sit ultrices metus lacus habitant cras auctor. Molestie eu ullamcorper nam convallis id. Volutpat risus elit et.",
    cta: "Learn More",
  },
  {
    title: "Ring Sizing Guide",
    text: "Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra.",
    // cta: "Learn More"
  },
  {
    title: "Customization & Bespoke Process",
    text: "Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra.",
    // cta: "Learn More"
  },
  {
    title: "Timeline",
    text: "Lorem ipsum dolor sit amet consectetur. Gravida accum adipiscing id viverra.",
    // cta: "Learn More"
  },
];
const ConsumerExperince = () => {
  return (
    <Container className="consumerExperinceContainer">
      <Grid container spacing={2} className="consumerExperienceGridContainer">
        <Grid item md={12} xs={12} className="consumerExperienceTitleGrid">
          <Typography variant="h3">Consumer Experience</Typography>
        </Grid>
        <Grid item md={6} xs={12} className="consumerExperienceImageGrid">
          <img
            src={consumerExperinceImage}
            className="consumerExperienceImage"
          />
        </Grid>
        <Grid item md={6} xs={12} className="consumerExperienceAccordionGrid">
          <AccordionComponent content={content} />
        </Grid>
      </Grid>
    </Container>
  );
};
export default ConsumerExperince;