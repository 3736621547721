/**
 * This file has the code for Desktop header
 */
import { React, useState, useRef, useEffect } from "react";
import DiamondsMenu from "./diamonds-menu";
import EngagementRingsMenu from "./engagement-rings-menu";
import CustomizeYourRingMenu from "./customize-your-ring-menu";
import EducationMenu from "./education-menu";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NativeSelect from "@mui/material/NativeSelect";
import {
  globeIcon,
  pinIcon,
  mailIcon,
  phoneIcon,
  searchIcon,
  userIcon,
  wishlistIcon,
  cartIcon,
} from "../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/jp-header-desktop.css";
const Header = ({ paths }) => {
  // Setting the current active index for selected menu
  const [currentTabIndex, setCurrentTabIndex] = useState(null);
  // reference for header component
  const headerRef = useRef();
  // handling mousedown event and cleanup function for it
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (headerRef.current && !headerRef.current.contains(e.target)) {
        setCurrentTabIndex(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    //console.log(headerRef.current);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });
  
  // handling the tab change
  const handleTabChange = (tabIndex) => {
    if (tabIndex === currentTabIndex) {
      setCurrentTabIndex(null);
    } else {
      setCurrentTabIndex(tabIndex);
    }
  };
  return (
    <>
      {/* Material UI icons and logo */}
      <Grid className="topHeader" container spacing={2}>
        <Grid item md={2} className="iconStack">
          <Stack className="headerIconList" direction="row" spacing={2}>
            <img src={globeIcon} />
            <NativeSelect className="sgdNativeSelect" IconComponent={()=>( <FontAwesomeIcon icon={faAngleDown} className="selectDropdownArrow" /> )}>
              <option className="sgdNativeSelect">SGD</option>
              <option className="sgdNativeSelect">AUG</option>
            </NativeSelect>
            <img src={pinIcon} />
            <img src={mailIcon} />
            <img src={phoneIcon} />
          </Stack>
        </Grid>
        <Grid item md={8} className="jpHeaderLogoGrid">
          <img
            className="jpHeaderLogo"
            src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png"
          />
        </Grid>
        <Grid item md={2} className="iconStack">
          <Stack className="headerIconList" direction="row" spacing={2}>
            {/* icons */}
            <img src={searchIcon} className="headerIconList" />
            <img src={userIcon} className="headerIconList" />
            <img src={wishlistIcon} className="headerIconList" />
            <img src={cartIcon} className="headerIconList" />
          </Stack>
        </Grid>
      </Grid>
      {/* Divider */}
      <Divider className="divider">
        <img src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png" />
      </Divider>
      {/* Menu Tabs */}
      <Box className="megaMenuContainer" ref={headerRef}>
        <Box className="megaMenuNavItems">
          <Typography
            onClick={() => handleTabChange(0)}
            className={`menuTabTitle ${
              currentTabIndex === 0 ? "activeTab" : ""
            } `}
          >
            Diamonds
            {currentTabIndex === 0 ? (
              <FontAwesomeIcon icon={faAngleUp} className="tabArrowIcons" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="tabArrowIcons" />
            )}
          </Typography>
          <Typography
            onClick={() => handleTabChange(1)}
            className={`menuTabTitle ${
              currentTabIndex === 1 ? "activeTab" : ""
            } `}
          >
            Engagement Rings
            {currentTabIndex === 1 ? (
              <FontAwesomeIcon icon={faAngleUp} className="tabArrowIcons" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="tabArrowIcons" />
            )}
          </Typography>
          <Typography
            onClick={() => handleTabChange(2)}
            className={`menuTabTitle ${
              currentTabIndex === 2 ? "activeTab" : ""
            } `}
          >
            Customize Your Ring
            {currentTabIndex === 2 ? (
              <FontAwesomeIcon icon={faAngleUp} className="tabArrowIcons" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="tabArrowIcons" />
            )}
          </Typography>
          <Typography
            onClick={() => handleTabChange(3)}
            className={`menuTabTitle ${
              currentTabIndex === 3 ? "activeTab" : ""
            } `}
          >
            Education
            {currentTabIndex === 3 ? (
              <FontAwesomeIcon icon={faAngleUp} className="tabArrowIcons" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className="tabArrowIcons" />
            )}
          </Typography>
          <Typography
            onClick={() => handleTabChange(4)}
            className={`menuTabTitle`}
          >
            Book An Appointment
          </Typography>
        </Box>
        {currentTabIndex === 0 && <DiamondsMenu />}
        {currentTabIndex === 1 && <EngagementRingsMenu />}
        {currentTabIndex === 2 && (
          <CustomizeYourRingMenu
            linkValue={paths.chooseYourDiamond}
            handleTabChange={handleTabChange}
          />
        )}
        {currentTabIndex === 3 && <EducationMenu />}
      </Box>
    </>
  );
};
export default Header;
