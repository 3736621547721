/**
 * This file contains code for list view
 */
import React, {useState, Fragment} from "react";
import { Link as RoutingLink } from "react-router-dom"; 
import PaginationComponent from '../../components/pagination.js';
import ProductViewDrawer from './cyd-mobile-product-view.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { cydDiamondImage } from "../../assets/cyd-media.js";
import { wishlistIcon, quickViewIcon, compareIcon, diamondCushion, diamondDecagon, diamondEmerald, diamondOval, diamondRadiant, diamondRound, diamondShield } from "../../assets/icons.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
// heads of table
const chooseYourDiamondTableHeads = [
    "Actual Photo",
    "Shape",
    "Carat",
    "Color",
    "Clarity",
    "Cut",
    "Price",
    "",
];
// custom Tooltip code from mui -> majorly used for styling of tooltip
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" className="onHoverTooltip" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      borderRadius: 0,
      boxShadow: theme.shadows[5],
    },
}));
const ListView = ({diamondData, isMobile}) =>{
    // toggling product view for mobile
    const [ toggleProductView, setToggleProductView ] = useState([false, null]);
    // setting default page to show and setting page changes for pagination
    const [page, setPage] =useState(1);
    const totalItemsPerPage = 8;
    // variable and function for handling expanded row
    const [expandRow, setExpandRow] = useState([false,null]);
    function handleExpandedRow(index){
        if(expandRow[1] === index){
            setExpandRow([false,null]);
        }else{
            setExpandRow([true, index]);
        }
    }
    // Settings for pagination
    const totalPages = Math.ceil(diamondData.length / totalItemsPerPage);
    const pageContent = diamondData.slice((page-1)*totalItemsPerPage,page*totalItemsPerPage);
    function handlePageChange(event, value){
      setPage(value);
    }

    const shapeColumnImg = (shape) =>{
      switch(shape){
        case "Cushion":
          return diamondCushion;
          case "Decagon":
          return diamondDecagon;
          case "Emerald":
          return diamondEmerald;
          case "Oval":
          return diamondOval;
          case "Round":
          return diamondRound;
          case "Shield":
          return diamondShield;
          default:
            return diamondRadiant

      }
    }
    return (
      <>
        {isMobile ? (
          // List view mobile strcture
          <List className="cydListViewMobile">
            {pageContent &&
              pageContent.map((row, index) => (
                <React.Fragment key={index}>
                  <ListItem className="cydListViewItems" onClick={()=>setToggleProductView([true, index])}>
                    <img src={cydDiamondImage} loading="lazy" className="mobieListViewImage" />
                    <Stack className="cydListViewTextStack">
                      <Typography className="cydListViewText1">
                        {row.carat}ct {row.cut} {row.shape}
                      </Typography>
                      <Typography className="cydListViewText2">
                        {row.shape} · {row.cut} · {row.color} · {row.clarity} ·{" "}
                        {row.cut}
                      </Typography>
                      <Typography className="cydListViewText3">{"SGD5,200"}</Typography>
                    </Stack>
                    <Stack className="cydListViewIconStack">
                      <img src={wishlistIcon} className="whishlistIcon" loading="lazy" />
                      <img src={quickViewIcon} className="quichViewIcon" loading="lazy" />
                      <img src={compareIcon} className="compareIcon" loading="lazy" />
                    </Stack>
                  </ListItem>
                  {/* Mobile Product view Drawer */}
                   {toggleProductView[0] && toggleProductView[1] === index && ( <ProductViewDrawer data={row} index={index} setToggleProductView={setToggleProductView} />)}
                  <Divider className="cydListViewDivider" />
                </React.Fragment>
              ))}
          </List>
        ) : (
          // List view desktop strcture
          <TableContainer className="cydListViewDesktop">
            <Table className="cydListViewTable">
              {/* table head */}
              {/* <TableHead className="cydListViewTableHead"> */}
                {/* <TableRow className="cydListViewTableHeadRow">
                  {chooseYourDiamondTableHeads.map((head, index) => index === 0 || index === chooseYourDiamondTableHeads.length-1 ? (<TableCell key={index} className="cydTableHeads">{head}</TableCell>) : (<TableCell key={index} className="cydTableHeads">{head} <FontAwesomeIcon icon={faAngleDown} className="cydTableHeadDownArrow" /></TableCell>))}
                </TableRow> */}
              {/* </TableHead> */}
              {/* table body */}
              <TableBody className="cydListViewTableBody">
              <TableRow className="cydListViewTableHeadRow cydListViewRow cydListViewTableHead">
                  {chooseYourDiamondTableHeads.map((head, index) => index === 0 || index === chooseYourDiamondTableHeads.length-1 ? (<TableCell key={index} className="cydTableHeads">{head}</TableCell>) : (<TableCell key={index} className="cydTableHeads">{head} <FontAwesomeIcon icon={faAngleDown} className="cydTableHeadDownArrow" /></TableCell>))}
                </TableRow>
                {pageContent &&
                  pageContent.map((row, index) => (
                    <Fragment key={index}>
                      <TableRow
                        hover
                        // key={index}
                        onClick={() => handleExpandedRow(index)}
                        // className="cydListViewTableRow"
                        className={`${expandRow[0] && expandRow[1] === index ? "cydTableRowBorder" : ""} cydListViewTableRow cydListViewRow`}
                      >
                        <TableCell className="cydListViewTableCell">
                         
                          <img
                            src={cydDiamondImage}
                            className="cydListViewTableImage"
                            loading="lazy"
                          />
                      
                        </TableCell>
                        <TableCell className="cydListViewTableCell cydListViewShapeColumn"> <Box className="imageListView"><img src={shapeColumnImg(row.shape)} className="cydListViewDiamondShape"/> {row.shape}</Box></TableCell>
                        <TableCell className="cydListViewTableCell">{row.carat}</TableCell>
                        <TableCell className="cydListViewTableCell">{row.color}</TableCell>
                        <TableCell className="cydListViewTableCell">{row.clarity}</TableCell>
                        <TableCell className="cydListViewTableCell">{row.cut}</TableCell>
                        <TableCell className="cydListViewTableCell"><Box className="listViewPriceColumn"> {"SGD5,200"}</Box></TableCell>
                        <TableCell className="cydListViewTableCell">
                         <Box className="listViewIconsColumn"> <BootstrapTooltip
                            title="Add to Wishlist"
                            className="cydTooltip"
                          >
                            <img src={wishlistIcon} className="whishlistIcon" loading="lazy" />
                          </BootstrapTooltip>
                          <BootstrapTooltip
                            title="Quick View"
                            className="cydTooltip"
                          >
                            <img
                              src={quickViewIcon}
                              className="quichViewIcon" loading="lazy"
                            />
                          </BootstrapTooltip>
                          <BootstrapTooltip
                            title="Compare"
                            className="cydTooltip"
                          >
                            <img src={compareIcon} className="compareIcon" loading="lazy" />
                          </BootstrapTooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {/* expandable row */}
                      <TableRow className={`${expandRow[0] && expandRow[1] === index ? "cydExpandedRowBorder" : ""} cydListViewTableRow`}>
                        {/* table cell spanning all the columns */}
                        <TableCell colSpan={chooseYourDiamondTableHeads.length} className="cydListViewTableCellExpanded">
                          <Collapse
                            in={expandRow[0] && expandRow[1] === index}
                            timeout="auto"
                            className="cydListViewTableCellCollapse"
                          >
                            <Grid container spacing={4} className="cydListViewExpandedRow">
                              <Grid item md={3}>
                                <img
                                  src={cydDiamondImage}
                                  className="cydListViewExpandedRowImage"
                                  loading="lazy"
                                />
                              </Grid>
                              <Grid item md={6} className="cydListViewExpandedRowTexts">
                                <Typography className="cydListViewExpandedRowText1">
                                  {row.carat} {row.color} {row.clarity} {row.cut}{" "}
                                  {row.shape}
                                </Typography>
                                <Typography className="cydListViewExpandedRowText2">
                                  {row.shape} · {row.carat} · {row.color} · {" "}
                                  {row.clarity} · {row.cut}
                                </Typography>
                                <Typography className="cydListViewExpandedRowText3">{"SGD5,200"}</Typography>
                                <Divider />
                                <Grid container className="cydListViewExpandedRowTable">
                                  <Grid item md={3} className="cydListViewExpandedRowTableCol">
                                    <Typography className="listViewKeys">Polish</Typography>
                                    <Typography className="listViewKeys">Symmetry</Typography>
                                    <Typography className="listViewKeys">Fluorescent</Typography>
                                  </Grid>
                                  <Grid item md={3} className="cydListViewExpandedRowTableCol">
                                    <Typography>{row.polish}</Typography>
                                    <Typography>{row.symmetry}</Typography>
                                    {/* <Typography>{row.fluorescent}</Typography> */}
                                    <Typography>{"none"}</Typography>
                                  </Grid>
                                  <Grid item md={3} className="cydListViewExpandedRowTableCol">
                                    <Typography className="listViewKeys">Depth%</Typography>
                                    <Typography className="listViewKeys">Table%</Typography>
                                    <Typography className="listViewKeys">Certified Lab</Typography>
                                  </Grid>
                                  <Grid item md={3} className="cydListViewExpandedRowTableCol">
                                    <Typography>
                                      {row.depth}
                                    </Typography>
                                    <Typography>
                                      {row.table}
                                    </Typography>
                                    {/* <Typography>{row.certifiedLab}</Typography> */}
                                    <Typography>{"GCAL"}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={3} className="cydListViewExpandedRowTableCol2" >
                                <RoutingLink key={"startWithSettingLink"+index} to="/start-with-setting" state={row} className="linkComponent">
                                <Button variant="contained" className="black-filled fullWidthButton">
                                  Select This Diamond
                                </Button>
                                </RoutingLink>
                                <RoutingLink key={"diamonDetailsLink"+index} to="/diamond-details" state={row} className="linkComponent">
                                <Button variant="outlined" className="blackOutlinedButton fullWidthButton topMarginOnButton">View Details</Button>
                                </RoutingLink>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* Pagination */}
        <PaginationComponent
          totalPages={totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile = {isMobile}
        />
      </>
    );
}
export default ListView;