/**
 * This file has the radio buttons for JP pages
 */
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { activeRadioBtnIcon, inactiveRadioBtnIcon } from "../assets/icons";
const RadioButton = ({value}) =>{
    return(
        <FormControlLabel value={value} control={<Radio icon={<img src={inactiveRadioBtnIcon} className="radioInactiveIcon"/>} checkedIcon={<img className="radioActiveIcon" src={activeRadioBtnIcon} />} className="jpRadioButton" />} label={value} className="jpRadioButtonWithLabel" />
    );
}
export default RadioButton;