/**
 * This file has the checkboxes for JP pages
 */
import { useState, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { activeCheckBoxIcon, inactiveCheckBoxIcon } from "../assets/icons";
// check whether the value is present
const isPresent = (value, data) =>{
    console.log(data);
    let flag = false;
    // console.log(arr);
    for(let i=0;i<data.length;i++){
      if(data[i] === value){
        flag = true;
      }
    }
    // console.log(flag);
    // if it is a value then return true else return false
    return flag;
  }
const CheckboxComponent = ({value, img, setSelectedFilters, resetAll, sectionReset, setResetLinks, data}) =>{
    // console.log("===========================================SWAP==========================inside CheckboxComponent resetAll: "+ resetAll+" || section reset: "+sectionReset);
    const [checked, setChecked] = useState(false);
    // Reset all the checkboxes
    useEffect(() => {
        if (resetAll || sectionReset) {
            setChecked(false);
            console.log("Value of checked for ", value, " is ", checked);
            setSelectedFilters((prevArray)=>prevArray.filter( item => !isPresent(item, data) && sectionReset));
            // set reset links to false
            setResetLinks({
                resetMetalSection: false,
                resetDiamondShapeSection: false,
                resetAll: false
            });
        }
    }, [resetAll, sectionReset]);
    // this function will handle clicking on checkbox
    const handleCheckBoxClick = (event) =>{
        const isChecked = event.target.checked;
        setChecked(isChecked);
        // when the checkbox is checked only then add 
        if(isChecked){
            // when checkbox is clicked, value will be entered
            setSelectedFilters(prevArray => [...prevArray, value]);
        }else{
            // when checkbox is unchecked, value will be removed
            setSelectedFilters(prevArray => prevArray.filter((prevItem)=> prevItem !== value));
        }
    }
    return(
        <FormControlLabel 
            control={
                <Checkbox 
                    id="jp-checkbox-button"
                    icon={<img src={checked ? activeCheckBoxIcon : inactiveCheckBoxIcon} className="checkboxInactiveIcon" />}
                    checkedIcon={<img className="checkboxActiveIcon" src={activeCheckBoxIcon} />}
                    checked={checked}
                    onChange={handleCheckBoxClick}
                    className="jpCheckbox"
                />
            }
            label={
                <Typography className="jpCheckBoxLabel">
                    {img && (<img src={img} className="jpCheckboxicon" />)}
                    {value}
                </Typography>
            }
            className="jpCheckboxWithLabel"
        />
    );
}
export default CheckboxComponent;