/**
 * This file has the structure for thumbnail slider
 */
import Slider from "react-slick";
import Container from "@mui/material/Container";
import { useState } from "react";
const ThumbnailSlider = ({thumbnailImages, setCurrentTabIndex}) =>{
    // Current active index
    const [ activeImage, setActiveImage ] = useState(0);
    const handleClick = (index) =>{
        setCurrentTabIndex(index);
        setActiveImage(index);
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false
    }
    return (
        
            <Slider className="thumbnailSlider" id="thumbnail-slider" {...settings}>
                {thumbnailImages.map((image, index)=>(
                    <img src={image} className={`${index === 0 ? "greyThumbnail" : ""} thumbnailSliderImage ${index === activeImage ? "activeThumbnailSliderImage": ""}`} key={index} onClick={()=>handleClick(index)} />
                ))}
            </Slider>
    );
}
export default ThumbnailSlider;