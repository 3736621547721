/**
 * This file contains loader
 */
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import "../assets/css/components.css";
const Loader = () =>{
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    return(
        <Modal
        open={open}
        onClose={handleClose}
        className="loaderModal"
      >
         <CircularProgress className="loaderCircularProgressBar" />
      </Modal>
    );
}
export default Loader;