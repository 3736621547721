/**
 * This file has the structure for master crafter page
 */
import { useState, useEffect, useRef } from "react";
import Header from "../../components/header/header";
import MobileHeader from "../../components/header/mobile-header";
import MobileFooter from "../../components/footer/mobile-footer";
import Footer from "../../components/footer/footer";
import CTACard from "../../components/cta-card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  masterCrafterBanner,
  masterCarfterCombinedImage1,
  masterCarfterCombinedImage2,
  artOfCarftingImage,
  pursuitOfPerfectionImage,
  ctaCardsImage1,
  ctaCardsImage2,
  youtubeIcon,
  instagramIcon,
} from "../../assets/master-crafter-media.js";
// CTA cards data
const ctaCardsData = [
  {
    image: ctaCardsImage1,
    imageTitle: "Discover our Designs",
    imageSubtitle: "Lorem ipsum dolor sit amet consectetur.",
    linkToNewPage: "",
    ctaName: "Shop Now",
  },
  {
    image: ctaCardsImage2,
    imageTitle: "Ring Customisation",
    imageSubtitle:
      "Not all diamond inventory are listed, for more options please contact us.",
    linkToNewPage: "",
    ctaName: "customise your own",
  },
];
const MasterCrafterLanding = ({ isMobile, paths }) => {
  //importing css files according to desktop and mobile view
  isMobile
    ? import("../../assets/css/master-crafter/master-crafter-mobile.css")
    : import("../../assets/css/master-crafter/master-crafter-desktop.css");
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      // Updating scroll position
      setScrollPosition(window.scrollY);
      console.log(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [flag, setFlag] = useState(window.scrollY >= 900);
  // Calculating the translateX values based on the scroll position
  let line1Translate, line2Translate;
  // if(flag){
    line1Translate = Math.min(scrollPosition / 20, 100); // Limiting the translation to 100px
    line2Translate = Math.min(scrollPosition / 20, 100);
  // }

  return (
    <>
      {/* Header */}
      {isMobile ? <MobileHeader paths={paths} /> : <Header paths={paths} />}
      <Box className="masterCrafterBannerImageBox">
        <img
          loading="lazy"
          src={masterCrafterBanner}
          className="masterCrafterBannerImage"
        />
      </Box>
      <Box className="masterCrafterBlackBgBox masterCrafterMiyabiJPBox">
        <Container className="masterCrafterContainer1">
          <Box className="miyabiJannPaulBox">
            <Typography variant="h2" className="miyabiJannPaulTitle">
              MIYABI X JANNPAUL
            </Typography>
            <Typography className="miyabiJannPaulText">
              We understand the importance of every engagement ring hence the
              endless pursuit of perfection. When it comes to diamonds, the
              crafting of the ring, or setting the actual diamond, we place the
              utmost care and attention in every minute detail. Every process
              and procedure that we undertake upholds the strictest value of
              JANNPAUL, only the very best for our clients.
            </Typography>
            <img
              src={masterCarfterCombinedImage1}
              className="masterCarfterCombinedImage1 masterCrafterPaddingTop80px"
            />
          </Box>
          <Box className="masterCrafterArtOfCraftingBox masterCrafterPaddingTop80px" onMouseEnter={()=>setFlag(true)}>
            <Typography
              variant="h4"
              className="masterCrafterArtOfCraftingSubTitle"
            >
              Personalized
            </Typography>
            <Typography
              variant="h2"
              className="masterCrafterArtOfCraftingTitle"
            >
              Art of crafting
            </Typography>
            <Typography className="masterCrafterArtOfCraftingText">
              Meticulous craftsmanship. Time-tested traditions in fine jewelry,
              advanced by technology. Lorem ipsum dolor sit amet consectetur.
              Ante tincidunt orci ac morbi rhoncus vitae massa sed ac.
            </Typography>
            <img
              src={artOfCarftingImage}
              className="masterCrafterArtOfCraftingImage masterCrafterPaddingTop80px"
            />
          </Box>
        </Container>
        {/* Will work after completing rest of the page structure */}
        <Box className="masterCrafterMarqueeBox masterCrafterPaddingTop80px">
          <Typography
            variant="h2"
            className="scrollLeftToRightTitle"
            style={{ transform: `translateX(${line1Translate}px)`, transition: "transform 0.2s ease-out"}}
          >
            “quote by miyabi dolor
          </Typography>
          <Typography
            variant="h2"
            className="scrollRightToLeftTitle"
            style={{ transform: `translateX(-${line2Translate}px)`,transition: "transform 0.2s ease-out"}}
          >
            {" "}
            sit amet consectetur”
          </Typography>
        </Box>
        <Container className="masterCarfterCombinedImage2Container masterCrafterPaddingTop80px">
          <img
            src={masterCarfterCombinedImage2}
            className="masterCarfterCombinedImage2"
          />
          <Box className="shortStoryAboutMiyabiBox masterCrafterPaddingTop80px">
            <Typography variant="h2" className="shortStoryAboutMiyabiTitle">
              short Story about miyabi
            </Typography>
            <Typography className="shortStoryAboutMiyabiText">
              Lorem ipsum dolor sit amet consectetur. Gravida accumsan turpis
              adipiscing id viverra. Curabitur aliquet ligula sit in pharetra
              ultricies. Cras nibh eu fermentum id penatibus urna diam. Nisi mi
              turpis nulla fusce cursus enim. Sagittis praesent sit ultrices
              metus lacus habitant cras auctor. Molestie eu ullamcorper nam
              convallis id. Volutpat risus elit et massa tellus morbi ac
              facilisis.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box className="masterCrafterLiveStreamBox masterCrafterBlackBgBox">
        <img
          src={pursuitOfPerfectionImage}
          className="masterCrafterJpPursitOfPerfectionImage"
        />
        <Container className="subscribeLiveStreamContainer">
          <Typography className="subscribeLiveStreamTitle" variant="h3">
            Subscribe to Miyabi Live Stream
          </Typography>
          <Typography className="subscribeLiveStreamText">
            Lorem ipsum dolor sit amet consectetur.
          </Typography>
          <Box className="subscribeLiveStreamIconBox">
            <img loading="lazy" src={instagramIcon} className="instagramIcon" />
            <img loading="lazy" src={youtubeIcon} className="youtubeIcon" />
          </Box>
        </Container>
      </Box>
      {/* CTA cards section */}
      <Container className="masterCrafterCTACardsContainer">
        <Grid
          container
          className="masterCrafterCTACardsGridContainer"
          spacing={2}
        >
          {ctaCardsData.map((item, index) => (
            <Grid
              item
              md={6}
              xs={12}
              key={index}
              className="ctaCardBox masterCrafterCardBox"
            >
              <CTACard item={item} index={index} isMobile={isMobile} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      {isMobile ? <MobileFooter /> : <Footer />}
    </>
  );
};
export default MasterCrafterLanding;
