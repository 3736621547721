/**
 * This file has strcture for Cut analysis section in diamond details page
 */
import { useState } from "react";
import DropDownComponent from "../../components/dropdown.js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  lightPerformanceAnalysisImage1,
  lightPerformanceAnalysisImage2,
} from "../../assets/diamond-details-media";
const CutAnalysis = ({ isMobile }) => {
  const [dataIndex, setDataIndex] = useState(0);
  const handleTabChange = (index) => {
    setDataIndex(index);
  };
  const CutAnalysisData = [
    {
      name: "Fire Evaluation",
      title: "Light Performance Analysis1",
      subtitle: "ASET Rotation Video Comparison1",
      img1: lightPerformanceAnalysisImage1,
      img2: lightPerformanceAnalysisImage2,
      desc1: "DECAGON 2.227CT G VVS2  /  Certificate No. AGS 104114930046",
      desc2: {
        left : "Left: Super Ideal Cut, 57 facets Round Brilliant",
        middle: "Middle: Typical Ideal Cut (shallow), 57 facets Round brilliant",
        right: "Right: Typical GIA Triple Excellent (steep/deep), 57 facets Round brilliant"
      },
      para1:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
      para2:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
    },
    {
      name: "ASET Rotation Video",
      title: "Light Performance Analysis2",
      subtitle: "ASET Rotation Video Comparison2",
      img1: lightPerformanceAnalysisImage1,
      img2: lightPerformanceAnalysisImage2,
      desc1: "DECAGON 2.227CT G VVS2  /  Certificate No. AGS 104114930046",
      desc2: {
        left : "Left: Super Ideal Cut, 57 facets Round Brilliant",
        middle: "Middle: Typical Ideal Cut (shallow), 57 facets Round brilliant",
        right: "Right: Typical GIA Triple Excellent (steep/deep), 57 facets Round brilliant"
      },
      para1:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
      para2:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
    },
    {
      name: "Brightness Evaluation",
      title: "Light Performance Analysis3",
      subtitle: "ASET Rotation Video Comparison3",
      img1: lightPerformanceAnalysisImage1,
      img2: lightPerformanceAnalysisImage2,
      desc1: "DECAGON 2.227CT G VVS2  /  Certificate No. AGS 104114930046",
      desc2: {
        left : "Left: Super Ideal Cut, 57 facets Round Brilliant",
        middle: "Middle: Typical Ideal Cut (shallow), 57 facets Round brilliant",
        right: "Right: Typical GIA Triple Excellent (steep/deep), 57 facets Round brilliant"
      },
      para1:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
      para2:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
    },
    {
      name: "Hearts & Arrows",
      title: "Light Performance Analysis4",
      subtitle: "ASET Rotation Video Comparison4",
      img1: lightPerformanceAnalysisImage1,
      img2: lightPerformanceAnalysisImage2,
      desc1: "DECAGON 2.227CT G VVS2  /  Certificate No. AGS 104114930046",
      desc2: {
        left : "Left: Super Ideal Cut, 57 facets Round Brilliant",
        middle: "Middle: Typical Ideal Cut (shallow), 57 facets Round brilliant",
        right: "Right: Typical GIA Triple Excellent (steep/deep), 57 facets Round brilliant"
      },
      para1:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
      para2:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
    },
    {
      name: "Color Grade Analysis",
      title: "Light Performance Analysis5",
      subtitle: "ASET Rotation Video Comparison5",
      img1: lightPerformanceAnalysisImage1,
      img2: lightPerformanceAnalysisImage2,
      desc1: "DECAGON 2.227CT G VVS2  /  Certificate No. AGS 104114930046",
      desc2: {
        left : "Left: Super Ideal Cut, 57 facets Round Brilliant",
        middle: "Middle: Typical Ideal Cut (shallow), 57 facets Round brilliant",
        right: "Right: Typical GIA Triple Excellent (steep/deep), 57 facets Round brilliant"
      },
      para1:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
      para2:
        "The Decagon 10HA displays fire and scintillation that has surpassed famous modified 10 Hearts & Arrows diamonds that were known to be the best in the industry. This breakthrough has set a new benchmark no other diamond has done in the last 2 decades. The Decagon 10HA paves the way for a new generation of Super Ideal diamonds to aspire from. Its firey display of extreme fireworks will captivate any viewers timelessly. The Decagon 10HA's pavilion size are optimized to achieve a large burst of dispersion of fire.",
    },
  ];
  const ddData = [];
  for (let i = 0; i < CutAnalysisData.length; i++) {
    ddData.push(CutAnalysisData[i].name);
  }
  return (
    <Grid container className="cutAnalysisGridContainer" spacing={2}>
      <Grid item md={12} xs={12} className="cutAnalysisGrid1" >
        {isMobile ? (
          <DropDownComponent data={ddData} setDataIndex={setDataIndex} />
        ) : (
            <Tabs
              value={dataIndex}
              onChange={handleTabChange}
              className="ddpCutAnalysisTabsContainer"
            >
              {CutAnalysisData.map((tab, index) => (
                <Tab
                  label={
                    <Button
                      className={`${
                        dataIndex === index
                          ? "brownFilledButton"
                          : "ddpCutAnalysisTabButton"
                      }`}
                    >
                      {tab.name}
                    </Button>
                  }
                  onClick={() => handleTabChange(index)}
                  className={`ddpCutAnalysisTab ddpCutAnalysisTab${index + 1}`}
                />
              ))}
            </Tabs>
        )}
      </Grid>

      <Grid item md={12} xs={12} className="cutAnalysisGrid2">
        <Box className="cutAnalysisContentBox">
          <Typography variant="h3" className="cutAnalysisTitle">
            {CutAnalysisData[dataIndex].title}
          </Typography>
          <Typography className="cutAnalysisSubTitle">
            {CutAnalysisData[dataIndex].subtitle}
          </Typography>
        </Box>
        <Box className="cutAnalysisImageDescBox">
          <img
            className="cutAnalysisImages"
            src={CutAnalysisData[dataIndex].img1}
          />
          <Typography className="cutAnalysisImageDesc">
            {CutAnalysisData[dataIndex].desc1}
          </Typography>
        </Box>
        <Typography className="cutAnalysisParagraphs">
          {CutAnalysisData[dataIndex].para1}
        </Typography>
        <Box className="cutAnalysisImageDescBox">
          <img
            className="cutAnalysisImages"
            src={CutAnalysisData[dataIndex].img2}
          />
          <Typography className="cutAnalysisImageDescLeft">
            {CutAnalysisData[dataIndex].desc2.left}
          </Typography>
          <Typography className="cutAnalysisImageDescMiddle">
            {CutAnalysisData[dataIndex].desc2.middle}
          </Typography>
          <Typography className="cutAnalysisImageDescRight">
            {CutAnalysisData[dataIndex].desc2.right}
          </Typography>
        </Box>
        <Typography className="cutAnalysisParagraphs">
          {CutAnalysisData[dataIndex].para2}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default CutAnalysis;
