/**
 * This file contains mobile header structure and calls all the menus and search bar
 */
import { useState, useCallback } from "react";
import MobileMenu from "./mobile-menu";
import SearchBar from "./mobile-search-bar";
import { mobileMenuIcon, searchIcon, cartIcon } from "../../assets/icons";
import Grid from "@mui/material/Grid";
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';
import Collapse from '@mui/material/Collapse';
import "../../assets/css/jp-header-mobile.css";
const MobileHeader = ({ paths }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleSearchBar, setToggleSearchBar] = useState(false);
  // for passing header width to search bar
  const [ headerHeight, setHeaderHeight ] = useState(0);
  const headerRef = useCallback(node => {
    if(node !=null){
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  })
  // console.log(headerHeight);
  return (
    // adding z-index to header so that whenever search bar drawer is opened it won't hide header behind it
    <Grid container className={`${toggleSearchBar ? "headerZIndex" : ""} jpMobileHeader`} ref={headerRef} >
      <Grid item xs={2} className="sandwichMenu">
        <img src={mobileMenuIcon} onClick={() => setToggleMenu(true)} />
      </Grid>
      <Grid item xs={8} className="jpMobileHeaderLogoGrid">
        <img
          src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png"
          className="jpMobileHeaderLogo"
        />
      </Grid>
      <Grid className="searchCartIcon" item xs={2}>
        <img className="headerIcons" src={searchIcon} onClick={()=>setToggleSearchBar(!toggleSearchBar)} />
        <img className="headerIcons" src={cartIcon} />
      </Grid>
      <Drawer open={toggleMenu} onClose={() => setToggleMenu(false)} className="mobileMenuDrawer">
        {/* passing setToggleMenu to menu so that we can close menu from child component */}
        <MobileMenu setToggleMenu={setToggleMenu} />
      </Drawer>
      <Grid  item xs={12}>
      <Modal className="mainMobileModel" slotProps={{backdrop: { classes: { root: "modalBackgroundSection"} }}} disableAutoFocus open={toggleSearchBar} onClose={() => setToggleSearchBar(false)}>
        <><SearchBar handleClose={setToggleSearchBar}/></>
      </Modal>
      </Grid>
    </Grid>
  );
};
export default MobileHeader;
