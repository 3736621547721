/**
 * This file contains code for grid view pop up
 */
import { useState } from "react";
// for routing
import { Link as RoutingLink } from "react-router-dom"; 
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { cydDiamondImage } from "../../assets/cyd-media.js";
import { closeIcon } from "../../assets/icons.js";
const DiamondDetailsModal = ({ itemData, setOpenModal}) => {
  const [open, setOpen] = useState(true);
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    setOpenModal([false, null])
  }
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="griViewModal"
        id="grid-view-modal"
      > 
      <Grid container  className="gridViewModalContainer" >
        <Grid item md={4}>
          <img src={cydDiamondImage} className="cydListViewExpandedRowImage" loading="lazy" />
        </Grid>
        <Grid item md={8} className="gridViewModalTexts">
          <Typography className="gridViewModalText1">
            {itemData.carat} {itemData.color} {itemData.clarity} {itemData.cut} {itemData.shape}
          </Typography>
          <Typography className="gridViewModalText2">
            {itemData.shape} · {itemData.carat} · {itemData.color} · {itemData.clarity} ·{" "}
            {itemData.cut}
          </Typography>
          <Typography className="gridViewModalText3">SGD5,200</Typography>
          <Divider />
          <Grid container className="gridViewModalTable" spacing={2}>
            <Grid item md={3} className="gridViewModalCol1">
              <Typography className="gridViewModalKeys">Polish</Typography>
              <Typography className="gridViewModalKeys">Symmetry</Typography>
              <Typography className="gridViewModalKeys">Fluorescent</Typography>
            </Grid>
            <Grid item md={3} className="gridViewModalCol1">
              <Typography className="gridViewModalValues">{itemData.polish}</Typography>
              <Typography className="gridViewModalValues">{itemData.symmetry}</Typography>
              {/* <Typography className="gridViewModalValues">{itemData.fluorescent}</Typography> */}
              <Typography className="gridViewModalValues">{"None"}</Typography>
            </Grid>
            <Grid item md={3} className="gridViewModalCol1">
              <Typography className="gridViewModalKeys">Depth%</Typography>
              <Typography className="gridViewModalKeys">Table%</Typography>
              <Typography className="gridViewModalKeys">Certified Lab</Typography>
            </Grid>
            <Grid item md={3} className="gridViewModalCol1">
              <Typography className="gridViewModalValues">{itemData.depth}%</Typography>
              <Typography className="gridViewModalValues">{itemData.table}%</Typography>
              {/* <Typography className="gridViewModalValues">{itemData.certifiedLab}</Typography> */}
              <Typography className="gridViewModalValues">{"GCAL"}</Typography>
            </Grid>
            <Grid item md={6} className="gridViewModalCol2">
            <RoutingLink key={"startWithSettingLink"} to="/start-with-setting" state={itemData} className="linkComponent fullWidthButton" >
              <Button variant="contained" className="black-filled fullWidthButton">Select This Diamond</Button>
              </RoutingLink>
            </Grid>
            <Grid item md={6} className="gridViewModalCol3">
            <RoutingLink to="/diamond-details" state={itemData} className="linkComponent">
              <Button className="blackOutlinedButton fullWidthButton" variant="outlined">View Details</Button>
              </RoutingLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="gridViewModalCloseButtonGrid">
          <img src={closeIcon} onClick={handleClose} loading="lazy" />
        </Grid>
      </Grid>
      </Modal>
    </Box>
  );
};
export default DiamondDetailsModal;
